<template>
    <div class="breadcrumb-box" v-if="product">
        <router-link :to="{ name: 'home' }">Jandaia</router-link>
        <router-link :to="{ name: 'products' }">Loja</router-link>
        <router-link :to="{ name: 'product', params: { id: product.id } }">Produto {{ product.ref }}</router-link>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConstructedProduct } from '../../utils/Interfaces/Products'

@Component({})
export default class ProductBreadCrumb extends Vue {
    @Prop({}) product!: ConstructedProduct | null
}
</script>
<style scoped lang="scss"></style>
