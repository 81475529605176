import HTTP from '../utils/HTTP'
import API from '../utils/API'
import { ProductsCompleteSearch } from '../utils/Interfaces/Products'

export const API_ROUTE = '/public/products'

class BlankAPI extends API {

    get (productId: string) {
        return HTTP.api().get(`${this.API_ROUTE}/product/${productId}`)
    }

    getAll () {
        return HTTP.api().get(`${this.API_ROUTE}/`)
    }

    getFeatured () {
        return HTTP.api().get(`${this.API_ROUTE}/featured`)
    }

    getProductStock (productId: string) {
        return HTTP.api().get(`${this.API_ROUTE}/${productId}/stock`)
    }

    getProductPrice (productId: string) {
        return HTTP.api().get(`${this.API_ROUTE}/${productId}/price`)
    }

    search (searchParams: ProductsCompleteSearch) {
        return HTTP.api().post(`${this.API_ROUTE}/search`, searchParams)
    }

}

export default new BlankAPI(API_ROUTE)
