<template>
    <div class="cart-box popup active" id="cartPopupBox" v-if="isOpen" :style="styleString">
        <div class="popup-container">
            <template v-if="cartIsEmpty">
                <div class="cart-entry">
                    <p>O seu carrinho está vazio.</p>
                </div>
            </template>
            <template v-else>
                <div v-for="product in products"
                     v-if="cart[product.id]"
                     :key="product.id"
                     class="cart-entry">
                    <a class="image">
                        <img :src="firstImage(product)"/>
                    </a>
                    <div class="content">
                        <router-link class="title" :to="{ name: 'product', params: { id: product.id }}">
                            {{ product.ref }}
                        </router-link>
                        <div v-for="(quantity, size) in cart[product.id].quantities"
                             :key="`${product.id}_${size}`"
                             class="quantity"
                             style="position: relative; padding-left: 22px">
                            Tamanho: {{ quantity }} x {{ size }}
                            <div class="button-x"
                                 title="Remover tamanho do produto"
                                 style="top: 5px; left: 0px; opacity: 0.7;"
                                 @click="removeProductSize(product.id, size)">
                                <i class="fa fa-close"></i>
                            </div>
                        </div>
                        <div class="price">{{ getProductPrice(product.id) }} €</div>
                    </div>
                    <div class="button-x"
                         title="Remover produto"
                         @click="removeProduct(product.id)">
                        <i class="fa fa-close"></i>
                    </div>
                </div>

                <div class="summary">
                    <div class="subtotal">preço (sem IVA): {{ cartTotalPrice.base.toFixed(2) }} €</div>
                    <div class="grandtotal">TOTAL <span>{{ cartTotalPrice.total.toFixed(2) }} €</span></div>
                </div>
                <div class="cart-buttons">
                    <div class="column">
                        <router-link :to="{name: 'cart'}" class="button style-3">Ver carrinho</router-link>
                        <div class="clear"></div>
                    </div>
                    <div class="column">
                        <router-link :to="{name: 'checkout'}" class="button style-4">Checkout</router-link>
                        <div class="clear"></div>
                    </div>
                    <div class="clear"></div>
                </div>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
    import ACTIONS from '../../store/types-actions'
    import MUTATIONS from '../../store/types-mutations'
    import { CartProduct } from '../../utils/Interfaces/Purchase'
    import { ConstructedProduct } from '../../utils/Interfaces/Products'
    import getImageUrl from '../../utils/ImageURL'
    import CartFunctions, { CartTotalPrice } from '../../utils/Constructers/CartFunctions'

    @Component({})
    export default class ContentCartPopup extends Vue {
        @Prop({}) cart!: { [index: string]: CartProduct}
        @Prop({}) products!: { [index: string]: ConstructedProduct}
        @Prop({}) cartTotalPrice!: CartTotalPrice

        right: number = 0
        top: number = 0

        get isOpen (): boolean {
            return this.$store.state.modals.cartPopup.active
        }
        get styleString () {
            return `top: ${this.top}px; right: ${this.right}px`
        }
        get cartIsEmpty (): boolean {
            return Object.keys(this.cart).length === 0
        }

        // Product info
        getProductImageURL (file: string): string {
            return getImageUrl({ filePath: file })
        }
        // TODO: make this a method of ConstructedProduct
        firstImage (product: ConstructedProduct): string {
            // No images
            if (!product.merged.pictures[0]) {
                return this.getProductImageURL('products/product-no-image.jpg')
            }
            // At least one image
            const thumbnailIndex = product.merged.fixedThumbnailIndex
            const productPictures = product.merged.pictures
            return this.getProductImageURL(productPictures[thumbnailIndex])
        }
        getProductPrice (productId: string): string | '-' {
            let price = CartFunctions.getProductPrice({
                productId,
                cart: this.cart,
                products: this.products
            })
            if (!price) return '-'
            return price.toFixed(2)
        }
        removeProduct (productId: string) {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_REMOVE_PRODUCT, { productId })
        }
        removeProductSize (productId: string, size: number) {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_REMOVE_PRODUCT_SIZE, { productId, size })
        }

        // Popup STATE and POSITION management
        @Watch('isOpen')
        onIsOpenChange(): void {
            this.adjust()
        }
        @Watch('$route', { deep: true })
        onRouteChange() {
            this.$store.commit(MUTATIONS.APP_CART_POPUP_HIDE)
        }
        detectClickOutside (event: Event) {
            if (!this.isOpen) return

            const buttonElement: Element | null = document.querySelector('#cartPopupButtom')
            const popupBoxElement: Element | null = document.querySelector('#cartPopupBox')
            if (!buttonElement || !popupBoxElement || !event.target) return

            // If we reach body -> click was outside, close popupUp
            // If we reach buttonElement -> click was on header button, ignore
            // If we reach popupBoxElement -> click was inside, ignore
            const VerifyClick = (element: Element | null) => {
                if (!element) return
                if (element.isSameNode(buttonElement)) return
                if (element.isSameNode(popupBoxElement)) return

                if (element.tagName === 'BODY') {
                    this.$store.commit(MUTATIONS.APP_CART_POPUP_HIDE)
                } else {
                    VerifyClick(element.parentElement)
                }
            }

            const firstTarget = event.target as Element
            VerifyClick(firstTarget)
        }
        adjust () {
            if (!this.isOpen) return

            const popupButton = document.querySelector('#cartPopupButtom')
            if (!popupButton) return

            const popupRect: ClientRect | DOMRect = popupButton.getBoundingClientRect()
            const windowWith = window.innerWidth

            this.top = popupRect.top + 20
            this.right = windowWith - popupRect.right
        }

        mounted () {
            window.addEventListener('resize', this.adjust)
            window.document.addEventListener('click', this.detectClickOutside)
        }
    }
</script>
<style scoped lang="scss"></style>
