// Import the correspondent API object
import brandsAPI from '../../api/brands'
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { BrandsObject} from '../../utils/Interfaces/Brands'

interface DefaultState {
    brandsObject: BrandsObject
    loading: boolean
}

const defaultState: DefaultState = {
    brandsObject: {},
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {}

const actions = {
    async [ACTIONS.BRANDS_LOAD] ({ commit }: { commit: any }): Promise<void> {
        commit(MUTATIONS.BRANDS_REQUEST_START)
        await brandsAPI.getAll().then((response: any) => {
            commit(MUTATIONS.BRANDS_LOAD, { brandsObject: response.data.data })
        })
        commit(MUTATIONS.BRANDS_REQUEST_FINISH)
    }
}

const mutations = {
    [MUTATIONS.BRANDS_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.BRANDS_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.BRANDS_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.BRANDS_LOAD] (state: DefaultState, { brandsObject }: { brandsObject: BrandsObject }) {
        state.brandsObject = brandsObject
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
