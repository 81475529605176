<template>
    <div>
        <div class="accordeon-title"
             :class="{ 'active': isOpen }"
             @click="isOpen = !isOpen">
            <span class="number">2</span>Contactos
        </div>
        <div class="accordeon-entry" v-show="isOpen">
            <div class="message-box message-warning" v-if="userIsLogged">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    Pode alterar o contacto telefónico apenas para esta encomenda. O contacto guardado na sua conta permanecerá inalterado.
                    Para alterar o contacto definitivamente utilize o formulário na página <router-link :to="{name: 'account'}">Minha conta</router-link>.
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 information-entry">
                    <div class="article-container style-1">
                        <form>
                            <label>Email de contacto (obrigatório)</label>
                            <input class="simple-field"
                                   v-model="email"
                                   type="text"
                                   placeholder="Email"
                                   :disabled="userIsLogged || purchaseGenerated"
                                   required>
                        </form>
                    </div>
                </div>
                <div class="col-md-6 information-entry">
                    <div class="article-container style-1">
                        <form>
                            <label>Contacto telefónico (obrigatório)</label>
                            <input class="simple-field"
                                   v-model="phone"
                                   type="text"
                                   placeholder="telefone"
                                   :disabled="purchaseGenerated"
                                   required>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import GETTERS from '../../store/types-getters'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class CheckoutContacts extends Vue {
        isOpen: boolean = true

        get purchaseGenerated () {
            return this.$store.state.purchase.generated
        }
        
        get email () {
            return this.$store.state.purchase.contacts.email
        }
        set email (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'contacts', key: 'email', value })
        }

        get phone () {
            return this.$store.state.purchase.contacts.phone
        }
        set phone (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'contacts', key: 'phone', value })
        }

        get userIsLogged () {
            return this.$store.getters[GETTERS.USER_IS_LOGGED]
        }
        @Watch('userIsLogged', { immediate: true })
        onUserLoggedChanged () {
            if (!this.userIsLogged) return
            const userData = this.$store.state.user.user
            this.email = userData.email
            this.phone = `351 ${userData.phone || ''}`
        }
    }
</script>
<style scoped lang="scss"></style>
