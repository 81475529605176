<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'notFound' }">Página não encontrada</router-link>
        </div>

        <div class="information-blocks">
            <div class="container-404">
                <div class="title">404</div>
                <div class="description">Página não encontrada</div>
                <div class="text">
                    A página que tentou aceder não existe.<router-link :to="{ name: 'home' }">Voltar à página inicial</router-link>.
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'

    @Component({})
    export default class PageNotFound extends Vue {
    }
</script>
<style scoped lang="scss"></style>
