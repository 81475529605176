<template>
    <div class="swiper-slide" :style="`margin-bottom: ${marginBottom}px;`">
        <div class="paddings-container">
            <div class="product-slide-entry shift-image">
                <router-link :to="{ name: 'product', params: { id: productConstructed.id } }"
                             class="product-image">
                    <img :src="firstImage" alt="" />
                    <img :src="secondImage" alt="" />
                    <div class="bottom-line"
                         @click.capture="(e) => { e.preventDefault() }">
                        <div class="right-align">
                            <a class="bottom-line-a square"
                               :class="{ '--active': isFavorite }"
                               :title="isFavorite ? 'remover dos favoritos' : 'adicionar aos favoritos'"
                               @click="addToFavorites()">
                                <i class="fa fa-heart"></i>
                            </a>
                        </div>
                        <div class="left-align">
                            <a class="bottom-line-a"
                               @click="addToCart()">
                                <i class="fa fa-shopping-cart"></i> Adicionar
                            </a>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{ name: 'product', params: { id: productConstructed.id } }"
                             class="tag">{{ productConstructed.merged.brand }}</router-link>
                <router-link :to="{ name: 'product', params: { id: productConstructed.id } }"
                             class="title">
                             {{ productConstructed.title || productConstructed.ref }}
                </router-link>
                <!-- FOR VERSION 2.0 -->
                <div class="rating-box" v-if="false">
                    <div class="star"><i class="fa fa-star"></i></div>
                    <div class="star"><i class="fa fa-star"></i></div>
                    <div class="star"><i class="fa fa-star"></i></div>
                    <div class="star"><i class="fa fa-star"></i></div>
                    <div class="star"><i class="fa fa-star"></i></div>
                </div>
                <!-- FOR VERSION 2.0 -->
                <div class="price">
                    <template v-if="product.price > effectivePrice">
                        <div class="prev" style="margin-right: 4px">{{ product.price.toFixed(2) }} € </div>
                        <div class="current"> {{ effectivePrice.toFixed(2) }} €</div>
                    </template>
                    <template v-else>
                        <div class="current">{{ product.price.toFixed(2) }} €</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PublishedProduct, ConstructedProduct } from '../../utils/Interfaces/Products'
import { AuxData } from '../../utils/Interfaces/_misc'
import ACTIONS from '../../store/types-actions'
import MUTATIONS from '../../store/types-mutations'
import constructProduct from '../../utils/Constructers/Product'
import CartFunction from '../../utils/Constructers/CartFunctions'
import getImageUrl from '../../utils/ImageURL'

@Component({})
export default class ProductCard extends Vue {
    @Prop({}) product!: PublishedProduct
    @Prop({ default: 0 }) marginBottom!: number

    getProductImageURL (file: string): string {
        return getImageUrl({ filePath: file })
    }

    get effectivePrice (): number {
        return CartFunction.processPrice(this.product)
    }

    get isFavorite () {
        if (!this.$store.state.user.logged) return false
        if (!this.$store.state.user.user.wishlist) return false
        return this.$store.state.user.user.wishlist.indexOf(this.product.id) !== -1
    }

    get auxData (): AuxData {
        return {
            colors: this.$store.state.colors.colorsObject,
            brands: this.$store.state.brands.brandsObject,
            categories: this.$store.state.categories.categoryTree,
            tags: this.$store.state.tags.tagsObject
        }
    }

    get storeIsOnline () {
        return this.$store.state.settings.store_online
    }

    get productConstructed (): ConstructedProduct {
        return constructProduct(this.product, this.auxData)
    }

    // TODO: make this a method of ConstructedProduct
    get firstImage () {
        // No images
        if (!this.productConstructed.merged.pictures[0]) {
            return this.getProductImageURL('products/product-no-image.jpg')
        }
        // At least one image
        const thumbnailIndex = this.productConstructed.merged.fixedThumbnailIndex
        const productPictures = this.productConstructed.merged.pictures
        return this.getProductImageURL(productPictures[thumbnailIndex])
    }

    get secondImage () {
        // One or less pictures
        if (!this.productConstructed.merged.pictures[1]) {
            return this.getProductImageURL('products/product-no-image.jpg')
        }
        const thumbnailIndex = this.productConstructed.merged.fixedThumbnailIndex
        const productPictures = this.productConstructed.merged.pictures
        // If thumbnailIndex is 0, return 1, else return the first image
        if (thumbnailIndex === 0) {
            return this.getProductImageURL(productPictures[1])
        } else {
            return this.getProductImageURL(productPictures[0])
        }
    }

    addToFavorites () {
        if (!this.$store.state.user.logged) {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'Faça login ou crie uma conta para adicionar produtos aos seus favoritos.')
            return
        }

        this.$store.dispatch(ACTIONS.USER_WISHLIST_TOGGLE_PRODUCT, { productId: this.product.id }).catch(() => {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'Não foi possível adicionar ou remover o produto à sua lista de favoritos. Por favor, tente mais tarde.')
        })
    }

    addToCart () {
        if (!this.storeIsOnline) {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'A nossa loja está temporáriamente indisponível. Pedimos desculpa pelo incómodo. Por favor tente mais tarde.')
            return
        }

        this.$store.commit(MUTATIONS.APP_SIZE_PICKER_SHOW, { productId: this.product.id })
    }
}
</script>
<style scoped lang="scss"></style>
