<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'maintenance' }">Em manutenção</router-link>
        </div>

        <div class="information-blocks">
            <div class="container-404">
                <div class="description">Em manutenção</div>
                <div class="text">
                    Estamos a preparar o novo site Jandaia. Voltamos muito brevemente<br />
                    Pedimos desculpa pelo incómodo
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'

    @Component({})
    export default class PageNotFound extends Vue {
    }
</script>
<style scoped lang="scss"></style>
