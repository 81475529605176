import { DeliveryAddress, InvoiceAddress } from "./Address"

export type PAYMENT_METHODS = 'CREDIT' | 'MULTIBANCO'

export enum PurchaseStatus {
    'MULTIBANCO' = 'MULTIBANCO',                        // waiting for user to pay using Multibanco method
    'REGISTERED' = 'REGISTERED',                        // the user has registered the order
    'PAYED' = 'PAYED',                                  // the payment has been received
    'DISPATCHED_REQUESTED' = 'DISPATCHED_REQUESTED',    // CTT has been notified to pick up the order
    'DISPATCHED' = 'DISPATCHED',                        // CTT has pickup and the order is in transit
    'DELIVERED' = 'DELIVERED',                          // The order has been delivered to the address
    'CANCELED' = 'CANCELED'                             // The order has been canceled
}

export interface CartProduct {
    id: string
    quantities: {
        [index: number]: number
    }
}

export interface PurchaseContacts {
    email: string
    phone: string
}

export interface PurchaseFull {
    clientId?: string
    cart: { [index: string]: CartProduct }
    contacts: PurchaseContacts,
    addressDelivery: DeliveryAddress
    addressInvoice: InvoiceAddress
    notesClient: string
    paymentMethod: 'CREDIT' | 'MULTIBANCO'
}

export interface Purchase {
    id: string
    idShort: string
    clientId: string
    documentId: string
    paymentMethod: 'CREDIT' | 'MULTIBANCO'
    paymentEntity: '' | string
    paymentReference: '' | string
    date: string
    state: PurchaseStatus
    cart: string
    priceTotal: number
    priceBase: number
    priceTax: number
    priceIVA: number
    contactEmail: string
    contactPhone: string
    addressDeliveryStreet: string
    addressDeliveryCity: string
    addressDeliveryPostalCode: string
    addressInvoiceName: string
    addressInvoiceNif: string
    addressInvoiceStreet: string
    addressInvoiceCity: string
    addressInvoicePostalCode: string
    notesClient: string
}
