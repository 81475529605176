<template>
  <div class="content-push">
    <div class="breadcrumb-box">
      <router-link :to="{ name: 'home' }">Jandaia</router-link>
      <router-link :to="{ name: 'products' }">Loja</router-link>
      <router-link :to="{ name: 'cart' }">Carrinho</router-link>
    </div>
    <div class="information-blocks">
      <div class="row">
        <ContentCartProducts :cart="cart" :products="products" />
        <content-cart-total :cartTotalPrice="cartTotalPrice" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CartProduct } from "../../utils/Interfaces/Purchase";
import { ConstructedProduct } from "../../utils/Interfaces/Products";
import { CartTotalPrice } from "../../utils/Constructers/CartFunctions";
// Components
import ContentCartProducts from "../content/cartProducts.vue";
import ContentCartTotal from "../content/cartTotal.vue";

@Component({
  components: {
    ContentCartProducts,
    ContentCartTotal
  }
})
export default class PageCart extends Vue {
  @Prop({}) cart!: { [index: string]: CartProduct };
  @Prop({}) products!: { [index: string]: ConstructedProduct };
  @Prop({}) cartTotalPrice!: CartTotalPrice;

  get cartIsEmpty(): boolean {
    return Object.keys(this.cart).length === 0;
  }

  @Watch("cartIsEmpty", { immediate: true })
  onCartIsEmpty(isEmpty: boolean): void {
    if (isEmpty) this.$router.replace({ name: "home" });
  }
}
</script>
<style scoped lang="scss"></style>
