// KEEP FILE IN-SYNC BETWEEN BEACKEND AND FRONTEND

export const INDICATIVO = '(351) '
export function healPhone (target: string) {
    if (target.substr(0, 6) !== INDICATIVO) {
        return INDICATIVO
    }
    let partial = target
    partial = partial.substr(6)
    partial = partial.substr(0, 9)

    let partialNumber = Number(partial)
    if (isNaN(partialNumber) || partialNumber === 0) {
        return INDICATIVO
    }

    return `${INDICATIVO}${partialNumber}`
}

export function healNIF (target: string): string {
    const removeAndRepeat = (repeat: string): string => {
        repeat = repeat.substr(0, repeat.length - 1)
        return healNIF(repeat)
    }

    if (target === '') return ''
    const regexString = `^\\d{${target.length}}}?$`
    const regexReg = new RegExp(regexString)
    return regexReg.test(target) ? target : removeAndRepeat(target)
}

export function healPostalCode (target: string): string {
    const removeAndRepeat = (repeat: string): string => {
        repeat = repeat.substr(0, repeat.length - 1)
        return healPostalCode(repeat)
    }

    if (target === '') return ''
    switch (target.length) {
        case 1:
            if (!/^\d?$/.test(target)) return removeAndRepeat(target)
            return target
        case 2:
            if (!/^\d{2}?$/.test(target)) return removeAndRepeat(target)
            return target
        case 3:
            if (!/^\d{3}-?$/.test(target)) return removeAndRepeat(target)
            return target
        case 4:
            if (!/^\d{4}?$/.test(target)) return removeAndRepeat(target)
            return target
        case 5:
            if (!/^\d{4}-?$/.test(target)) return removeAndRepeat(target)
            return target
        case 6:
            if (!/^\d{4}-\d?$/.test(target)) return removeAndRepeat(target)
            return target
        case 7:
            if (!/^\d{4}-\d{2}?$/.test(target)) return removeAndRepeat(target)
            return target
        case 8:
            if (!/^\d{4}-\d{3}?$/.test(target)) return removeAndRepeat(target)
            return target
        default:
            return removeAndRepeat(target)
    }
}
