// Import the correspondent API object
import tagsAPI from '../../api/tags'
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { TagsObject} from '../../utils/Interfaces/Tags'

interface DefaultState {
    loading: boolean
    tagsObject: TagsObject
}

const defaultState: DefaultState = {
    tagsObject: {},
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {}

const actions = {
    async [ACTIONS.TAGS_LOAD] ({ commit }: { commit: any }): Promise<void> {
        commit(MUTATIONS.TAGS_REQUEST_START)
        await tagsAPI.getAll().then((response: any) => {
            commit(MUTATIONS.TAGS_LOAD, { tagsObject: response.data.data })
        })
        commit(MUTATIONS.TAGS_REQUEST_FINISH)
    }
}

const mutations = {
    [MUTATIONS.TAGS_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.TAGS_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.TAGS_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.TAGS_LOAD] (state: DefaultState, { tagsObject }: { tagsObject: TagsObject }) {
        state.tagsObject = tagsObject
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
