<template>
    <div class="navigation-banner-swiper">
        <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide v-for="banner in banners"
                          :key="banner.id">
                <div class="navigation-banner-wrapper light-text align-3"
                     :style="`background-image: url(${getBannerImageUrl(banner.image)}); background-position: center center; background-size: cover`">
                    <div class="navigation-banner-content">
                        <div class="cell-view">
                            <h2 v-if="banner.subtitle" class="subtitle">{{ banner.subtitle }}</h2>
                            <h1 v-if="banner.title" class="title">{{ banner.title }}</h1>
                            <div v-if="banner.text" class="description">{{ banner.text }}</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import getImageUrl from '../../utils/ImageURL'

@Component({})
export default class BannerSwipe extends Vue {
    swiperOptions = {
        autoplay: {
            delay: 5000,
        },
        autoHeight: true
    }

    get banners () {
        return this.$store.state.banners.bannersObject
    }
    getBannerImageUrl (image: string) {
        return getImageUrl({ filePath: image })
    }
}
</script>
<style scoped lang="scss"></style>
