<template>
    <div class="content-push">
        <banner-swipe />
        <banner-main-categories />
        <div class="hidden-sm hidden-xs" style="height: 30px;"></div>
        <content-featured-products />
        <content-home-links />
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import BannerSwipe from '../banner/swipe.vue'
import BannerMainCategories from '../banner/mainCategories.vue'
import ContentFeaturedProducts from '../content/featuredProducts.vue'
import ContentHomeLinks from '../content/homeLinks.vue'

@Component({
    components: {
        BannerSwipe,
        BannerMainCategories,
        ContentFeaturedProducts,
        ContentHomeLinks
    }
})
export default class PageHome extends Vue {}
</script>
<style scoped lang="scss"></style>
