import { CartProduct } from '../Interfaces/Purchase'
import { ConstructedProduct, PublishedProduct } from '../Interfaces/Products'
import { Country, CountryGroup } from '../Interfaces/Countries'
import store from '../../store'

const roundPrice = (price: number): number => {
    return Math.round((price * 100)) / 100
}

interface BaseInputs {
    cart: { [index: string]: CartProduct }
    products: { [index: string]: ConstructedProduct }
    country?: string
}

interface ProductInputs extends BaseInputs {
    productId: string
}

export interface CartTotalPrice {
    iva: number
    ivaAmount: number
    base: number
    delivery: number
    total: number
}

class CartFunctions {
    public processPrice (product: ConstructedProduct | PublishedProduct ): number {
        let effectivePrice = product.price
        if (product.discount) {
            effectivePrice = product.price * (1 - (product.discount / 100))
            effectivePrice = Math.round(effectivePrice * 100) / 100
        }
        return effectivePrice
    }
    public getProductPrice (inputs: ProductInputs): number | false {
        const product = inputs.products[inputs.productId]
        const productCart = inputs.cart[inputs.productId]

        if (!product || !productCart) return false
        let price = 0
        const sizes = Object.keys(productCart.quantities)
        sizes.forEach((size: string) => {
            // @ts-ignore
            price += this.processPrice(product) * productCart.quantities[size]
        })

        return roundPrice(price)
    }
    public getDeliveryPrice (country: string) {
        if (!country) return 0
        // @ts-ignore
        const countries = store.state.countries.countries
        // @ts-ignore
        const countryGroups = store.state.countries.groups
        const findCountry = countries.find((pais: Country) => pais.name === country)

        if (!findCountry) return 0
        if (!countryGroups) return 0

        const countryGroup: CountryGroup = countryGroups[findCountry.groupId]
        if (!countryGroup) return 0

        return countryGroup.price
    }
    public getCartTotalPrice (inputs: BaseInputs): CartTotalPrice {
        // @ts-ignore
        const iva = store.state.settings.IVA
        const cart = inputs.cart
        const productIds = Object.keys(inputs.products)

        let base = 0
        let total = 0
        let ivaAmount = 0
        let delivery = 0

        if (!iva) return { iva: 0, ivaAmount, base, delivery, total }

        productIds.forEach((productId: string) => {
            if (!cart[productId]) return
            let productPrice = this.getProductPrice({
                products: inputs.products,
                cart,
                productId
            })
            if (!productPrice) return
            total += productPrice
        })

        // Total dos produtos
        total = roundPrice(total)
        base = roundPrice(total / (1 + iva))

        const totalDeliveryPrice = this.getDeliveryPrice(inputs.country || '')
        delivery = roundPrice( totalDeliveryPrice / (1 + iva))

        total += totalDeliveryPrice
        ivaAmount = roundPrice(total - base - delivery)

        return { iva, ivaAmount, base, delivery, total }
    }
}

export default new CartFunctions()
