<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'account' }">A minha conta</router-link>
            <router-link :to="{ name: 'emailConfirmation', params: { emailCode: $route.params.emailCode } }">
                Confirmação de email
            </router-link>
        </div>

        <template v-if="isRequesting">
            <div class="information-blocks">
                <div class="container-404">
                    <div class="description">Estamos a confirmar o seu email</div>
                </div>
            </div>
        </template>
        <template v-else>
            <!-- The code was invalid -->
            <template v-if="confirmationResponse.invalidCode">
                <!-- If the user is logged and the email already confirmed, just ignore it -->
                <template v-if="userHasEmailConfirmed">
                    <div class="information-blocks">
                        <div class="container-404">
                            <div class="description">O seu email já se encontra confirmado</div>
                        </div>
                        <div class="text">
                            Veja os dados <router-link :to="{ name: 'account' }">da sua conta</router-link> ou regresse <router-link :to="{name: 'home'}">à página inicial</router-link>.
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="information-blocks">
                        <div class="container-404">
                            <div class="description">O código de confirmação de email é inválido.</div>
                        </div>
                    </div>
                </template>
            </template>
            <!-- The email was confirmed -->
            <template v-else-if="confirmationResponse.confirmed">
                <div class="information-blocks">
                    <div class="container-404">
                        <div class="description">O seu email foi confirmado com sucesso</div>
                    </div>
                    <template v-if="userLogged">
                        <div class="text">
                            Veja os dados <router-link :to="{ name: 'account' }">da sua conta</router-link> ou regresse <router-link :to="{name: 'home'}">à página inicial</router-link>.
                        </div>
                    </template>
                    <template v-else>
                        <div class="text">
                            Regressar <router-link :to="{name: 'home'}">à página inicial</router-link>.
                        </div>
                    </template>
                </div>
            </template>
            <!-- We don't know what happen -->
            <template v-else>
                <div class="information-blocks">
                    <div class="container-404">
                        <div class="description">Ocorreu um erro ao confirmar o seu email.</div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import ACTIONS from '../../store/types-actions'
    import { EmailConfirmationResponse } from '../../utils/Interfaces/User'

    @Component({})
    export default class PageEmailConfirmation extends Vue {
        isRequesting: boolean = true
        confirmationResponse: EmailConfirmationResponse = {
            confirmed: false,
            invalidCode: false
        }

        get emailCode () {
            return this.$route.params.emailCode
        }

        get userLogged () {
            return this.$store.state.user.logged
        }

        get userHasEmailConfirmed () {
            if (!this.userLogged) return false
            return this.$store.state.user.user.emailConfirmed === 1
        }

        mounted () {
            this.$store.dispatch(ACTIONS.USER_CONFIRM_EMAIL, { emailCode: this.emailCode }).then((confirmation: EmailConfirmationResponse) => {
                this.confirmationResponse = confirmation
            }).catch(() => {
                // Treat errors like page not found
                this.$router.replace({ name: 'notFound' })
            }).finally(() => {
                this.isRequesting = false
            })
        }
    }
</script>
<style scoped lang="scss"></style>
