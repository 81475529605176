<template>
    <div class="column-article-wrapper">
        <div class="row nopadding">
            <div v-for="(link, index) in links"
                 :key="index"
                 class="col-sm-4 information-entry left-border nopadding">
                <div class="column-article-entry">
                    <router-link :to="link.route" class="title" href="#">{{ link.title }}</router-link>
                    <div class="description" v-if="link.text">{{ link.text }}</div>
                    <router-link :to="link.route" class="read-more">Ver <i class="fa fa-angle-right"></i></router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ContentHomeLinks extends Vue {
    links = [
        {
            title: 'As nossas lojas',
            text: '',
            route: { name: 'stores' }
        },
        {
            title: 'À medida',
            text: '',
            route: { name: 'customize' }
        },
        {
            title: 'Contactos',
            text: '',
            route: { name: 'contacts' }
        }
    ]
}
</script>
<style scoped lang="scss"></style>
