<template>
    <div class="col-sm-3 col-sm-pull-9 blog-sidebar">
        <div class="information-blocks">
            <div class="categories-list account-links">
                <div class="block-title size-3">Minha Conta</div>
                <ul>
                    <li>
                        <router-link :to="{name: 'account'}"
                                     :class="{'--active': $route.name === 'account'}">Os meus dados</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'accountPurchases'}"
                                     :class="{'--active': $route.name === 'accountPurchases'}">As minhas compras</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'wishlist'}"
                                     :class="{'--active': $route.name === 'wishlist'}">Favoritos</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'accountDelete'}"
                                     :class="{'--active': $route.name === 'accountDelete'}">Eliminar conta</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class OtherAccountNavigation extends Vue {}
</script>
<style scoped lang="scss"></style>
