<template>
    <div class="col-xs-12">
        <div class="article-container style-1">
            <div v-if="formError.active" class="message-box message-danger">
                <div class="message-icon"><i class="fa fa-times"></i></div>
                <div class="message-text">{{ formError.message }}</div>
            </div>
            <div v-if="formSuccess.active" class="message-box message-success">
                <div class="message-icon"><i class="fa fa-check"></i></div>
                <div class="message-text">{{ formSuccess.message }}</div>
                <div class="message-close" @click="formSuccess.active = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-3 col-sm-6">
                    <form @submit.prevent="changePassword()">
                        <label>Introduza a sua password actual</label>
                        <input v-model="oldPassword"
                               type="password"
                               placeholder="Password"
                               class="simple-field width"
                               maxlength="40"
                               minlength="8"
                               @focus="formSuccess.active = false"
                               required/>
                        <label>Nova Password</label>
                        <input v-model="newPassword"
                               type="password"
                               placeholder="Password"
                               class="simple-field width"
                               maxlength="40"
                               minlength="8"
                               @focus="formSuccess.active = false"
                               required/>
                        <label>Confirmar password</label>
                        <input v-model="newPasswordConfirm"
                               type="password"
                               placeholder="Password"
                               class="simple-field width"
                               maxlength="40"
                               minlength="8"
                               @focus="formSuccess.active = false"
                               required/>
                        <div class="button style-10">
                            Alterar<input type="submit" value="">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormUserPassword extends Vue {
    oldPassword: string = ''
    newPassword: string = ''
    newPasswordConfirm: string = ''
    formError = {
        active: false,
        message: ''
    }
    formSuccess = {
        active: false,
        message: ''
    }

    validateForm (): boolean {
        this.formError.active = false
        this.formError.message = ''

        if (this.newPassword !== this.newPasswordConfirm) {
            this.formError.active = true
            this.formError.message = 'As passwords não coincidem'
            return false
        }
        return true
    }

    changePassword () {
        if (!this.validateForm()) return

        this.$store.dispatch(ACTIONS.USER_CHANGE_PASSWORD, { oldPassword: this.oldPassword, newPassword: this.newPasswordConfirm })
            .then(() => {
                this.formError.active = false
                this.formSuccess.active = true
                this.formSuccess.message = 'Password alterada com sucesso'
                this.oldPassword = ''
                this.newPassword = ''
                this.newPasswordConfirm = ''
            })
            .catch((error) => {
                this.formError.active = true
                this.formError.message = error.message
            })
    }
}
</script>