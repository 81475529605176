<template>
    <div>
        <div class="accordeon-title"
             :class="{ 'active': isOpen }"
             @click="isOpen = !isOpen">
            <span class="number">4</span>Morada de faturação (opcional)
        </div>
        <div class="accordeon-entry" v-show="isOpen">
            <div class="message-box message-warning">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    Se não introduzir dados para faturação receberá uma fatura padrão para consumidor final.
                </div>
            </div>
            <div class="message-box message-warning" v-if="userIsLogged">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    Pode alterar a morada de faturação apenas para esta encomenda. A morada de faturação na sua conta permanecerá inalterada.
                    Para alterar a morada de faturação definitivamente utilize o formulário na página <router-link :to="{name: 'account'}">Minha conta</router-link>.
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <div class="article-container style-1">
                        <label>Nome</label>
                        <input v-model="name"
                               type="text"
                               name="name"
                               class="simple-field width"
                               maxlength="70"
                               :disabled="purchaseGenerated"
                               required/>
                        <label>Número de Identificação Fiscal (NIF)</label>
                        <input v-model="nif"
                               type="text"
                               name="nif"
                               class="simple-field width"
                               maxlength="9"
                               minlength="9"
                               @input="healNIF()"
                               :disabled="purchaseGenerated"
                               required/>
                        <label>Pais</label>
                        <input v-model="country"
                            type="text"
                            name="country"
                            class="simple-field width"
                            maxlength="50"
                            :disabled="purchaseGenerated"
                            required/>
                        <label>Morada</label>
                        <input v-model="street"
                               type="text"
                               name="address"
                               placeholder="Avenida, Praceta, Rua..."
                               class="simple-field width"
                               maxlength="300"
                               :disabled="purchaseGenerated"
                               required/>
                        <label>Cidade / Localidade</label>
                        <input v-model="city"
                               type="text"
                               name="city"
                               class="simple-field width"
                               maxlength="70"
                               :disabled="purchaseGenerated"
                               required/>
                        <!-- postalCode -->
                        <label>Código Postal</label>
                        <input v-model="postalCode"
                               type="text"
                               name="postal-code"
                               placeholder="xxxx-xxx"
                               class="simple-field width"
                               maxlength="8"
                               minlength="8"
                               @input="healPostalCode()"
                               :disabled="purchaseGenerated"
                               required/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { healNIF, healPostalCode } from '../../utils/formHealers'
    import GETTERS from '../../store/types-getters'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class CheckoutAddressDelivery extends Vue {
        isOpen: boolean = true

        get purchaseGenerated () {
            return this.$store.state.purchase.generated
        }

        get name () {
            return this.$store.state.purchase.addressInvoice.name
        }
        set name (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'name', value })
        }

        healNIF () {
            this.nif = healNIF(this.nif)
        }
        get nif () {
            return this.$store.state.purchase.addressInvoice.nif
        }
        set nif (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'nif', value })
        }
        
        get country () {
            return this.$store.state.purchase.addressInvoice.country
        }
        set country (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'country', value })
        }

        get street () {
            return this.$store.state.purchase.addressInvoice.street
        }
        set street (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'street', value })
        }

        get city () {
            return this.$store.state.purchase.addressInvoice.city
        }
        set city (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'city', value })
        }

        healPostalCode () {
            this.postalCode = healPostalCode(this.postalCode)
        }
        get postalCode () {
            return this.$store.state.purchase.addressInvoice.postalCode
        }
        set postalCode (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressInvoice', key: 'postalCode', value })
        }

        get userIsLogged () {
            return this.$store.getters[GETTERS.USER_IS_LOGGED]
        }
        get userInvoiceAddress () {
            return this.$store.state.user.address.invoice
        }
        loadData () {
            if (!this.userIsLogged) return
            if (!this.userInvoiceAddress) return
            
            this.name = this.userInvoiceAddress.name
            this.nif= this.userInvoiceAddress.nif
            this.country = this.userInvoiceAddress.country
            this.street = this.userInvoiceAddress.street
            this.city = this.userInvoiceAddress.city
            this.postalCode = this.userInvoiceAddress.postalCode
        }
        @Watch('userIsLogged', { immediate: true })
        onUserLoggedChanged () { this.loadData() }
        @Watch('userInvoiceAddress', { deep: true })
        onUserDeliveryAddressChanged () { this.loadData() }
    }
</script>
<style scoped lang="scss"></style>
