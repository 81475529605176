export default class API {
    API_ROUTE: string
    params: any

    constructor (API_ROUTE: string) {
        this.API_ROUTE = API_ROUTE
    }

    getParams (mergeParams: any) {
        let currentParams = this.params || {}

        if (mergeParams !== undefined && mergeParams !== null) {
            currentParams = Object.assign({}, currentParams, mergeParams)
        }
        return Object.keys(currentParams).length === 0 ? null : {params: currentParams}
    }

    setParam (key: string, val: number | string | boolean) {
        if (this.params === undefined) { this.params = {} }
        if (val) {
            this.params[key] = val
        } else {
            delete this.params[key]
        }
        return this
    }

    resetParams () {
        this.params = {}
        return this
    }
}
