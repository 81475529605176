import GETTERS from './types-getters'

const getters = {
    [GETTERS.ROOT_GET_AUX_DATA]: (state: any) => {
        return {
            brands: state.brands.brandsObject,
            categories: state.categories.categoryTree,
            colors: state.colors.colorsObject,
            tags: state.tags.tagsObject
        }
    }
}

export default getters
