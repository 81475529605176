<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'contacts' }">Contactos</router-link>
        </div>
        <div class="contact-spacer"></div>
        <div class="information-blocks">
            <div class="clear"></div>
            <div class="row">
                <forms-contact />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import FormsContact from '../forms/contact.vue'

@Component({
    components: {
        FormsContact
    }
})
export default class PageContacts extends Vue {
}
</script>
<style scoped lang="scss"></style>
