import HTTP from '../utils/HTTP'
import API from '../utils/API'

// API access to both public and admin restricted routes. Can't set up a base API_ROUTE
export const API_ROUTE = ''

class StoresAPI extends API {
    // Public access
    getAll () {
        return HTTP.api().get(`/public/stores`)
    }
}

export default new StoresAPI(API_ROUTE)
