<template>
    <div class="col-md-9 col-sm-8">
        <!-- PRODUCTS GRID -->
        <div class="row shop-grid grid-view">
            <div class="col-md-4 col-sm-4 shop-grid-item"
                 v-for="product in products"
                 :key="`pg_${product.id}`">
                <product-card :product="product" :marginBottom="40"/>
            </div>
        </div>
        <div class="row product__load">
            <div class="col-xs-12">
                <div class="button style-10" v-if="continueSearch">
                    Carregar mais
                    <input type="submit" value="" @click="$emit('loadMore')">
                </div>
                <p v-else>Sem mais produtos para mostrar</p>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConstructedProduct } from '../../../utils/Interfaces/Products'
import ProductCard from '../card.vue'

@Component({
    components: {
        ProductCard
    }
})
export default class ProductStoreGrid extends Vue {
    @Prop({}) products!: ConstructedProduct[]
    @Prop({}) continueSearch!: boolean
}
</script>
<style scoped lang="scss"></style>
