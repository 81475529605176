// Import the correspondent API object
import publishedProductsAPI from '../../api/publishedProducts'
// Store getters, actions and mutation types
import MUTATIONS from '../types-mutations'
import ACTIONS from '../types-actions'
import GETTERS from '../types-getters'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { PublishedProduct, ProductsBaseSearch, ProductsCompleteSearch, ProducePrice } from '../../utils/Interfaces/Products'

interface FeaturedProducts {
    featured: PublishedProduct[]
    mostViewed: PublishedProduct[]
    mostRecent: PublishedProduct[]
}

interface DefaultState {
    products: { [index: string]: PublishedProduct }
    featured: FeaturedProducts
    search: {
        finished: boolean,
        products: string[],
        resultsCount: number
    }
    initialized: boolean
    loading: boolean
}

const defaultState: DefaultState = {
    products: {},
    featured: {
        featured: [],
        mostViewed: [],
        mostRecent: []
    },
    search: {
        finished: false,
        products: [],
        resultsCount: 18
    },
    initialized: false,
    loading: false
}

let state: DefaultState = cloneDeep(defaultState)

const getters = {
    [GETTERS.PRODUCTS_GET_PRODUCT]: (state: DefaultState) => (productId: string): PublishedProduct | null => {
        if (!state.products[productId]) return null
        return state.products[productId]
    }
}

const actions = {
    async [ACTIONS.PUBLISHED_PRODUCTS_LOAD] ({ state, commit }: { state: DefaultState, commit: any }, { forceUpdate = false }: { forceUpdate: boolean }): Promise<void> {
        if (state.initialized && !forceUpdate) return
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        await publishedProductsAPI.getAll().then((response: any) => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_LOAD_ALL, { products: response.data.data })
            commit(MUTATIONS.PUBLISHED_PRODUCTS_INITIALIZED)
        })
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
    },
    async [ACTIONS.PUBLISHED_PRODUCTS_LOAD_ONE] ({ state, commit }: { state: DefaultState, commit: any }, { productId }: { productId: string }): Promise<void> {
        if (state.products[productId]) return
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        await publishedProductsAPI.get(productId).then((response: any) => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_LOAD_ONE, { product: response.data.data })
        })
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
    },
    async [ACTIONS.PUBLISHED_PRODUCTS_GET_FEATURED] ({ state, commit }: { state: DefaultState, commit: any }): Promise<void> {
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        await publishedProductsAPI.getFeatured().then((response: any) => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_LOAD_FEATURED, { featured: response.data.data })
        })
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
    },
    async [ACTIONS.PUBLISHED_PRODUCTS_GET_PRODUCT_STOCK] ({ state, commit }: { state: DefaultState, commit: any }, { productId } : { productId: string}): Promise<void> {
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        return publishedProductsAPI.getProductStock(productId).then((response: any) => {
            return response.data
        }).catch((error) => {
            return error.response.data
        }).finally(() => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
        })
    },
    [ACTIONS.PUBLISHED_PRODUCTS_GET_PRODUCT_PRICE] ({ state, commit }: { state: DefaultState, commit: any }, { productId } : { productId: string}): void {
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        publishedProductsAPI.getProductPrice(productId).then((response: any) => {
            if (!response.data.data) return
            if (!response.data.data.price) return
            // Commit change to published products
            commit(MUTATIONS.PUBLISHED_PRODUCTS_UPDATE_PRODUCT_PRICE, { productId, price: response.data.data.price })
            // Commit change to cart products
            commit(MUTATIONS.PURCHASE_CART_UPDATE_PRODUCT_PRICE, { productId, price: response.data.data.price })
        }).finally(() => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
        })
    },
    // Related to product search
    async [ACTIONS.PUBLISHED_PRODUCTS_SEARCH] (
        { state, commit }: { state: DefaultState, commit: any },
        { searchParams, restart = false }: { searchParams: ProductsBaseSearch, restart: boolean}): Promise<void> {
        commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START)
        if (restart) commit(MUTATIONS.PUBLISHED_PRODUCTS_SEARCH_INIT)
        const completeSearch: ProductsCompleteSearch = {
            results: state.search.resultsCount,
            offset: state.search.products.length,
            ...searchParams
        }
        return publishedProductsAPI.search(completeSearch).then((response: any) => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_SEARCH_LOAD, { products: response.data.data })
        }).finally(() => {
            commit(MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH)
        })
    }
}

const mutations = {
    [MUTATIONS.PUBLISHED_PRODUCTS_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_INITIALIZED] (state: DefaultState) {
        state.initialized = true
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_LOAD_ALL] (state: DefaultState, { products }: { products: PublishedProduct[] }) {
        const resultCount = products.length
        if (!resultCount) return
        // Insert all products into store
        for (let product of products) {
            product = product as PublishedProduct
            Vue.set(state.products, product.id, product)
        }
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_LOAD_ONE] (state: DefaultState, { product }: { product: PublishedProduct }) {
        Vue.set(state.products, product.id, product)
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_LOAD_FEATURED] (state: DefaultState, { featured }: { featured: FeaturedProducts }) {
        state.featured.featured = featured.featured
        state.featured.mostViewed = featured.mostViewed
        state.featured.mostRecent = featured.mostRecent
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_UPDATE_PRODUCT_PRICE] (state: DefaultState, { productId, price }: { productId: string, price: ProducePrice }) {
        if (!state.products[productId]) return

        Vue.set(state.products[productId], 'price', price.price)
        Vue.set(state.products[productId], 'discount', price.discount)
    },
    // Related to product search
    [MUTATIONS.PUBLISHED_PRODUCTS_SEARCH_INIT] (state: DefaultState) {
        state.search.finished = false
        state.search.products = []
    },
    [MUTATIONS.PUBLISHED_PRODUCTS_SEARCH_LOAD] (state: DefaultState, { products }: { products: PublishedProduct[] }) {
        const baseProducts = cloneDeep(state.products)
        const productIds = products.map(p => {
            baseProducts[p.id] = p
            return p.id
        })
        state.products = baseProducts
        state.search.products = state.search.products.concat(productIds)
        // Set has finished if the results are less the resultsCount
        if (products.length < state.search.resultsCount) state.search.finished = true
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
