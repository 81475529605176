import Vue from 'vue'
import Vuex from 'vuex'
import HTTP from './utils/HTTP'
// Root action, mutations and getters
import actions from './store/actions'
import mutations from './store/mutations'
import getters from './store/getters'
// modules
import _blank from './store/modules/_blank'
import brands from './store/modules/brands'
import banners from './store/modules/banners'
import categories from './store/modules/categories'
import contact from './store/modules/contact'
import colors from './store/modules/colors'
import countries from './store/modules/countries'
import tags from './store/modules/tags'
import stores from './store/modules/stores'
import publishedProducts from './store/modules/publishedProducts'
import purchase from './store/modules/purchase'
import user from './store/modules/user'

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3011' : 'https://api.jandaia.pt'
HTTP.set({ baseURL })
Vue.use(Vuex)

const state = {
    appLoading: true,
    modals: {
        error: {
            active: false,
            message: ''
        },
        message: {
            active: false,
            title: '',
            message: '',
            onClose: null
        },
        sizePicker: {
            active: false,
            productId: ''
        },
        sizePickerSuccess: {
            active: false
        },
        purchaseSuccess: {
            active: false,
            purchaseData: {},
        },
        cartPopup: {
            active: false
        }
    },
    settings: {},
    persistentDataLoaded: false
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
      _blank,
      brands,
      banners,
      categories,
      contact,
      colors,
      countries,
      tags,
      stores,
      publishedProducts,
      purchase,
      user
  }
})
