// Import the correspondent API object
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { Country, CountryGroup } from '../../utils/Interfaces/Countries'

interface DefaultState {
    loading: boolean
    countries: Country[]
    groups: { [index: string]: CountryGroup }
}

const defaultState: DefaultState = {
    countries: [],
    groups: {},
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {}

const actions = {}

const mutations = {
    [MUTATIONS.COUNTRIES_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.COUNTRIES_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.COUNTRIES_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.COUNTRIES_LOAD_COUNTRIES] (state: DefaultState, { countries }: { countries: Country[] }) {
        state.countries = countries
    },
    [MUTATIONS.COUNTRIES_LOAD_COUNTRY_GROUPS] (state: DefaultState, { groups }: { groups: { [index: string]: CountryGroup } }) {
        state.groups = groups
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
