<template>
    <div class="information-blocks" v-if="product">
        <div class="row">
            <div class="col-sm-6 information-entry">
                <div class="product-preview-box">
                    <swiper :options="swiperOptions" ref="mySwiper">
                        <swiper-slide v-if="activePicture">
                            <div class="product-zoom-image" v-if="activePicture">
                                <img :src="activePicture" alt="" :data-zoom="activePicture" />
                            </div>
                        </swiper-slide>
                        <swiper-slide v-else>
                            <div class="product-zoom-image">
                                <img src="../../assets/img/product-no-image.jpg" alt=""/>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="product-zoom-container" v-if="product.merged.pictures.length > 0">
                        <div class="move-box">
                            <img class="default-image" :src="getPictureURL(product.merged.pictures[0])" alt="" />
                            <img class="zoomed-image" :src="getPictureURL(product.merged.pictures[0])" alt="" />
                        </div>
                        <div class="zoom-area"></div>
                    </div>
                    <div style="height: 20px;"></div>
                    <div class="swiper-hidden-edges">
                        <swiper class="product-thumbnails-swiper" :options="swiperOptionsSmall" ref="mySwiper">
                            <swiper-slide v-for="(pic, index) in product.merged.pictures"
                                          :key="'s_' + pic">
                                <div class="paddings-container">
                                    <img :src="getPictureURL(pic)"
                                         alt=""
                                         @click="selectPicture(index)"/>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 information-entry">
                <div class="product-detail-box">
                    <div class="detail-info-entry">
                        <a class="button style-11" v-if="isFavorite" @click="toggleFavorite()">
                            <i class="fa fa-heart"></i> Remover dos favoritos
                        </a>
                        <a class="button style-11" v-else @click="toggleFavorite()">
                            <i class="fa fa-heart"></i> Marcar como favorito
                        </a>
                        <div class="clear"></div>
                    </div>
                    <h1 class="product-title" v-if="product.title">{{ product.title }}</h1>
                    <h1 class="product-title" v-else>Ref: {{ product.ref }}</h1>
                    <h3 class="product-subtitle">
                        {{ product.merged.mainCategory }}
                        <template v-if="product.merged.categoryObject">
                            > {{ product.merged.categoryObject.name }}
                        </template>
                    </h3>
                    <div v-if="product.title"
                        class="tags-selector detail-info-entry">
                        <div class="detail-info-entry-title">Ref: </div>
                        {{ product.ref }}
                    </div>
                    <div class="tags-selector detail-info-entry" v-if="product.merged.color">
                        <div class="detail-info-entry-title">Cor: </div>
                        {{ product.merged.color }}
                    </div>
                    <div class="tags-selector detail-info-entry">
                        <div class="detail-info-entry-title">Marca: </div>
                        {{ product.merged.brand }}
                    </div>
                    <div class="tags-selector detail-info-entry">
                        <div class="detail-info-entry-title">Categoria: </div>
                        {{ product.merged.mainCategory }}
                        <template v-if="product.merged.categoryObject">
                            > {{ product.merged.categoryObject.name }}
                        </template>
                    </div>
                    <!-- FOR VERSION 2.0 -->
                    <div class="rating-box" v-if="false">
                        <div class="star"><i class="fa fa-star"></i></div>
                        <div class="star"><i class="fa fa-star"></i></div>
                        <div class="star"><i class="fa fa-star"></i></div>
                        <div class="star"><i class="fa fa-star-o"></i></div>
                        <div class="star"><i class="fa fa-star-o"></i></div>
                        <div class="rating-number">25 Reviews</div>
                    </div>
                    <!-- FOR VERSION 2.0 -->
                    <div class="price detail-info-entry" v-if="false">
                        <div class="prev">$90,00</div>
                        <div class="current">$70,00</div>
                    </div>
                    <div class="size-selector detail-info-entry" v-if="product.merged.sizes.length > 0">
                        <div class="detail-info-entry-title">Tamanhos disponíveis em loja</div>
                        <div v-if="selectedSize === 0"
                             class="detail-info-entry-title"
                             style="font-size: 12px">Selecione um tamanho para verificar o stock do produto</div>
                        <div v-for="size in availableSizes"
                             :key="size"
                             class="entry"
                             :class="{'active': selectedSize === size, 'entry--noStock': !sizeHasStock(size)}"
                             @click="selectedSize = selectedSize === size ? 0 : size">{{ size }}</div>
                        <template v-if="selectedSize !== 0 && productStock">
                            <div class="detail-info-entry-title">Disponibilidade de stock</div>
                            <!-- ONLINE STOCK -->
                            <div v-if="productStock.online"
                                 class="product-subtitle product__stock"
                                 :class="{'product__stock--in': productStock.online && productStock.online[selectedSize]}">
                                Loja Online
                                <template v-if="productStock.online && productStock.online[selectedSize]">
                                    <i class="fa fa-2x fa-check-circle"></i> Em stock
                                </template>
                                <template v-else>
                                    <i class="fa fa-2x fa-times-circle"></i> indisponível
                                </template>
                            </div>
                            <!-- STORES STOCK -->
                            <div class="product-subtitle product__stock"
                                 v-for="store in stores"
                                 :key="store.id"
                                 :class="{'product__stock--in': productStock[store.id] && productStock[store.id][selectedSize]}">
                                {{ store.name }}
                                <template v-if="productStock[store.id] && productStock[store.id][selectedSize]">
                                    <i class="fa fa-2x fa-check-circle"></i> Em stock
                                </template>
                                <template v-else>
                                    <i class="fa fa-2x fa-times-circle"></i> indisponível
                                </template>
                            </div>
                        </template>
                    </div>
                    <!-- FOR VERSION 2.0 -->
                    <div class="color-selector detail-info-entry" v-if="false">
                        <div class="detail-info-entry-title">Color</div>
                        <div class="entry active" style="background-color: #d23118;">&nbsp;</div>
                        <div class="entry" style="background-color: #2a84c9;">&nbsp;</div>
                        <div class="entry" style="background-color: #000;">&nbsp;</div>
                        <div class="entry" style="background-color: #d1d1d1;">&nbsp;</div>
                        <div class="spacer"></div>
                    </div>
                    <!-- FOR VERSION 2.0 -->
                    <div class="quantity-selector detail-info-entry" v-if="false">
                        <div class="detail-info-entry-title">Quantity</div>
                        <div class="entry number-minus">&nbsp;</div>
                        <div class="entry number">10</div>
                        <div class="entry number-plus">&nbsp;</div>
                    </div>
                    <div class="tags-selector detail-info-entry" v-if="product.merged.tags.length > 0">
                        <div class="detail-info-entry-title">Tags:&nbsp;</div>
                        <template v-for="(tag, tIndex) in product.merged.tags" href="#">
                            <a>{{ tag.name }}</a>
                            <template v-if="tIndex < product.merged.tags.length - 1">&nbsp;/&nbsp;</template>
                        </template>
                    </div>
                    <div class="share-box detail-info-entry">
                        <div class="title">Partilhar</div>
                        <div class="socials-box">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="price detail-info-entry" v-if="effectivePrice">
                        <template v-if="product.price > effectivePrice">
                            <div class="prev" style="margin-right: 4px">{{ product.price.toFixed(2) }} € </div>
                            <div class="current"> {{ effectivePrice.toFixed(2) }} €</div>
                        </template>
                        <template v-else>
                            <div class="current">{{ product.price.toFixed(2) }} €</div>
                        </template>
                    </div>
                    <div class="detail-info-entry">
                        <a class="button style-10" @click="addToCart()">Adicionar ao carrinho</a>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { ConstructedProduct } from '../../utils/Interfaces/Products'
import { ApiResponse } from "../../utils/Interfaces/ApiResponse"
import ACTIONS from '../../store/types-actions'
import MUTATIONS from '../../store/types-mutations'
import getImageUrl from '../../utils/ImageURL'
import CartFunction from '../../utils/Constructers/CartFunctions'

@Component({})
export default class ProductInfo extends Vue {
    @Prop({}) product!: ConstructedProduct

    get effectivePrice (): number {
        return CartFunction.processPrice(this.product)
    }

    get productId (): string {
        if (!this.product) return ''
        return this.product.id
    }

    // FAVORITE MANAGEMENT - START
    get userWishlist () {
        if (!this.$store.state.user.logged) return []
        return this.$store.state.user.user.wishlist || []
    }
    get isFavorite () {
        return this.userWishlist.indexOf(this.product.id) !== -1
    }
    toggleFavorite () {
        if (!this.$store.state.user.logged) {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'Faça login ou crie uma conta para adicionar produtos aos seus favoritos.')
            return
        }
        this.$store.dispatch(ACTIONS.USER_WISHLIST_TOGGLE_PRODUCT, { productId: this.product.id }).catch(() => {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'Não foi possível adicionar ou remover o produto à sua lista de favoritos. Por favor, tente mais tarde.')
        })
    }
    // FAVORITE MANAGEMENT - END

    // ADD TO CART MANAGEMENT - START
    get storeIsOnline () {
        return this.$store.state.settings.store_online
    }

    addToCart () {
        if (!this.storeIsOnline) {
            this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'A nossa loja está temporáriamente indisponível. Pedimos desculpa pelo incómodo. Por favor tente mais tarde.')
            return
        }

        this.$store.commit(MUTATIONS.APP_SIZE_PICKER_SHOW, { productId: this.product.id })
    }
    // ADD TO CART MANAGEMENT - END

    @Watch('productId')
    onProductChanged () {
        this.selectedSize = 0
        this.getProductStock()
        this.getProducePrice()
        this.setInitialActivePicture()
    }

    // Active picture
    activePictureIndex = 0
    get activePicture () {
        if (!this.product) return ''
        if (!this.product.merged.pictures) return ''
        if (!this.product.merged.pictures[0]) return ''

        const pictures = this.product.merged.pictures
        if (pictures[this.activePictureIndex]) {
            return this.getPictureURL(pictures[this.activePictureIndex])
        } else {
            return this.getPictureURL(pictures[0])
        }
    }
    setInitialActivePicture (): void {
        if (this.product) {
            this.activePictureIndex = this.product.merged.fixedThumbnailIndex
        }
    }
    selectPicture (index: number): void {
        // Fix selectable index outside this.product.merged.pictures lenght
        this.activePictureIndex = index
    }

    // Product stock and price
    selectedSize: number = 0
    productStock: null | any = null
    async getProductStock (): Promise<void> {
        if (!this.productId) return
        let response: ApiResponse = await this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_GET_PRODUCT_STOCK, { productId: this.productId })
        if (response.valid) {
            this.productStock = response.data
        }
    }
    getProducePrice (): void {
        if (!this.productId) return
        this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_GET_PRODUCT_PRICE, { productId: this.productId })
    }
    mounted () {
        this.getProductStock()
        this.getProducePrice()
        this.setInitialActivePicture()
    }

    swiperOptions = { slidesPerView: 1 }
    swiperOptionsSmall = { slidesPerView: 4 }

    get stores () {
        return this.$store.state.stores.storesObject
    }
    get productColor () {
        if (!this.product) return ''
    }
    get availableSizes() {
        if (!this.productStock || !this.productStock.online) return []
        const sizes = Object.keys(this.productStock.online)
        const sizesOrdered = sizes.sort((a, b) => Number(a) > Number(b) ? 1 : -1)
        sizes.sort((a, b) => a.localeCompare(b) ? 1 : -1)
        return sizesOrdered
    }

    sizeHasStock (size: number): boolean {
        if (!this.productStock) return false
        const stocks = Object.values(this.productStock) as Array<Record<string, number>>

        for (let storeStock of stocks) {
            if (storeStock[size] > 0) return true
        }
        return false
    }
    getPictureURL (picture: string): string {
        return getImageUrl({ filePath: picture })
    }
}
</script>
<style scoped lang="scss"></style>
