import HTTP from '../utils/HTTP'
import API from '../utils/API'
import { CartProduct, PurchaseFull } from "../utils/Interfaces/Purchase"

// API access to both public and admin restricted routes. Can't set up a base API_ROUTE
export const API_ROUTE = '/public/purchase'

class StoresAPI extends API {
    // Public access
    getProducts (cart: { [index: string]: CartProduct }) {
        return HTTP.api().post(`${API_ROUTE}/products`, { cart })
    }

    generate (purchaseData: PurchaseFull) {
        return HTTP.api().post(`${API_ROUTE}/generate`, purchaseData)
    }

    wait (purchaseIdShort: string) {
        return HTTP.api().post(`${API_ROUTE}/wait`, { idShort: purchaseIdShort })
    }

    confirm (intentId: string) {
        return HTTP.api().post(`${API_ROUTE}/confirm`, { intentId })
    }
}

export default new StoresAPI(API_ROUTE)
