import HTTP from '../utils/HTTP'
import API from '../utils/API'
import { NewUserClient, UserChangeData } from '../utils/Interfaces/User'
import { Address } from '../utils/Interfaces/Address'
import { CartProduct } from '../utils/Interfaces/Purchase'

// API access to both public and admin restricted routes. Can't set up a base API_ROUTE
export const API_ROUTE = '/users/client'

class BlankAPI extends API {
    // PUBLIC CALLS
    register (newUser: NewUserClient) {
        return HTTP.api().post(`${API_ROUTE}`, newUser)
    }
    getToken (email: string, password: string) {
        return HTTP.api().post(`${API_ROUTE}/token`, { email, password })
    }
    sentRecoverPassword (email: string) {
        return HTTP.api().post(`${API_ROUTE}/recover/send`, { email })
    }
    checkRecoverPassword (recoveryToken: string) {
        return HTTP.api().post(`${API_ROUTE}/recover/check`, { recoveryToken })
    }
    performRecoverPassword (recoveryToken: string, password: string) {
        return HTTP.api().post(`${API_ROUTE}/recover/perform`, { recoveryToken, password })
    }
    confirmEmail (emailCode: string) {
        return HTTP.api().post(`${API_ROUTE}/emailCode`, { emailCode })
    }

    // ACCOUNT MANAGEMENT
    reflect () {
        return HTTP.api().get(`${API_ROUTE}`)
    }
    delete (password: string) {
        return HTTP.api().post(`${API_ROUTE}/delete`, { password })
    }
    changePassword (oldPassword: string, newPassword: string) {
        return HTTP.api().patch(`${API_ROUTE}/password`, { oldPassword, newPassword })
    }
    changeData (userData: UserChangeData) {
        return HTTP.api().patch(`${API_ROUTE}/settings`, userData)
    }
    resendConfirmCode () {
        return HTTP.api().post(`${API_ROUTE}/emailCode/resend`)
    }

    // WISHLIST
    wishlistToggleProduct (productId: string) {
        return HTTP.api().post(`${API_ROUTE}/wishlist/${productId}`)
    }
    getWishlistProducts () {
        return HTTP.api().get(`${API_ROUTE}/wishlist/products`)
    }

    // CART
    cartUpdate (cart: { [index: string]: CartProduct }) {
        return HTTP.api().post(`${API_ROUTE}/cart`, { cart })
    }

    // ADDRESS's
    addressGetBoth () {
        return HTTP.api().get(`${API_ROUTE}/address`)
    }
    addressUpdate (address: Address) {
        return HTTP.api().post(`${API_ROUTE}/address`, address)
    }

    // PURCHASES
    getPurchases () {
        return HTTP.api().get(`${API_ROUTE}/purchases`)
    }
    getPurchaseProducts (purchaseId: string) {
        return HTTP.api().get(`${API_ROUTE}/purchase/${purchaseId}/products`)
    }
}

export default new BlankAPI(API_ROUTE)
