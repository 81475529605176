<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'customize' }">À Medida</router-link>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-5 text-center">
                <img src="../../assets/img/shoe_top_view.png" />
            </div>
            <div class="col-xs-12 col-md-7 text-center">
                <div class="row">
                    <div class="col-xs-12"
                         v-for="(block, index) in blocks"
                         :key="index">
                        <div class="sale-entry">
                            <div class="sale-price">
                                <i class="fa fa- fa-4x" :class="`fa-${block.icon}`"></i>
                            </div>
                            <div class="sale-description">
                                <h3 style="font-weight: 700">{{ block.title }}</h3>
                                <p>{{ block.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PageCustomize extends Vue {
    blocks = [
        {
            icon: 'sliders',
            title: 'NÚMEROS PERSONALIZADOS',
            text: 'Possibilidade de encomendar o seu numero caso não exista em stock. Algumas colecções disponibilizam 1/2 números (ex: 37,5).'
        },
        {
            icon: 'pencil',
            title: 'CORES ALTERADAS',
            text: 'Possibilidade de escolher outra cor para o mesmo modelo.'
        },
        {
            icon: 'sitemap',
            title: 'ALTERAÇÃO DE SOLA',
            text: 'Possibilidade de modificar a sola para o mesmo modelo.'
        },
        {
            icon: 'cut',
            title: 'MATERIAL PERSONALIZADO',
            text: 'Possibilidade de escolher outro material.'
        },
        {
            icon: 'arrows-alt',
            title: 'TAMANHO DO SALTO',
            text: 'Possibilidade de escolher outra altura de salto para o mesmo modelo.'
        },
        {
            icon: 'retweet',
            title: 'PARES ASSIMÉTRICOS',
            text: 'Possibilidade de variação do tamanho no par.'
        }
    ]
}
</script>
<style scoped lang="scss"></style>
