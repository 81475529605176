<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'products' }">Loja</router-link>
            <router-link :to="{ name: 'checkout' }">Checkout</router-link>
        </div>
        <div class="information-blocks">
            <div class="row">
                <div class="col-sm-9 information-entry">
                    <div class="accordeon size-1">
                        <checkout-method />
                        <checkout-contacts />
                        <checkout-address-delivery />
                        <checkout-address-invoice />
                        <checkout-payment :cartTotalPrice="cartTotalPrice"/>
                    </div>
                </div>
                <content-cart-total :cartTotalPrice="cartTotalPrice"
                                    :showCheckoutList="true"/>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CartProduct } from '../../utils/Interfaces/Purchase'
import { CartTotalPrice } from '../../utils/Constructers/CartFunctions'
import ContentCartTotal from '../content/cartTotal.vue'
import CheckoutMethod from '../checkout/method.vue'
import CheckoutContacts from '../checkout/contacts.vue'
import CheckoutAddressDelivery from '../checkout/addressDelivery.vue'
import CheckoutAddressInvoice from '../checkout/addressInvoice.vue'
import CheckoutPayment from '../checkout/payment.vue'

@Component({
    components: {
        ContentCartTotal,
        CheckoutMethod,
        CheckoutContacts,
        CheckoutAddressDelivery,
        CheckoutAddressInvoice,
        CheckoutPayment
    }
})
export default class PageCheckout extends Vue {
    @Prop({}) cart!: { [index: string]: CartProduct}
    @Prop({}) cartTotalPrice!: CartTotalPrice

    get cartIsEmpty (): boolean {
        return Object.keys(this.cart).length === 0
    }

    @Watch('cartIsEmpty', { immediate: true })
    onCartIsEmpty(isEmpty: boolean): void {
        if (isEmpty) this.$router.replace({ name: 'cart' })
    }
}
</script>
<style scoped lang="scss"></style>
