<template>
    <div class="col-sm-3 information-entry">
        <template v-if="showCheckoutList">
            <h3 class="cart-column-title size-2">Processo de checkout</h3>
            <div class="checkout-progress-widget">
                <div class="step-entry">1. Compra única ou registe-se</div>
                <div class="step-entry">2. Contactos</div>
                <div class="step-entry">3. Morada de entrega</div>
                <div class="step-entry">4. Dados de faturação (opcional)</div>
                <div class="step-entry">5. Pagamento</div>
            </div>
        </template>
        <h3 class="cart-column-title size-1" style="text-align: center;">Preço</h3>
        <div class="sidebar-subtotal">
            <div class="price-data">
                <div class="main">{{ cartTotalPrice.total.toFixed(2) }} €</div>
                <div class="title">preço total com IVA</div>
                <hr>
                <div class="title">Preço base: {{ cartTotalPrice.base.toFixed(2) }} €</div>
                <div class="title">Custo de Entrega: {{ cartTotalPrice.delivery.toFixed(2) }} €</div>
                <div class="title">IVA: {{ cartTotalPrice.ivaAmount.toFixed(2) }} €</div>
                <hr>
                <div class="subtitle">Todos os pagamentos são processados em Euros (€)</div>
            </div>
            <div class="additional-data" v-if="!showCheckoutList">
                <router-link :to="{ name: 'checkout' }"
                             class="button style-10">Checkout</router-link>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { CartTotalPrice } from '../../utils/Constructers/CartFunctions'

    @Component({})
    export default class ContentCartTotal extends Vue {
        @Prop({}) cartTotalPrice!: CartTotalPrice
        @Prop({ default: false }) showCheckoutList?: boolean
    }
</script>
<style scoped lang="scss"></style>
