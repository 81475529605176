<template>
    <div class="header-middle">
        <div class="logo-wrapper">
            <router-link :to="{ name: 'home' }"
                         id="logo"
                         href="#">
                <img src="../../assets/img/logo.png" alt="" />
            </router-link>
        </div>
        <div class="right-entries">
            <a class="header-functionality-entry" @click="toggleSearchBox"><i class="fa fa-search"></i>
                <span>Procurar</span>
                <div class="search-box popup" v-if="showSearchBox" @click.stop>
                    <form @submit.prevent="searchProduct()">
                        <div class="search-button">
                            <i class="fa fa-search"></i>
                            <input type="submit"/>
                        </div>
                        <div class="search-drop-down">
                            <div class="title" @click="showSearchBoxList = !showSearchBoxList">
                                <span>{{ categories[activeCategory].text }}</span>
                                <i class="fa fa-angle-down"></i>
                            </div>
                            <div class="list" v-if="showSearchBoxList">
                                <div class="overflow">
                                    <div class="category-entry"
                                         v-for="cat in categories"
                                         :key="cat.value"
                                         @click="selectCategory(cat.value)">{{cat.text}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="search-field">
                            <input ref="searchInput" type="text" v-model="search" placeholder="Procurar produto" />
                        </div>
                    </form>
                </div>
            </a>
            <a v-if="storeIsOnline"
               id="cartPopupButtom"
               class="header-functionality-entry"
               @click="openCartPopup()">
                <i class="fa fa-shopping-cart"></i><span>Carrinho ( {{ cartCount }} )</span>
            </a>
            <template v-if="userLogged">
                <router-link :to="{name: 'account'}"
                             class="header-functionality-entry">
                    <i class="fa fa-user"></i>
                    <span>Minha conta</span>
                </router-link>
                <router-link :to="{name: 'wishlist'}"
                             class="header-functionality-entry">
                    <i class="fa fa-heart-o"></i>
                    <span>Favoritos</span>
                </router-link>
                <a class="header-functionality-entry"
                   title="Sair da conta"
                   @click="logout()">
                    <i class="fa fa-sign-out"></i>
                </a>
            </template>
            <router-link v-else
                         :to="{name: 'sign'}"
                         class="header-functionality-entry">
                <i class="fa fa-user"></i>
                <span>Registar / Entrar</span>
            </router-link>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import MUTATIONS from '../../store/types-mutations'
import GETTERS from '../../store/types-getters'

interface Category {
    value: number
    text: string
    params: {
        isWomen: number
        isMen: number
        forShoe: number
        forAccessories: number
    }
}

@Component({})
export default class HeaderLogoAndLinks extends Vue {
    showSearchBox = false
    showSearchBoxList = false

    search = ''
    activeCategory = 1
    categories: { [index: number]: Category }= {
        1: {
            value: 1,
            text: 'Todas',
            params: {
                isWomen: 1,
                isMen: 1,
                forShoe: 1,
                forAccessories: 1
            }
        },
        2: {
            value: 2,
            text: 'Sapatos Mulher',
            params: {
                isWomen: 1,
                isMen: 0,
                forShoe: 1,
                forAccessories: 0
            }
        },
        3: {
            value: 3,
            text: 'Sapatos Homem',
            params: {
                isWomen: 0,
                isMen: 1,
                forShoe: 1,
                forAccessories: 0
            }
        },
        4: {
            value: 4,
            text: 'Acessórios Mulher',
            params: {
                isWomen: 1,
                isMen: 0,
                forShoe: 0,
                forAccessories: 1
            }
        },
        5: {
            value: 5,
            text: 'Acessórios Homem',
            params: {
                isWomen: 0,
                isMen: 1,
                forShoe: 0,
                forAccessories: 1
            }
        }
    }

    get userLogged () {
        return this.$store.state.user.logged
    }

    get cartCount () {
        return this.$store.getters[GETTERS.PURCHASE_CART_COUNT]
    }

    get storeIsOnline () {
        return this.$store.state.settings.store_online
    }

    toggleSearchBox () {
        this.showSearchBox = !this.showSearchBox
        if (this.showSearchBox) {
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    // @ts-ignore
                    this.$refs.searchInput.focus()
                }
            })
        } else {
            this.search = ''
        }
    }

    logout () {
        this.$store.dispatch(ACTIONS.USER_LOGOUT)
    }

    selectCategory (category: number) {
        this.activeCategory = category
        this.showSearchBoxList = false
    }

    openCartPopup () {
        const cartIsOpen = this.$store.state.modals.cartPopup.active
        const over1200 = window.matchMedia("(min-width: 1200px)")

        // Window less then 1200px, route to cart page
        if (!over1200.matches) {
            this.$router.push({ name: 'cart' })
            return
        }

        // Window over then 1200px, open/close cart popup
        if (cartIsOpen) {
            this.$store.commit(MUTATIONS.APP_CART_POPUP_HIDE)
        } else {
            this.$store.commit(MUTATIONS.APP_CART_POPUP_SHOW)
        }
    }

    searchProduct () {
        const category = this.categories[this.activeCategory]
        // @ts-ignore
        this.$router.push({
            name: 'products',
            // @ts-ignore 
            query: {
                search: this.search,
                // @ts-ignore
                ...category.params
            }
        })
    }
}
</script>
<style scoped lang="scss"></style>
