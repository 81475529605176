<template>
    <div class="col-sm-6 col-sm-offset-3 information-entry">
        <div v-if="formSuccess.active" class="message-box message-success">
            <div class="message-icon"><i class="fa fa-check"></i></div>
            <div class="message-text">{{ formSuccess.message }}</div>
            <div class="message-close" @click="formSuccess.active = false">
                <i class="fa fa-times"></i>
            </div>
        </div>

        <div class="login-box">
            <div class="article-container style-1">
                <h3>Recuperar password</h3>
                <p>
                    Introduza o email da sua conta.<br/>
                    Envieremos um email com instruções de recuperação.
                </p>
            </div>
            <form @submit.prevent="recover()">
                <label>Endereço de email</label>
                <input class="simple-field"
                       v-model="email"
                       type="email"
                       required>
                <div class="button style-10">
                    Recuperar
                    <input type="submit" value="">
                </div>
            </form>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormUserLogin extends Vue {
    email: string = ''
    sentEmail: boolean = false
    formSuccess = {
        active: false,
        message: ''
    }

    recover () {
        // Note. do not expose call errors.
        // Everything will always be fine.
        this.$store.dispatch(ACTIONS.USER_SEND_RECOVER_PASSWORD, this.email).finally(() => {
            this.sentEmail = true
            this.formSuccess.active = true
            this.formSuccess.message = 'Enviamos um email com instruções de recuperação da sua conta'
        })
    }
}
</script>
<style scoped lang="scss"></style>
