<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'offline' }">Em manutenção</router-link>
        </div>

        <div class="information-blocks">
            <div class="container-404">
                <div class="title">500</div>
                <div class="description">Manutenção</div>
                <div class="text">Estamos em manutenção e não é possível aceder à Jandaia de momento.</div>
                <div class="text">Pedimos desculpa pelo incómodo.</div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PageOffline extends Vue {
}
</script>
<style scoped lang="scss"></style>
