<template>
    <div class="col-sm-6 information-entry">
        <div class="login-box">
            <div class="article-container style-1">
                <h3>Entrar</h3>
                <p>Já tem conta? Introduza o email e password para iniciar sessão.</p>
            </div>
            <div v-if="formError.active" class="message-box message-danger">
                <div class="message-icon"><i class="fa fa-times"></i></div>
                <div class="message-text">{{ formError.message }}</div>
            </div>
            <form @submit.prevent="login">
                <label>Endereço de email</label>
                <input class="simple-field"
                       v-model="email"
                       type="text"
                       placeholder="Email"
                       @focus="formError.active = false"
                       required>
                <label>Password</label>
                <input class="simple-field"
                       v-model="password"
                       type="password"
                       placeholder="Password"
                       minlength="8"
                       maxlength="40"
                       @focus="formError.active = false"
                       required>
                <p>
                    Esqueceu-se da sua password?
                    <router-link :to="{name: 'recoverSignin'}">
                        Recuperar password.
                    </router-link>
                </p>
                <br/>
                <div class="button style-10">
                    Entrar
                    <input type="submit" value="">
                </div>
            </form>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormUserLogin extends Vue {
    email: string = ''
    password: string = ''
    formError = {
        active: false,
        message: ''
    }

    login () {
        this.$store.dispatch(ACTIONS.USER_GET_TOKEN, {
            email: this.email,
            password: this.password
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }
}
</script>
<style scoped lang="scss"></style>
