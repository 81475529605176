<template>
    <div>
        <div class="accordeon-title"
             :class="{ 'active': isOpen }"
             @click="isOpen = !isOpen">
            <span class="number">1</span>Compra única ou registe-se
        </div>
        <div class="accordeon-entry" v-show="isOpen">
            <div class="row" v-if="!purchaseGenerated">
                <template v-if="userIsLogged">
                    <div class="col-md-6 col-md-offset-3 information-entry">
                        <div class="article-container style-1">
                            <h3>Olá {{ $store.state.user.user.name }}!</h3>
                            <p>Se desejar fazer uma compra única sem que fique registado na sua conta, faça logout.</p>
                            <button class="button style-10" @click="logout()">
                                Sair da minha conta
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md-6 information-entry">
                        <div class="article-container style-1">
                            <h3>Fazer compra única</h3>
                            <p>
                                Pode fazer a compra sem se registar.
                                Certefique que introduz corretamente o seu email e número de telefone para que o possamos contactar caso seja necessário.
                            </p>
                            <p>Se registar uma conta poderá:</p>
                            <ul>
                                <li>Gravar produtos favoritos</li>
                                <li>Gravar produtos no carrinho</li>
                                <li>Acompanhar o processo da sua encomenda</li>
                                <li>Consultar as suas encomendas</li>
                            </ul>
                            <router-link class="button style-18"
                                         :to="{ name: 'sign', query: { redirectCheckout: true }}">
                                Registar
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-6 information-entry">
                        <div class="article-container style-1">
                            <h3>Registado? Entre na sua conta</h3>
                            <p>Entre na sua conta para facilitar o seu processo de compra e acompanhar a sua encomenda.</p>
                            <form @submit.prevent="login">
                                <label>Endereço de email</label>
                                <input class="simple-field"
                                       v-model="email"
                                       type="text"
                                       placeholder="Email"
                                       @focus="formError.active = false"
                                       required>
                                <label>Password</label>
                                <input class="simple-field"
                                       v-model="password"
                                       type="password"
                                       placeholder="Password"
                                       minlength="8"
                                       maxlength="40"
                                       @focus="formError.active = false"
                                       required>
                                <div class="button style-10">
                                    Entrar<input type="submit" value="">
                                </div>
                                <router-link class="forgot-password"
                                             :to="{ name: 'recoverSignin' }">
                                    Recuperar password.
                                </router-link>
                            </form>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import GETTERS from '../../store/types-getters'
    import ACTIONS from '../../store/types-actions'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class CheckoutMethod extends Vue {
        isOpen: boolean = true
        email: string = ''
        password: string = ''
        formError = {
            active: false,
            message: ''
        }

        get purchaseGenerated () {
            return this.$store.state.purchase.generated
        }

        get userIsLogged () {
            return this.$store.getters[GETTERS.USER_IS_LOGGED]
        }

        login () {
            this.$store.dispatch(ACTIONS.USER_GET_TOKEN, {
                email: this.email,
                password: this.password,
                keepLocalCart: true
            }).catch((error) => {
                this.formError.active = true
                this.formError.message = error.message
            })
        }

        logout (): void {
            this.$store.dispatch(ACTIONS.USER_LOGOUT)
        }

        mounted () {
            if (this.userIsLogged) this.isOpen = false
        }
    }
</script>
<style scoped lang="scss"></style>
