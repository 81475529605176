import { render, staticRenderFns } from "./mainCategories.vue?vue&type=template&id=e24aa5d6&scoped=true"
import script from "./mainCategories.vue?vue&type=script&lang=ts"
export * from "./mainCategories.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24aa5d6",
  null
  
)

export default component.exports