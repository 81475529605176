<template>
  <div class="accordeon-title"
      :class="{ 'active': isOpen }"
      @click="isOpen = !isOpen"
  >
      {{title}}

      <div
          class="accordeon-entry"
          v-if="isOpen"
          @click.stop
      >
          <div class="custom-accordion-section article-container style-1">
              <ul class="custom-accordion-list">
                  <li v-for="cat in categories"
                      :key="cat.id">
                      <router-link :to="{ name: 'products', query: { category: cat.id }}">
                          {{ cat.name }}
                      </router-link>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ProductStoreSideBar extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) categories!: Array<{ id: string, name: string }>;
  
  isOpen = false;
}
</script>
