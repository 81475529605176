<template>
    <div class="col-md-3 col-sm-4 blog-sidebar">
        <div class="information-blocks categories-border-wrapper">
            <div class="block-title size-3">Categorias</div>
            <div class="accordeon custom-accordion">
                <side-bar-group :title="'Sapatos Mulher'" :categories="womemShoeCategories" />
                <side-bar-group :title="'Sapatos Homem'" :categories="menShoeCategories" />
                <side-bar-group :title="'Acessórios Mulher'" :categories="womemAccessoriesCategories" />
                <side-bar-group :title="'Acessórios Homem'" :categories="menAccessoriesCategories" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import GETTERS from '../../../store/types-getters'
// Components
import SideBarGroup from './sideBarGroup.vue';

@Component({
    components: {
        SideBarGroup,
    }
})
export default class ProductStoreSideBar extends Vue {
    get categories () {
        return this.$store.state.categories.categoryTree
    }

    get womemShoeCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_WOMAN_SHOES]
    }

    get menShoeCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_MAN_SHOES]
    }

    get womemAccessoriesCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_WOMAN_ACCESSORIES]
    }

    get menAccessoriesCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_MAN_ACCESSORIES]
    }
}
</script>
<style scoped lang="scss"></style>
