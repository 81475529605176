import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
// Base components
import Home from './components/pages/home.vue'
import Products from './components/pages/products.vue'
import Product from './components/pages/product.vue'
import Stores from './components/pages/stores.vue'
import Cart from './components/pages/cart.vue'
import Checkout from './components/pages/checkout.vue'
import Contacts from './components/pages/contacts.vue'
import Customize from './components/pages/customize.vue'
import Sign from './components/pages/sign.vue'
import SignRecover from './components/pages/signRecover.vue'
import SignRecoverToken from './components/pages/signRecoverToken.vue'
import EmailConfirmation from './components/pages/emailConfirmation.vue'
import Account from './components/pages/account/account.vue'
import Wishlist from './components/pages/account/wishlist.vue'
import AccountDelete from './components/pages/account/delete.vue'
import AccountPurchases from './components/pages/account/purchases.vue'
import Offline from './components/pages/offline.vue'
import Maintenance from './components/pages/Maintenance.vue'
import About from './components/pages/about.vue'
import Privacy from './components/pages/privacy.vue'

import Terms from './components/pages/terms.vue'
import NotFound from './components/pages/notFound.vue'
// Add-ons
import Header from './components/header/header.vue'

Vue.use(Router)

export default new Router({
  routes: [
      {
          path: '/',
          name: 'root',
          redirect: { name: 'home' }
      },
      {
          path: '/home',
          name: 'home',
          components: {
              default: Home,
              header: Header
          }
      },
      {
          path: '/products',
          name: 'products',
          components: {
              default: Products,
              header: Header
          }
      },
      {
          path: '/product/:id',
          name: 'product',
          components: {
              default: Product,
              header: Header
          }
      },
      {
          path: '/stores',
          name: 'stores',
          components: {
              default: Stores,
              header: Header
          }
      },
      {
          path: '/contacts',
          name: 'contacts',
          components: {
              default: Contacts,
              header: Header
          }
      },
      {
          path: '/customize',
          name: 'customize',
          components: {
              default: Customize,
              header: Header
          }
      },
      {
          path: '/cart',
          name: 'cart',
          components: {
              default: Cart,
              header: Header
          }
      },
      {
          path: '/checkout',
          name: 'checkout',
          components: {
              default: Checkout,
              header: Header
          }
      },
      {
          path: '/sign',
          name: 'sign',
          components: {
              default: Sign,
              header: Header
          }
      },
      {
          path: '/sign/recover',
          name: 'recoverSignin',
          components: {
              default: SignRecover,
              header: Header
          }
      },
      {
          path: '/sign/recover/:token',
          name: 'recoverPassword',
          components: {
              default: SignRecoverToken,
              header: Header
          }
      },
      {
          path: '/account/settings',
          name: 'account',
          components: {
              default: Account,
              header: Header
          },
          beforeEnter: (to, from, next) => {
            // @ts-ignore 
            if (!store.state.user.logged) {
                next({ name: 'sign'})
                return
            }
            next()
          }
      },
      {
          path: '/account/wishlist',
          name: 'wishlist',
          components: {
              default: Wishlist,
              header: Header
          },
          beforeEnter: (to, from, next) => {
            // @ts-ignore 
            if (!store.state.user.logged) {
                next({ name: 'sign'})
                return
            }
            next()
          }
      },
      {
          path: '/account/delete',
          name: 'accountDelete',
          components: {
              default: AccountDelete,
              header: Header
          },
          beforeEnter: (to, from, next) => {
            // @ts-ignore 
            if (!store.state.user.logged) {
                next({ name: 'sign'})
                return
            }
            next()
          }
      },
      {
          path: '/account/purchases',
          name: 'accountPurchases',
          components: {
              default: AccountPurchases,
              header: Header
          },
          beforeEnter: (to, from, next) => {
            // @ts-ignore 
            if (!store.state.user.logged) {
                next({ name: 'sign'})
                return
            }
            next()
          }
      },
      // NOTE!!!: if route changes, update EmailSender URL structure
      {
          path: '/email/confirmation/emailCode/:emailCode',
          name: 'emailConfirmation',
          components: {
              default: EmailConfirmation,
              header: Header
          }
      },
      {
          path: '/offline',
          name: 'offline',
          components: {
              default: Offline,
              header: Header
          }
      },
      {
          path: '/maintenance',
          name: 'maintenance',
          components: {
              default: Maintenance,
              header: Header
          }
      },
      {
          path: '/about',
          name: 'about',
          components: {
              default: About,
              header: Header
          }
      },
      {
          path: '/terms',
          name: 'terms',
          components: {
              default: Terms,
              header: Header
          }
      },
      {
          path: '/privacy',
          name: 'privacy',
          components: {
              default: Privacy,
              header: Header
          }
      },
      {
          path: '/*',
          name: 'notFound',
          components: {
              default: NotFound,
              header: Header
          }
      }
  ]
})
