import MUTATIONS from './types-mutations'
import { Settings } from '../utils/Interfaces/Settings'

// Root store mutations
const mutations = {
    [MUTATIONS.APP_LOADING_START] (state: any) {
        state.appLoading = true
    },
    [MUTATIONS.APP_LOADING_FINISH] (state: any) {
        state.appLoading = false
    },
    [MUTATIONS.APP_PERSISTENT_DATA_LOADED] (state: any) {
        state.persistentDataLoaded = true
    },
    [MUTATIONS.APP_SETTINGS_LOAD] (state: any, { settings }: { settings: Settings}) {
        state.settings = settings
    },
    [MUTATIONS.APP_ERROR_SHOW] (state: any, message: string) {
        state.modals.error.message = message
        state.modals.error.active = true
    },
    [MUTATIONS.APP_ERROR_HIDE] (state: any) {
        state.modals.error.active = false
    },
    [MUTATIONS.APP_MESSAGE_SHOW] (state: any, { title, message, onClose }: { title: string, message: string, onClose?: Function }) {
        state.modals.message.title = title
        state.modals.message.message = message
        state.modals.message.onClose = onClose || null
        state.modals.message.active = true
    },
    [MUTATIONS.APP_MESSAGE_HIDE] (state: any) {
        state.modals.message.active = false
        state.modals.message.onClose = null
    },
    [MUTATIONS.APP_SIZE_PICKER_SHOW] (state: any, { productId }: { productId: string }) {
        state.modals.sizePicker.productId = productId
        state.modals.sizePicker.active = true
    },
    [MUTATIONS.APP_SIZE_PICKER_HIDE] (state: any) {
        state.modals.sizePicker.productId = ''
        state.modals.sizePicker.active = false
    },
    [MUTATIONS.APP_SIZE_PICKER_SUCCESS_SHOW] (state: any) {
        state.modals.sizePickerSuccess.active = true
    },
    [MUTATIONS.APP_SIZE_PICKER_SUCCESS_HIDE] (state: any) {
        state.modals.sizePickerSuccess.active = false
    },
    [MUTATIONS.APP_PURCHASE_SUCCESS_SHOW] (state: any, purchaseData: object) {
        state.modals.purchaseSuccess.active = true
        state.modals.purchaseSuccess.purchaseData = purchaseData
    },
    [MUTATIONS.APP_PURCHASE_SUCCESS_HIDE] (state: any) {
        state.modals.purchaseSuccess.active = false
        state.modals.purchaseSuccess.purchaseData = {}
    },
    [MUTATIONS.APP_CART_POPUP_SHOW] (state: any) {
        state.modals.cartPopup.active = true
    },
    [MUTATIONS.APP_CART_POPUP_HIDE] (state: any) {
        state.modals.cartPopup.active = false
    }
}

export default mutations
