<template>
    <div class="col-sm-6 information-entry">
        <div class="login-box">
            <div class="article-container style-1">
                <h3>Registar</h3>
                <p>Registe-se na Jandaia para um processo de compra mais fácil, guarde os seus produtos favoritos e outras funcionalidades.</p>
            </div>
            <div v-if="formError.active" class="message-box message-danger">
                <div class="message-icon"><i class="fa fa-times"></i></div>
                <div class="message-text">{{ formError.message }}</div>
            </div>
            <form @submit.prevent="register">
                <label>Nome (*)</label>
                <input class="simple-field"
                       v-model="user.name"
                       name="name"
                       type="text"
                       placeholder="Primeiro e último nome"
                       maxlength="150"
                       required>
                <label>Email (*)</label>
                <input class="simple-field"
                       v-model="user.email"
                       name="email"
                       type="email"
                       placeholder="Email"
                       maxlength="75"
                       required>
                <label>Password (*)</label>
                <input class="simple-field"
                       v-model="user.password"
                       name="password"
                       type="password"
                       placeholder="Password"
                       maxlength="40"
                       minlength="8"
                       required>
                <label>Confirmar password (*)</label>
                <input class="simple-field"
                       v-model="user.passwordConfirm"
                       name="passwordConfirm"
                       type="password"
                       placeholder="Confirmar password"
                       maxlength="40"
                       minlength="8"
                       required>
                <label>Telefone</label>
                <input class="simple-field"
                       v-model="user.phone"
                       name="phone"
                       type="text"
                       placeholder="Telefone"
                       maxlength="15"
                       @input="healPhoneNumber()">
                <div>
                    <label class="checkbox-entry">
                        <input type="checkbox" v-model="acceptPrivacyPolicy" > <span class="check"></span> 
                        Aceito os termos de <a href="#/privacy" target="_blank">Política de Privacidade</a>.
                    </label>
                </div>
                <div class="button style-10">
                    Registar
                    <input type="submit" value="">
                </div>
            </form>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { NewUserClient } from '../../utils/Interfaces/User'
import { healPhone, INDICATIVO } from '../../utils/formHealers'
import ACTIONS from '../../store/types-actions'
import MUTATIONS from '../../store/types-mutations'

@Component({})
export default class FormUserRegister extends Vue {
    formError = {
        active: false,
        message: ''
    }

    acceptPrivacyPolicy: boolean = false

    user = {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        phone: '(351) '
    }

    healPhoneNumber () {
        this.user.phone = healPhone(this.user.phone)
    }

    formIsValid (): boolean {
        this.formError.active = false
        this.formError.message = ''

        if (this.user.password !== this.user.passwordConfirm) {
            this.formError.active = true
            this.formError.message = 'As password não coincidem'
            return false
        }

        if (this.user.phone.length > 6) {
            if (this.user.phone.length !== 15 || this.user.phone.substr(0, 6) !== INDICATIVO) {
                this.formError.active = true
                this.formError.message = 'Introduza um número de telefone válido'
                return false
            }
        }

        return true
    }

    register () {
        if (!this.formIsValid()) return
        if (!this.acceptPrivacyPolicy) {
            this.$store.commit(MUTATIONS.APP_MESSAGE_SHOW, {
                title: 'Politica de privacidade',
                message: 'Para criar uma conta é necessário que tome conhecimento e aceite a nossa política de privacidade.'
            })
            return
        }

        const newUser: NewUserClient = {
            name: this.user.name,
            email: this.user.email,
            password: this.user.password
        }
        if (this.user.phone.length > 6) newUser.phone = this.user.phone.substr(6, 9)

        this.$store.dispatch(ACTIONS.USER_REGISTER, newUser).then(() => {
            this.formError.active = false
            // this.user.email = ''
            // this.user.name = ''
            // this.user.password = ''
            // this.user.passwordConfirm = ''
            // this.user.phone = INDICATIVO
            // TODO: Redirect to user page
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }

}
</script>
<style scoped lang="scss"></style>
