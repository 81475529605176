// Import the correspondent API object
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
import GETTERS from '../types-getters'
// Store specific imports
import userAPI from "../..//api/user";
import { NewUserClient, UserClient, UserChangeData } from '../../utils/Interfaces/User'
import { Address } from '../../utils/Interfaces/Address'
// Other helper imports
import { cloneDeep } from 'lodash'
import { setCookie, removeCookie } from 'tiny-cookie'
import Vue from 'vue'
import HTTP from '../../utils/HTTP'
import {CartProduct} from "@/utils/Interfaces/Purchase";

interface DefaultState {
    loading: boolean
    logged: boolean
    token: string
    user: null | UserClient
    address: {
        delivery: undefined | Address
        invoice: undefined | Address
    }
}

const defaultState: DefaultState = {
    loading: false,
    logged: false,
    token : '',
    user: null,
    address: {
        delivery: undefined,
        invoice: undefined
    }
}

let state = cloneDeep(defaultState)

const getters = {
    [GETTERS.USER_IS_LOGGED]: (state: DefaultState) => {
        return state.logged
    },
    [GETTERS.USER_ID]: (state: DefaultState) => {
        if (!state.user) return null
        return state.user.id
    }
}

const actions = {
    [ACTIONS.USER_LOGOUT] ({ commit }: { commit: any }) {
        commit(MUTATIONS.USER_DELETE_TOKEN)
        commit(MUTATIONS.USER_RESET)
        commit(MUTATIONS.PURCHASE_RESET_SOFT)
    },
    async [ACTIONS.USER_REGISTER] ({ commit, dispatch, rootState }: { commit: any, dispatch: any, rootState: any}, newUser: NewUserClient ) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.register(newUser).then((response: any) => {
            commit(MUTATIONS.USER_SET_TOKEN, response.data.data.token)
            commit(MUTATIONS.USER_LOG_USER, response.data.data.user)
            dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: rootState.purchase.cart })
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_GET_TOKEN] ({ commit, dispatch, rootState }: { commit: any, dispatch: any, rootState: any }, { email, password, keepLocalCart = false }: { email: string, password: string, keepLocalCart?: boolean } ) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.getToken(email, password).then((response: any) => {
            commit(MUTATIONS.USER_SET_TOKEN, response.data.data.token)
            commit(MUTATIONS.USER_LOG_USER, response.data.data.user)
            // if keepLocalCart, save the local cart, otherwise load the users one
            if (keepLocalCart) {
                dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: rootState.purchase.cart })
            } else {
                dispatch(ACTIONS.PURCHASE_CART_LOAD, { cartString: response.data.data.user.cart})
            }
            dispatch(ACTIONS.USER_ADDRESS_GET_BOTH)
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_SEND_RECOVER_PASSWORD] ({ commit }: { commit: any }, email: string ) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.sentRecoverPassword(email).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_CHECK_RECOVER_PASSWORD] ({ commit }: { commit: any }, recoveryToken: string ) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.checkRecoverPassword(recoveryToken).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_PERFORM_RECOVER_PASSWORD] ({ commit }: { commit: any }, { recoveryToken, password }: { recoveryToken: string, password: string }) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.performRecoverPassword(recoveryToken, password)
            .catch((error) => { throw new Error(error.response.data.error.message) })
            .finally(() => { commit(MUTATIONS.USER_REQUEST_FINISH) })
    },
    async [ACTIONS.USER_REFLECT] ({ commit, state, dispatch}: { commit: any, state: DefaultState, dispatch: any }) {
        if (state.token === '') return
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.reflect().then((response: any) => {
            commit(MUTATIONS.USER_LOG_USER, response.data.data)
            dispatch(ACTIONS.PURCHASE_CART_LOAD, { cartString: response.data.data.cart})
            dispatch(ACTIONS.USER_ADDRESS_GET_BOTH)
        }).catch((error) => {
            commit(MUTATIONS.USER_DELETE_TOKEN)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_DELETE_ACCOUNT] ({ commit }: { commit: any }, password: string) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.delete(password).then((response: any) => {
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_CHANGE_PASSWORD] ({ commit }: { commit: any }, { oldPassword, newPassword }: { oldPassword: string, newPassword: string }) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.changePassword(oldPassword, newPassword).then((response: any) => {
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    [ACTIONS.USER_CONFIRM_EMAIL] ({ commit }: { commit: any }, { emailCode }: { emailCode: string }) {
        commit(MUTATIONS.USER_REQUEST_START)
        return userAPI.confirmEmail(emailCode).then((response: any) => {
            commit(MUTATIONS.USER_EMAIL_CONFIRMED)
            return response.data.data
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_RESEND_CONFIRM_EMAIL] ({ commit }: { commit: any }) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.resendConfirmCode()
            .catch((error) => { throw new Error(error.response.data.error.message) })
            .finally(() => { commit(MUTATIONS.USER_REQUEST_FINISH) })
    },
    async [ACTIONS.USER_CHANGE_DATA] ({ commit, dispatch }: { commit: any, dispatch: any }, userData: UserChangeData) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.changeData(userData).then(async (response: any) => {
            await dispatch(ACTIONS.USER_REFLECT)
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_WISHLIST_TOGGLE_PRODUCT] ({ commit }: { commit: any }, { productId }: { productId: string }) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.wishlistToggleProduct(productId).then(async (response: any) => {
            commit(MUTATIONS.USER_WISHLIST_UPDATE, response.data.data)
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    [ACTIONS.USER_WISHLIST_GET_PRODUCTS] ({ commit }: { commit: any }) {
        commit(MUTATIONS.USER_REQUEST_START)
        return userAPI.getWishlistProducts().then((response: any) => {
            return response.data.data
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_ADDRESS_GET_BOTH] ({ commit }: { commit: any }) {
        commit(MUTATIONS.USER_REQUEST_START)
        await userAPI.addressGetBoth().then(async (response: any) => {
            const addresses = response.data.data
            if (addresses.delivery) commit(MUTATIONS.USER_ADDRESS_UPDATE_ADDRESS, addresses.delivery)
            if (addresses.invoice) commit(MUTATIONS.USER_ADDRESS_UPDATE_ADDRESS, addresses.invoice)
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_ADDRESS_UPDATE_ADDRESS] ({ commit }: { commit: any }, address: Address) {
        commit(MUTATIONS.USER_REQUEST_START)
        return userAPI.addressUpdate(address).then((response: any) => {
            commit(MUTATIONS.USER_ADDRESS_UPDATE_ADDRESS, response.data.data)
        }).catch((error) => {
            throw new Error(error.response.data.error.message)
        }).finally(() => {
            commit(MUTATIONS.USER_REQUEST_FINISH)
        })
    },
    async [ACTIONS.USER_CART_UPDATE_CART] ({ commit, state }: { commit: any, state: DefaultState }, { cart }: { cart: { [index: string]: CartProduct }}) {
        if (!state.logged) return
        userAPI.cartUpdate(cart).then(() => {
            commit(MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE)
        })
    },
    [ACTIONS.USER_GET_PURCHASES] ({ commit, state }: { commit: any, state: DefaultState }) {
        if (!state.logged) return []
        return userAPI.getPurchases().then((response) => {
            return response.data.data
        }).catch(() => {
            return []
        })
    },
    [ACTIONS.USER_GET_PURCHASE_PRODUCTS] ({ commit, state }: { commit: any, state: DefaultState }, { purchaseId }: { purchaseId: string }) {
        if (!state.logged) return []
        return userAPI.getPurchaseProducts(purchaseId).then((response) => {
            return response.data.data
        }).catch(() => {
            return []
        })
    },
}

const mutations = {
    [MUTATIONS.USER_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.USER_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.USER_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.USER_LOG_USER] (state: DefaultState, user: UserClient) {
        state.logged = true
        state.user = user
    },
    [MUTATIONS.USER_SET_TOKEN] (state: DefaultState, token: string) {
        setCookie('authToken', token, { expires: '30D' })
        HTTP.setHeader({key: 'authorization', value: token})
        state.token = token
    },
    [MUTATIONS.USER_DELETE_TOKEN] (state: DefaultState, token: string) {
        removeCookie('authToken')
        HTTP.unsetHeader({ key: 'authorization' })
        state.token = ''
    },
    [MUTATIONS.USER_EMAIL_CONFIRMED] (state: DefaultState) {
        if (!state.user) return
        state.user.emailConfirmed = true
    },
    [MUTATIONS.USER_WISHLIST_UPDATE] (state: DefaultState, wishlist: string[]) {
        if (state.user === null) return
        state.user.wishlist = wishlist
    },
    [MUTATIONS.USER_ADDRESS_UPDATE_ADDRESS] (state: DefaultState, address: Address) {
        if (address.forInvoice) {
            state.address.invoice = address
            return
        }
        state.address.delivery = address
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
