<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'terms' }">Termos e Condições</router-link>
        </div>

        <div class="information-blocks">
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <div class="blog-landing-box type-1 detail-post">
                        <div class="blog-entry">
                            <div class="content">
                                <div class="article-container style-1">
                                    <h1 class="title">Termos e condições</h1>
                                    <p>
                                        <b>1 - </b> Os presentes termos e condições definem as normas que regulam o registo, encomenda e contrato de compra de produtos através do site <a href="https://www.jandaia.pt">https://www.jandaia.pt</a>. Para os seus efeitos os intervenientes reconhecem que:

                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>a.</b> Jandaia – Sapataria Jandaia Lda, sociedade por quotas, com sede na {{ settings.contact_address }}, número de pessoa colectiva <span class="mono-font">{{ settings.contact_nif }}</span>, capital social <span class="mono-font">€10.000,00</span>, detentora do endereço jandaia.pt, e fornecedor dos produtos disponíveis em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a>. Contacto para qualquer questão através do telefone <a :href="`tel:${settings.contact_phone}`" class="mono-font" target="_blank">{{ settings.contact_phone }}</a> ou e-mail <a :href="`mailto:${settings.contact_email}`" target="_blank">{{ settings.contact_email }}</a>
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>b.</b> Cliente – toda a pessoa singular que consulte, efectue o registo ou encomende produtos através do site <a href="https://www.jandaia.pt">https://www.jandaia.pt</a>,
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>c.</b> Os termos e condições gerais incorporam os direitos e garantias do Cliente, previstos no Decreto-Lei 24/2014 de 14 de Fevereiro e restante legislação para contractos desta natureza.
                                        </span>
                                    </p>
                                    <p>
                                        <b>2 - </b> Encomendas online

                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>a.</b> Para realizar uma encomenda não é obrigatório estar registado em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a>.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>b.</b>Caso pretenda efectuar o registo em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a> , ficará com acesso à conta pessoal online e poderá: <br/>
                                            Gravar produtos favoritos <br/>
                                            Adicionar produtos no carrinho  <br/>
                                            Acompanhar o processo da sua encomenda  <br/>
                                            Consultar as suas encomendas  <br/>
                                            Editar os seus dados
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>c.</b> Após a selecção dos produtos para o carrinho e confirmação da encomenda, receberá um email da Jandaia com a confirmação e respectivo código da encomenda.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>d.</b> Poderá seguir o estado da encomenda em tempo real, através do link (API CTT) disponível no site, ou pelo tracking number que a Jandaia lhe enviará
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>e.</b> O prazo de entrega da encomenda depende da zona geográfica do endereço, e será efectuado em dia útil até às 19horas. Consulte o “link Envios”.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>f.</b> A validação final do pedido, implica a aceitação das presentes condições gerais, preço e características do produto, e normas legais para aquisições desta natureza
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>g.</b> A Jandaia pode cancelar uma encomenda se os artigos estiverem esgotados, e procederá ao reembolso de todos os montantes pagos pelo Cliente
                                        </span>
                                    </p>
                                    <p>
                                        <b>3 - </b> Política de preços

                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>a.</b> Os preços indicados em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a> incluem o IVA à taxa legal aplicável
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>b.</b> Os preços podem ser actualizados a qualquer momento, sem aviso prévio
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>c.</b> Ao preço de venda indicado acrescem, quando devidas, as despesas de envio “link Envios”
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>d.</b> O modo de pagamento disponibilizado em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a> , respeita as normas de segurança mais exigentes e não acarreta custos para o Cliente
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>e.</b> O pagamento é efectuado em <a href="https://www.jandaia.pt">https://www.jandaia.pt</a> através de Cartão de Crédito/Multibanco
                                        </span>
                                    </p>
                                    <p>
                                        <b>4 - </b> Cancelamento, Trocas e Devoluções

                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>a.</b> O Cliente pode cancelar a encomenda a qualquer momento até à recepção da mesma, com direito ao reembolso de todos os montantes pagos
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>b.</b> A partir da recepção da encomenda, o Cliente pode exercer o direito de livre resolução, sem motivo justificativo e sem incorrer em custos, no prazo de 30 dias.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>c.</b> A devolução poderá ocorrer em qualquer loja física da Jandaia, ou através de envio por correio para o endereço: <br/>
                                                Rua dos Lagares d’el Rei, 19D, r/c Dto, 1700-268 Lisboa. <br/>
                                            O envio do produto fica à responsabilidade do cliente, sendo o próprio a suportar os custos.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>d.</b> O produto tem de se apresentar em estado novo, sem sinais de uso ou danificado de alguma forma, e na embalagem original de recepção da encomenda, juntamente com cópia da factura
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>e.</b> O Cliente pode solicitar a troca de tamanho, ou por outro produto de preço equivalente
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>f.</b> A troca só seguirá após a recepção pela Jandaia do produto em estado novo, na embalagem original, sem sinais de uso ou danificado de alguma forma, e após o consentimento do Cliente para se efectuar a troca
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>g.</b> Se preferir pode seleccionar a localização da loja física que mais lhe convêm e aí verificar/experimentar o tamanho adequado para efectuar a troca.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>h.</b> Não podem ser considerados defeitos, quaisquer danos ocorridos após manuseamento indevido ou influência de factores externos fora do contexto normal de utilização do produto.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>i.</b> Após confirmação pela Jandaia de que o artigo foi devolvido nas condições em que foi entregue ao Cliente, o processo de reembolso será iniciado e concretizado pela mesma via de pagamento ou por transferência bancária, num prazo máximo de 14 dias úteis.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>j.</b> No caso de não receber a encomenda, ou que a mesma se apresente em condições desadequadas, deverá entrar em contacto com apoiaocliente@jandaia.pt ou através numero <a :href="`tel:${settings.contact_phone}`" class="mono-font" target="_blank">{{ settings.contact_phone }}</a>.
                                        </span>
                                    </p>
                                    <p>
                                        <b>5 - </b> Outros
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>a.</b> As despesas decorrentes da devolução da encomenda serão suportadas pelo Cliente.
                                        </span>
                                        <span style="display: block; margin: 10px 0 0 20px">
                                            <b>b.</b> O Cliente pode apresentar reclamação através do e-mail <a :href="`mailto:${settings.contact_email}`" target="_blank">{{ settings.contact_email }}</a>  por escrito em carta enviada a Rua dos Lagares d’el Rei, 19D, r/c Dto, 1700-268 Lisboa, ou através do livro de reclamações online ( <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noopener noreferrer">www.livrodereclamacoes.pt</a> )
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PageTerms extends Vue {
    get settings () {
        return this.$store.state.settings
    }
}
</script>
<style scoped lang="scss"></style>
