<template>
    <div class="col-sm-9 col-sm-push-3 information-entry">
        <div class="accordeon size-1">
            <div class="accordeon-title"
                 @click="showAccountData = !showAccountData">
                <span class="number">1</span>Dados da conta
            </div>
            <div class="accordeon-entry" v-if="showAccountData">
                <div class="row">
                    <form-user-settings />
                </div>
            </div>
            <div class="accordeon-title"
                 @click="showDeliverAddress = !showDeliverAddress">
                <span class="number">2</span>Morada de entrega
            </div>
            <div class="accordeon-entry" v-if="showDeliverAddress">
                <div class="row">
                    <form-user-address :forInvoice="false"/>
                </div>
            </div>
            <div class="accordeon-title"
                 @click="showInvoiceAddress = !showInvoiceAddress">
                <span class="number">3</span>Morada de faturação
            </div>
            <div class="accordeon-entry" v-if="showInvoiceAddress">
                <div class="row">
                    <form-user-address :forInvoice="true"/>
                </div>
            </div>
            <div class="accordeon-title"
                 @click="showChangePassword = !showChangePassword">
                <span class="number">4</span>Alterar password
            </div>
            <div class="accordeon-entry" v-if="showChangePassword">
                <div class="row">
                    <form-user-password />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import FormUserSettings from '../forms/userSettings.vue'
    import FormUserAddress from '../forms/userAddress.vue'
    import FormUserPassword from '../forms/userPassword.vue'

    @Component({
        components: {
            FormUserSettings,
            FormUserAddress,
            FormUserPassword
        }
    })
    export default class ContentSettings extends Vue {
        showAccountData: boolean = false
        showDeliverAddress: boolean = false
        showInvoiceAddress: boolean = false
        showChangePassword: boolean = false
    }
</script>
<style scoped lang="scss"></style>
