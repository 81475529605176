<template>
    <div class="content-push">
        <!-- BREADCRUMB -->
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'products' }">Loja</router-link>
        </div>
        <!-- SHOP -->
        <div class="information-blocks">
            <div class="row" style="min-height: 500px; padding-bottom: 40px;">
                <store-side-bar />
                <store-grid
                    :products="activeProducts"
                    :continueSearch="!activeSearch.finished"
                    id="testId"
                    :ref="'productsGrid'"
                    @loadMore="loadMore()"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator'
import { PublishedProduct, ProductsBaseSearch } from '../../utils/Interfaces/Products'
import ACTIONS from '../../store/types-actions'
import GETTERS from '../../store/types-getters'
import StoreSideBar from '../product/store/sideBar.vue'
import StoreGrid from '../product/store/grid.vue'
import { Route } from 'vue-router'

@Component({
    name: 'PageProducts',
    components: {
        StoreSideBar,
        StoreGrid
    }
})
export default class PageProducts extends Vue {
    isIdle: boolean = false;
    saveScrollPosition: number = 0;
    previousRouteFullPath = '';
    $refs!: {
        productsGrid: HTMLDivElement;
    }

    loadProducts(restart = false) {
        if (this.isIdle) return;
        this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_SEARCH, {
            searchParams: this.searchParams,
            restart
        })
    }
    loadMore () {
        this.loadProducts(false)
    }

    get activeSearch () {
        return this.$store.state.publishedProducts.search
    }
    get activeProducts (): PublishedProduct[] {
        return this.activeSearch.products.map((pId: string) => {
            return this.$store.getters[GETTERS.PRODUCTS_GET_PRODUCT](pId)
        })
    }
    // Search Params
    get newCollection (): number {
        // @ts-ignore
        return Number(this.$route.query.newP) === 1 ? 1 : 0
    }
    get searchCategory () {
        const categories = this.$route.query.category || ''
        if (categories === String(categories)) return categories
        return categories[0]
    }
    get searchSearch (): string {
        // @ts-ignore
        return this.$route.query.search || ''
    }
    get searchIsWomen (): number {
        // @ts-ignore
        return Number(this.$route.query.isWomen) === 1 ? 1 : 0
    }
    get searchIsMen (): number {
        // @ts-ignore
        return Number(this.$route.query.isMen) === 1? 1 : 0
    }
    get searchForShoe (): number {
        // @ts-ignore
        return Number(this.$route.query.forShoe) === 1? 1 : 0
    }
    get searchForAcessorie (): number {
        // @ts-ignore
        return Number(this.$route.query.forAccessories) === 1? 1 : 0
    }
    get searchParams (): ProductsBaseSearch {
        return {
            newCollection: this.newCollection,
            category: this.searchCategory,
            search: this.searchSearch,
            isWomen: this.searchIsWomen,
            isMen: this.searchIsMen,
            forShoe: this.searchForShoe,
            forAccessories: this.searchForAcessorie
        }
    }

    @Watch('$route', { deep: true })
    onRouteChange (_newRoute: Route, previousRoute: Route): void {
        if (previousRoute.name === 'products') {
            this.previousRouteFullPath = previousRoute.fullPath;
        }

        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        // Size when category menu is above products
        const mobileBreakpoint = 767;
        if (width <= mobileBreakpoint) {
            // @ts-ignore
            const gridTop = this.$refs.productsGrid.$el.getBoundingClientRect().top;
            const scrollBy = -120 + gridTop;
            window.scrollTo({top: scrollBy, behavior: 'smooth'});
        }
    }

    activated() {
        this.isIdle = false
        const currentPath = this.$route.fullPath;

        // New search
        if (currentPath !== this.previousRouteFullPath) {
            this.loadProducts(true);
        } else {
            const scrollDif = this.saveScrollPosition - window.pageYOffset
            setTimeout(() => { window.scrollBy(0, scrollDif) }, 75)
        }
    }

    deactivated() {
        this.isIdle = true
    }

    @Watch('searchParams')
    onSearchParamsChange () {
        this.loadProducts(true)
    }

    created () {
        // Register listener to keep scroll updated
        window.addEventListener('scroll', () => {
            if (this.isIdle) return
            this.saveScrollPosition = window.pageYOffset
        })
    }
}
</script>
<style scoped lang="scss"></style>
