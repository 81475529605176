import PersistentApi from '../api/persistent'
import ACTIONS from './types-actions'
import MUTATIONS from './types-mutations'


// Root store actions
const actions = {
    [ACTIONS.RESET_ALL_STORES_MODULES] ({ commit }: { commit: any }) {
        commit(MUTATIONS.BLANK_RESET)
        commit(MUTATIONS.BRANDS_RESET)
        commit(MUTATIONS.CATEGORIES_RESET)
        commit(MUTATIONS.COLORS_RESET)
        commit(MUTATIONS.PUBLISHED_PRODUCTS_RESET)
        commit(MUTATIONS.TAGS_RESET)
        commit(MUTATIONS.COUNTRIES_RESET)
    },
    async [ACTIONS.LOAD_PERSISTENT_STORES] ({ state, commit } : { state: any, commit: any }) {
        if (state.persistentDataLoaded) return
        commit(MUTATIONS.APP_LOADING_START)
        await PersistentApi.get().then((response: any) => {
            const data = response.data.data
            // Load store data
            commit(MUTATIONS.CATEGORIES_LOAD, { categoryTree: data.categoryTree })
            commit(MUTATIONS.COLORS_LOAD, { colorsObject: data.colors })
            commit(MUTATIONS.BRANDS_LOAD, { brandsObject: data.brands })
            commit(MUTATIONS.BANNERS_LOAD, { bannersObject: data.banners })
            commit(MUTATIONS.TAGS_LOAD, { tagsObject: data.tags })
            commit(MUTATIONS.APP_SETTINGS_LOAD, { settings: data.settings })
            commit(MUTATIONS.STORES_LOAD, { storesObject: data.stores })
            commit(MUTATIONS.COUNTRIES_LOAD_COUNTRIES, { countries: data.countries })
            commit(MUTATIONS.COUNTRIES_LOAD_COUNTRY_GROUPS, { groups: data.countryGroups })
            // Set persistent has initialized
            commit(MUTATIONS.APP_PERSISTENT_DATA_LOADED)
        }).finally(() => {
            commit(MUTATIONS.APP_LOADING_FINISH)
        })
    }
}

export default actions
