<template>
    <div>
        <div class="accordeon-title"
             :class="{ 'active': isOpen }"
             @click="isOpen = !isOpen">
            <span class="number">3</span>Morada de entrega
        </div>
        <div class="accordeon-entry" v-show="isOpen">
            <div class="message-box message-warning" v-if="userIsLogged">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    Pode alterar a morada de entrega apenas para esta encomenda. A morada de entrega na sua conta permanecerá inalterada.
                    Para alterar a morada de entrega definitivamente utilize o formulário na página <router-link :to="{name: 'account'}">Minha conta</router-link>.
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <div class="article-container style-1">
                        <!-- Name -->
                        <label>Nome do destinatário (obrigatório)</label>
                        <input v-model="name"
                               type="text"
                               name="name"
                               placeholder="Primeiro e último nome"
                               class="simple-field width"
                               maxlength="300"
                               :disabled="purchaseGenerated"
                               required/>
                        <!-- Country -->
                        <label>País (obrigatório)</label>
                        <select v-model="country"
                                name="country"
                                class="simple-field width"
                                :disabled="purchaseGenerated"
                                required>
                            <option v-for="country in countries"
                                    :key="country.name"
                                    :value="country.name">{{ country.name }}</option>
                        </select>
                        <label>Morada (obrigatório)</label>
                        <input v-model="street"
                               type="text"
                               name="address"
                               placeholder="Avenida, Praceta, Rua..."
                               class="simple-field width"
                               maxlength="300"
                               :disabled="purchaseGenerated"
                               required/>
                        <!-- City -->
                        <label>Cidade / Localidade (obrigatório)</label>
                        <input v-model="city"
                               type="text"
                               name="city"
                               class="simple-field width"
                               maxlength="70"
                               :disabled="purchaseGenerated"
                               required/>
                        <!-- postalCode -->
                        <label>Código Postal (obrigatório)</label>
                        <input v-model="postalCode"
                               type="text"
                               name="postal-code"
                               placeholder="xxxx-xxx"
                               class="simple-field width"
                               maxlength="8"
                               minlength="8"
                               @input="healPostalCode()"
                               :disabled="purchaseGenerated"
                               required/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { healPostalCode } from '../../utils/formHealers'
    import GETTERS from '../../store/types-getters'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class CheckoutAddressDelivery extends Vue {
        isOpen: boolean = true

        get purchaseGenerated () {
            return this.$store.state.purchase.generated
        }

        get countries () {
            return this.$store.state.countries.countries
        }

        get name() {
            return this.$store.state.purchase.addressDelivery.name
        }
        set name(value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressDelivery', key: 'name', value })
        }

        get country () {
            return this.$store.state.purchase.addressDelivery.country
        }
        set country (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressDelivery', key: 'country', value })
        }

        get street () {
            return this.$store.state.purchase.addressDelivery.street
        }
        set street (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressDelivery', key: 'street', value })
        }

        get city () {
            return this.$store.state.purchase.addressDelivery.city
        }
        set city (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressDelivery', key: 'city', value })
        }

        healPostalCode () {
            this.postalCode = healPostalCode(this.postalCode)
        }
        get postalCode () {
            return this.$store.state.purchase.addressDelivery.postalCode
        }
        set postalCode (value) {
            this.$store.commit(MUTATIONS.PURCHASE_DATA_CHANGE, { segment: 'addressDelivery', key: 'postalCode', value })
        }

        get userIsLogged () {
            return this.$store.getters[GETTERS.USER_IS_LOGGED]
        }
        get userDeliveryAddress () {
            return this.$store.state.user.address.delivery
        }
        loadData () {
            if (!this.userIsLogged) return
            if (!this.userDeliveryAddress) return

            this.name = this.userDeliveryAddress.name
            this.country = this.userDeliveryAddress.country
            this.street = this.userDeliveryAddress.street
            this.city = this.userDeliveryAddress.city
            this.postalCode = this.userDeliveryAddress.postalCode
        }
        @Watch('userIsLogged', { immediate: true })
        onUserLoggedChanged () { this.loadData() }
        @Watch('userDeliveryAddress', { deep: true })
        onUserDeliveryAddressChanged () { this.loadData() }
    }
</script>
<style scoped lang="scss"></style>
