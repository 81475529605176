<template>
    <div class="col-xs-12">
        <div class="article-container style-1">
            <div class="message-box message-warning" v-if="!forInvoice">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    Estes dados são usados como morada de entrega das suas compras.<br/>
                    Quando efetuar uma compra pode escolher uma morada diferente válida apenas para essa encomenda.
                </div>
            </div>
            <div v-if="formError.active" class="message-box message-danger">
                <div class="message-icon"><i class="fa fa-times"></i></div>
                <div class="message-text">{{ formError.message }}</div>
                <div class="message-close" @click="formError.active = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div v-if="formSuccess.active" class="message-box message-success">
                <div class="message-icon"><i class="fa fa-check"></i></div>
                <div class="message-text">{{ formSuccess.message }}</div>
                <div class="message-close" @click="formSuccess.active = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-3 col-sm-6">
                    <form @submit.prevent="updateAddress()">
                        <label>{{ forInvoice ? 'Nome' : 'Nome do destinatario' }}</label>
                        <input v-model="address.name"
                               type="text"
                               name="name"
                               class="simple-field width"
                               maxlength="70"
                               @focus="hideMessages()"
                               required/>
                        <template v-if="forInvoice">
                            <label>Número de Identificação Fiscal (NIF)</label>
                            <input v-model="address.nif"
                                   type="text"
                                   name="nif"
                                   class="simple-field width"
                                   maxlength="9"
                                   minlength="9"
                                   @input="healNIF()"
                                   @focus="hideMessages()"
                                   required/>
                        </template>
                        <!-- Country -->
                        <label>Pais</label>
                        <template v-if="forInvoice">
                            <input v-model="address.country"
                                type="text"
                                name="country"
                                class="simple-field width"
                                maxlength="50"
                                @focus="hideMessages()"
                                required/>
                        </template>
                        <template v-else>
                            <select v-model="address.country"
                                    name="country"
                                    class="simple-field width"
                                    @focus="hideMessages()"
                                    required>
                                <option v-for="country in countries"
                                        :key="country.name"
                                        :value="country.name">{{ country.name }}</option>
                            </select>
                        </template>
                        
                        <!-- Street -->
                        <label>Morada</label>
                        <input v-model="address.street"
                               type="text"
                               name="address"
                               placeholder="Avenida, Praceta, Rua..."
                               class="simple-field width"
                               maxlength="300"
                               @focus="hideMessages()"
                               required/>
                        <!-- City -->
                        <label>Cidade / Localidade</label>
                        <input v-model="address.city"
                               type="text"
                               name="city"
                               class="simple-field width"
                               maxlength="70"
                               @focus="hideMessages()"
                               required/>
                        <!-- postalCode -->
                        <label>Código Postal</label>
                        <input v-model="address.postalCode"
                               type="text"
                               name="postal-code"
                               placeholder="xxxx-xxx"
                               class="simple-field width"
                               maxlength="8"
                               minlength="8"
                               @input="healPostalCode()"
                               @focus="hideMessages()"
                               required/>
                        <div class="button style-10">
                            Guardar<input type="submit" value="">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Address } from '../../utils/Interfaces/Address'
import { healNIF, healPostalCode } from '../../utils/formHealers'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormsUserAddress extends Vue {
    @Prop() forInvoice!: boolean

    get countries () {
        return this.$store.state.countries.countries
    }

    formError = {
        active: false,
        message: ''
    }

    formSuccess = {
        active: false,
        message: ''
    }

    hideMessages () {
        this.formError.active = false
        this.formSuccess.active = false
    }

    address: Address = {
        id: '',
        clientId: '',
        forInvoice: false,
        name: '',
        nif: '',
        country: '',
        street: '',
        city: '',
        postalCode: ''
    }

    healPostalCode () {
        this.address.postalCode = healPostalCode(this.address.postalCode)
    }

    healNIF (): void {
        this.address.nif = healNIF(this.address.nif)
    }

    updateAddress () {
        this.$store.dispatch(ACTIONS.USER_ADDRESS_UPDATE_ADDRESS, this.address).then(() => {
            this.formError.active = false
            this.formSuccess.active = true
            this.formSuccess.message = 'Dados alterados com sucesso.'
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }

    // Gets the object saved on store
    get storeAddress () {
        if (this.forInvoice) return this.$store.state.user.address.invoice
        return this.$store.state.user.address.delivery
    }
    // On store change, update the local data
    @Watch('storeAddress', { deep: true })
    onStoreAddressChange () {
        this.cloneAddress()
    }
    // Clone the store object to the local data
    cloneAddress () {
        this.address.forInvoice = this.forInvoice
        if (!this.storeAddress) return
        this.address.id = this.storeAddress.id
        this.address.clientId= this.storeAddress.clientId
        this.address.name = this.storeAddress.name
        this.address.nif = this.storeAddress.nif
        this.address.country = this.storeAddress.country
        this.address.street = this.storeAddress.street
        this.address.city = this.storeAddress.city
        this.address.postalCode = this.storeAddress.postalCode
    }
    // When mounted clone the data
    mounted () {
        this.cloneAddress()
    }
}
</script>
<style scoped lang="scss"></style>
