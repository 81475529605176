<template>
    <div class="information-blocks">
        <div class="tabs-container">
            <div class="swiper-tabs tabs-switch">
                <div class="title">Produtos</div>
                <div class="list">
                    <a class="block-title tab-switcher"
                       :class="{'active': activeTab === 'featured' }"
                       @click="activateTab('featured')">Destaques</a>
                    <a class="block-title tab-switcher"
                       :class="{'active': activeTab === 'mostViewed' }"
                       @click="activateTab('mostViewed')">Mais vistos</a>
                    <a class="block-title tab-switcher"
                       :class="{'active': activeTab === 'mostRecent' }"
                       @click="activateTab('mostRecent')">Recentes</a>
                    <div class="clear"></div>
                </div>
            </div>
            <div>
                <div class="tabs-entry">
                    <div class="products-swiper">
                        <swiper :options="swiperOptions" ref="mySwiper">
                            <swiper-slide v-for="product in featured[activeTab]"
                                          :key="product.id">
                                <product-card :product="product"/>
                            </swiper-slide>
                            <div class="pagination"></div>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import ProductCard from '../product/card.vue'

@Component({
    components: {
        ProductCard
    }
})
export default class ContentFeaturedProducts extends Vue {
    activeTab = ''
    swiperOptions = {
        slidesPerView: 5,
        breakpoints: {
            400: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            600: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            800: {
                slidesPerView: 4,
                spaceBetween: 30
            }
        }
    }

    activateTab (tab: string): void {
        this.activeTab = tab
    }

    get featured () {
        return this.$store.state.publishedProducts.featured
    }

    async mounted () {
        await this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_GET_FEATURED).finally(() => {
            this.activateTab('featured')
        })
    }
}
</script>
<style scoped lang="scss"></style>
