<template>
    <div class="header-wrapper style-15">
        <header class="type-4" :class="{'opened': isOpen }">
            <header-user-bar @toggleMenu="toggleMenu()"/>
            <header-logo-and-links />
            <div class="close-header-layer" v-show="isOpen" @click="toggleMenu()"></div>
            <header-navigation @toggleMenu="toggleMenu()"/>
        </header>
        <div class="clear"></div>
    </div>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator'
import headerUserBar from './headerUserBar.vue'
import headerLogoAndLinks from './headerLogoAndLinks.vue'
import headerNavigation from './headerNavigation.vue'

@Component({
    components: {
        headerUserBar,
        headerLogoAndLinks,
        headerNavigation
    }
})
export default class UserBar extends Vue {
    isOpen: boolean = false

    get currentRoute () {
        return this.$route.name
    }
    @Watch('currentRoute')
    onRouteChange () {
        this.isOpen = false
    }

    toggleMenu () {
        this.isOpen = !this.isOpen
    }
}
</script>
<style scoped lang="scss"></style>
