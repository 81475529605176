<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'recoverSignin' }">Recuperar password</router-link>
        </div>
        <div class="information-blocks">
            <div class="row">
                <form-user-login-recover />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import FormUserLoginRecover from '../forms/userLoginRecover.vue'

@Component({
    components: {
        FormUserLoginRecover
    }
})
export default class PageSign extends Vue {
    @Watch('$store.state.user.logged')
    onUserLoggedChanged (newLogged: boolean) {
        if (!newLogged) return
        this.$router.replace({ name: 'account'})
    }

    mounted () {
        if (!this.$store.state.user.logged) return
        this.$router.replace({ name: 'account'})
    }
}
</script>
<style scoped lang="scss"></style>
