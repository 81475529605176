import HTTP from '../utils/HTTP'
import API from '../utils/API'

// API access to both public and admin restricted routes. Can't set up a base API_ROUTE
export const API_ROUTE = 'public/persistent'

class PersistentApi extends API {
    // Public access
    get () {
        return HTTP.api().get(`${API_ROUTE}/`)
    }
}

export default new PersistentApi(API_ROUTE)
