<template>
    <div class="col-xs-12">
        <div class="article-container style-1">
            <h3>Eliminar a minha conta</h3>
            <div class="message-box message-warning">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    <b>Atenção!</b>
                    Esta acção é definitiva e irreverssível.
                    Por motivos de segurança os dados da conta serão apagados e não é possível recuperar o acesso a esta conta e a todos os dados a ela associados.
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-3 col-sm-6">
                    <div v-if="formError.active" class="message-box message-danger">
                        <div class="message-icon"><i class="fa fa-times"></i></div>
                        <div class="message-text">{{ formError.message }}</div>
                    </div>
                    <form @submit.prevent="deleteAccount()">
                        <label>Introduza a sua password e carregue em eliminar conta</label>
                        <input v-model="password"
                               type="password"
                               placeholder="Password"
                               class="simple-field width"
                               required/>
                        <div class="button style-12">
                            ELIMINAR CONTA<input type="submit" value="">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormUserLogin extends Vue {
    password: string = ''
    formError = {
        active: false,
        message: ''
    }

    deleteAccount () {
        this.$store.dispatch(ACTIONS.USER_DELETE_ACCOUNT, this.password).then(() => {
            this.$store.dispatch(ACTIONS.USER_LOGOUT)
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }
}
</script>