<template>
    <div class="col-sm-9 information-entry">
        <h3 class="cart-column-title size-1">Produtos</h3>
        <div v-for="product in products"
             v-if="cart[product.id]"
             :key="product.id"
             class="traditional-cart-entry style-1">
            <router-link class="image"
                         :to="{ name: 'product', params: { id: product.id }}">
                <img alt="" :src="firstImage(product)">
            </router-link>
            <div class="content">
                <div class="cell-view">
                    <p class="tag">{{ product.merged.mainCategory }}</p>
                    <router-link class="title"
                       :to="{ name: 'product', params: { id: product.id }}">
                        {{ product.ref }}
                    </router-link>
                    <div class="price">
                        <template v-if="product.price > getProductEffectivePrice(product)">
                            Preço: 
                            <div class="prev" style="margin-right: 10px">{{ product.price.toFixed(2) }} €</div>
                            <div class="current">{{ getProductEffectivePrice(product).toFixed(2) }} €</div>
                        </template>
                        <template v-else>
                            <div class="current">Preço: {{ product.price.toFixed(2) }} €</div>
                        </template>
                    </div>
                    <div v-for="(quantity, size) in cart[product.id].quantities"
                         :key="`cart_${product.id}_${size}`"
                         class="quantity-selector detail-info-entry">
                        <div class="detail-info-entry-title --inline">Tamanho {{ size }}</div>
                        <div class="entry number-minus"
                             @click="decrementQuantity(product, size)">&nbsp;</div>
                        <div class="entry number">{{ quantity }}</div>
                        <div class="entry number-plus"
                             @click="incrementQuantity(product, size)">&nbsp;</div>
                        <a class="button style-17"
                           @click="removeProductSize(product, size)">Remover tamanho {{ size }}</a>
                    </div>
                    <div class="quantity-selector detail-info-entry">
                        <a class="button style-17"
                           @click="removeProduct(product)">Remover todos os tamanhos</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import ACTIONS from '../../store/types-actions'
    import { CartProduct } from '../../utils/Interfaces/Purchase'
    import { ConstructedProduct } from '../../utils/Interfaces/Products'
    import CartFunctions from '../../utils/Constructers/CartFunctions'
    import getImageUrl from '../../utils/ImageURL'

    @Component({})
    export default class ContentCartProducts extends Vue {
        @Prop({}) cart!: { [index: string]: CartProduct}
        @Prop({}) products!: { [index: string]: ConstructedProduct}

        // Product effective price
        getProductEffectivePrice (product: ConstructedProduct) {
            return CartFunctions.processPrice(product)
        }
        // Product image processing
        getProductImageURL (file: string): string {
            return getImageUrl({ filePath: file })
        }
        firstImage (product: ConstructedProduct): string {
            // No images
            if (!product.merged.pictures[0]) {
                return this.getProductImageURL('products/product-no-image.jpg')
            }
            // At least one image
            const thumbnailIndex = product.merged.fixedThumbnailIndex
            const productPictures = product.merged.pictures
            return this.getProductImageURL(productPictures[thumbnailIndex])
        }

        // Change product/size quantity
        incrementQuantity (product: ConstructedProduct, size: number): void {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_ADD_PRODUCT, {
                productId: product.id,
                size
            })
        }
        decrementQuantity (product: ConstructedProduct, size: number): void {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_DECREMENT_PRODUCT, {
                productId: product.id,
                size
            })
        }

        // Remove size and product
        removeProductSize (product: ConstructedProduct, size: number): void {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_REMOVE_PRODUCT_SIZE, {
                productId: product.id,
                size
            })
        }
        removeProduct (product: ConstructedProduct): void {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_REMOVE_PRODUCT, {
                productId: product.id
            })
        }

    }
</script>
<style scoped lang="scss"></style>
