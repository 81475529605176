<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'terms' }">Política de Privacidade</router-link>
        </div>
        <div class="information-blocks">
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <div class="blog-landing-box type-1 detail-post">
                        <div class="blog-entry">
                            <div class="content">
                                <div class="article-container style-1">
                                    <h1 class="title">Política de Privacidade</h1>
                                    <p>A Jandaia está empenhada em proteger a privacidade dos Clientes e Utilizadores do seu site, e apenas recolhe as informações pessoais que nos forem fornecidas voluntariamente e as absolutamente necessárias para os fins a que se destinam.</p>
                                    <p>O tratamento dos dados pessoais é realizado no estrito cumprimento da legislação de protecção de dados pessoais em vigor.</p>
                                    <p>Ao utilizar o site <a href="https://www.jandaia.pt">https://www.jandaia.pt</a> , estará a consentir com os seguintes termos</p>

                                    <ul>
                                        <li>
                                            <b>Identificação do responsável pelo tratamento dos dados</b><br />
                                            Sapataria Jandaia Lda, com sede na {{ settings.contact_address }}, NIPC <span class="mono-font">{{ settings.contact_nif }}</span>, contacto <a :href="`tel:${settings.contact_phone}`" class="mono-font" target="_blank">{{ settings.contact_phone }}</a>, ou através de email para <a :href="`mailto:${settings.contact_email}`" target="_blank">{{ settings.contact_email }}</a>
                                        </li>
                                        <li>
                                            <b>Dados pessoais</b><br />
                                            Conjunto de elementos de identificação absolutamente necessários ao procedimento da encomenda e troca comercial, e que se tornam obrigatórios para</br>
                                                <span style="display: block; margin-left: 20px;">Emitir factura de aquisição</span>
                                                <span style="display: block; margin-left: 20px;">Proceder ao envio para o endereço indicado</span>
                                                <span style="display: block; margin-left: 20px;">Validar garantias e apoio pós venda</span>
                                                <span style="display: block; margin-left: 20px;">Proceder a contacto para serviço de apoio ao cliente</span>
                                        </li>
                                        <li>
                                            <b>Finalidade e conservação da informação pessoal</b><br />
                                            <p>Mediante a aceitação da Política de Privacidade o Cliente/ Utilizador presta o seu consentimento informado, expresso, livre e inequívoco para que os seus dados pessoais fornecidos através do site www.jandaia,pt sejam incluídos num ficheiro da responsabilidade da Jandaia, em conformidade com o RGPD, e na medida do absolutamente necessário para a finalidade pretendida.</p>
                                            <p>O acesso à informação (armazenada no nosso software de gestão) por parte dos colaboradores da Jandaia, só é possível com a inserção de password.</p>
                                            <p>Os dados pessoais são tratados com o grau de protecção legalmente exigível para garantir a segurança dos mesmos e evitar a sua alteração, perda, tratamento ou acesso não autorizado.</p>
                                            <p>A Jandaia não cede, vende ou aluga qualquer dado pessoal enviado pelos Clientes/Utilizadores do nosso site.</p>
                                            <p>O período de tempo durante o qual os dados são guardados varia de acordo com a finalidade para a qual a informação é utilizada. Existem requisitos legais que obrigam a conservar dados por um período determinado de tempo.</p>
                                            <p>Caso não exista uma exigência legal especifica, os dados serão conservados pelo período estritamente necessário à finalidade para que foram recolhidos, até indicação em contrário do Cliente/Utilizador.</p>
                                            <p>Quando os seus dados pessoais já não forem necessários, os registos serão removidos do nosso sistema ou mantido de forma anónima, sem possibilidade de identificação a partir dos mesmos.</p>
                                            <p>A Jandaia garante as condições para o Cliente/Utilizador exercer o direito do acesso, rectificação, apagamento, portabilidade ou limitação do tratamento que o RGPD lhe concede, através da área de cliente ou comunicação para <a :href="`mailto:${settings.contact_email}`" target="_blank">{{ settings.contact_email }}</a></p>
                                        </li>
                                        <li>
                                            <b>Cookies</b><br />
                                            <p>A Jandaia poderá colocar periodicamente informações no seu computador de modo a poder identificar a utilização efectuada pelos Clientes/Utilizadores</p>
                                            <p>Estas informações são normalmente designadas por cookies e servem para que o computador memorize determinadas informações referentes ao site, para facilitar o acesso e carregamento dos mesmos quando o Cliente/Utilizador os visita.</p>
                                            <p>A Jandaia pode melhorar o seu site com base nestas informações, sendo sempre possível ao Cliente/Utilizador bloquear a respectiva entrada no seu sistema.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PagePrivacy extends Vue {
    get settings () {
        return this.$store.state.settings
    }
}
</script>
<style scoped lang="scss"></style>
