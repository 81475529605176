<template>
    <footer class="type-2">
        <div class="position-center">
            <div class="row" style="margin-bottom: 20px">
                <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
                    <div class="row">
                        <div class="col-xs-4">
                            <img class="img-responsive" src="../../assets/img/endorsements/apoio1.png"/>
                        </div>
                        <div class="col-xs-4">
                            <img class="img-responsive" src="../../assets/img/endorsements/apoio2.png"/>
                        </div>
                        <div class="col-xs-4">
                            <img class="img-responsive" src="../../assets/img/endorsements/apoio3.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-links">
                <router-link :to="{ name: 'products' }" href="#">Sapatos</router-link>
                <router-link :to="{ name: 'home' }" href="#">Acessórios</router-link>
                <router-link :to="{ name: 'stores' }" href="#">Lojas</router-link>
                <router-link :to="{ name: 'customize' }" href="#">À Medida</router-link>
                <router-link :to="{ name: 'contacts' }" href="#">Contactos</router-link>
            </div>
            <div class="footer-links">
                <router-link :to="{ name: 'about' }" href="#">Quem somos</router-link>
                <router-link :to="{ name: 'terms' }" href="#">Termos e condições</router-link>
                <router-link :to="{ name: 'privacy' }" href="#">Política de privacidade</router-link>
                <a href="https://www.livroreclamacoes.pt/Pedido/Reclamacao" target="_blank" rel="noopener noreferrer">Livro de reclamações eletrónico</a>
            </div>
            <div class="copyright">Criado por <a href="http://www.jfcodes.com" target="_blank">JFCodes</a>. Todos os direitos reservados</div>
        </div>
    </footer>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class OtherFooter extends Vue {}
</script>
<style scoped lang="scss"></style>
