<template>
    <div class="navigation">
        <div class="navigation-header responsive-menu-toggle-class">
            <div class="title">Menu</div>
            <div class="close-menu" @click="$emit('toggleMenu')"></div>
        </div>
        <div class="nav-overflow">
            <nav>
                <ul>
                    <li class="column-2"
                        @mouseenter="showSubmenu($event.target)"
                        @mouseleave="hideMenu($event.target)">
                        <router-link :to="{ name: 'products', query: { forShoe: 1 }}">Sapatos</router-link>
                        <i class="fa fa-chevron-down"></i>
                        <div class="submenu">
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isWomen: 1, forShoe: 1 }}">Mulher</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li v-for="category in womemShoeCategories"
                                            :key="category.id">
                                            <router-link :to="{ name: 'products', query: { category: category.id }}">
                                                <i class="fa fa-angle-right"></i>{{ category.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isMen: 1, forShoe: 1 }}">Homem</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li v-for="category in menShoeCategories"
                                            :key="category.id">
                                            <router-link :to="{ name: 'products', query: { category: category.id }}">
                                                <i class="fa fa-angle-right"></i>{{ category.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="column-2"
                        @mouseenter="showSubmenu($event.target)"
                        @mouseleave="hideMenu($event.target)">
                        <router-link :to="{ name: 'products', query: { forAccessories: 1 }}">Acessórios</router-link>
                        <i class="fa fa-chevron-down"></i>
                        <div class="submenu">
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isWomen: 1, forAccessories: 1 }}">Mulher</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li v-for="category in womemAccessoriesCategories"
                                            :key="category.id">
                                            <router-link :to="{ name: 'products', query: { category: category.id }}">
                                                <i class="fa fa-angle-right"></i>{{ category.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isMen: 1, forAccessories: 1 }}">Homem</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li v-for="category in menAccessoriesCategories"
                                            :key="category.id">
                                            <router-link :to="{ name: 'products', query: { category: category.id }}">
                                                <i class="fa fa-angle-right"></i>{{ category.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="column-2"
                        @mouseenter="showSubmenu($event.target)"
                        @mouseleave="hideMenu($event.target)">
                        <router-link :to="{ name: 'products', query: { newP: 1 }}">Nova Coleção</router-link>
                        <i class="fa fa-chevron-down"></i>
                        <div class="submenu">
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isWomen: 1, newP: 1 }}">Mulher</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li>
                                            <router-link :to="{ name: 'products', query: { isWomen: 1, newP: 1, forShoe: 1 }}">
                                                <i class="fa fa-angle-right"></i>Sapatos
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ name: 'products', query: { isWomen: 1, newP: 1, forAccessories: 1 }}">
                                                <i class="fa fa-angle-right"></i>Accessórios
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="product-column-entry">
                                <div class="submenu-list-title">
                                    <router-link :to="{ name: 'products', query: { isMen: 1, newP: 1 }}">Homem</router-link>
                                    <span class="toggle-list-button"></span>
                                </div>
                                <div class="description toggle-list-container">
                                    <ul class="list-type-1">
                                        <li>
                                            <router-link :to="{ name: 'products', query: { isMen: 1, newP: 1, forShoe: 1 }}">
                                                <i class="fa fa-angle-right"></i>Sapatos
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ name: 'products', query: { isMen: 1, newP: 1, forAccessories: 1 }}">
                                                <i class="fa fa-angle-right"></i>Accessórios
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li><router-link :to="{ name: 'stores' }">Lojas</router-link></li>
                    <li><router-link :to="{ name: 'customize' }">À medida</router-link></li>
                    <li><router-link :to="{ name: 'contacts' }">Contactos</router-link></li>
                    <li class="fixed-header-visible">
                        <a class="fixed-header-square-button open-cart-popup"><i class="fa fa-shopping-cart"></i></a>
                        <a class="fixed-header-square-button open-search-popup"><i class="fa fa-search"></i></a>
                    </li>
                </ul>
                <div class="clear"></div>
            </nav>
            <div class="navigation-footer responsive-menu-toggle-class">
                <div class="socials-box">
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                    <a href="#"><i class="fa fa-instagram"></i></a>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import GETTERS from '../../store/types-getters'

const $ = require("jquery")

@Component({})
export default class HeaderNavigation extends Vue {

    get womemShoeCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_WOMAN_SHOES]
    }

    get menShoeCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_MAN_SHOES]
    }

    get womemAccessoriesCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_WOMAN_ACCESSORIES]
    }

    get menAccessoriesCategories () {
        return this.$store.getters[GETTERS.CATEGORY_GET_MAN_ACCESSORIES]
    }

    showSubmenu (target: Element) {
        // @ts-ignore
        $(target).find('.submenu').stop().fadeIn(300);
    }
    hideMenu (target: Element) {
        $(target).find('.submenu').stop().fadeOut(300);
    }
}
</script>
<style scoped lang="scss"></style>
