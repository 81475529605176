<template>
    <div id="content-block">
        <div class="content-center fixed-header-margin">
            <router-view name="header"></router-view>
            <keep-alive include="PageProducts">
                <router-view :cart="cart"
                             :products="products"
                             :cartTotalPrice="cartTotalPrice"/>
            </keep-alive>
        </div>
        <other-footer />
        <content-cart-popup :cart="cart"
                            :products="products"
                            :cartTotalPrice="cartTotalPrice"/>
        <modal-error />
        <modal-message />
        <modal-size-picker />
        <modal-size-picker-success />
        <modal-purchase-success />

        <div id="loader-wrapper" v-if="AppIsLoading">
            <div class="bubbles">
                <div class="title">Carregando...</div>
                <span></span>
                <span id="bubble2"></span>
                <span id="bubble3"></span>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
// Utilities
import { CartProduct } from './utils/Interfaces/Purchase'
import { AuxData } from './utils/Interfaces/_misc'
import { PublishedProduct, ConstructedProduct } from './utils/Interfaces/Products'
import CartFunctions, { CartTotalPrice } from './utils/Constructers/CartFunctions'
import constructProduct from './utils/Constructers/Product'
// Components
import OtherFooter from './components/other/footer.vue'
import ModalError from './components/modals/error.vue'
import ModalMessage from './components/modals/message.vue'
import ModalSizePicker from './components/modals/sizePicker.vue'
import ModalSizePickerSuccess from './components/modals/sizePickerSuccess.vue'
import ModalPurchaseSuccess from './components/modals/purchaseSuccess.vue'
import ContentCartPopup from './components/content/cartPopup.vue'

@Component({
    components: {
        OtherFooter,
        ContentCartPopup,
        ModalError,
        ModalMessage,
        ModalSizePicker,
        ModalSizePickerSuccess,
        ModalPurchaseSuccess
    }
})
export default class App extends Vue {
    /** Cart info - start */
    get auxData (): AuxData {
        return {
            colors: this.$store.state.colors.colorsObject,
            brands: this.$store.state.brands.brandsObject,
            categories: this.$store.state.categories.categoryTree,
            tags: this.$store.state.tags.tagsObject
        }
    }
    get products (): { [index: string]: ConstructedProduct} {
        const products = Object.values(this.$store.state.purchase.products)
        // @ts-ignore
        return products.reduce((acc: { [index: string]: ConstructedProduct}, product: PublishedProduct) => {
            acc[product.id] = constructProduct(product, this.auxData)
            return acc
        }, {})
    }
    get cart (): { [index: string]: CartProduct} {
        return this.$store.state.purchase.cart
    }
    get deliveryCountry (): string {
        return this.$store.state.purchase.addressDelivery.country
    }
    get cartTotalPrice (): CartTotalPrice {
        return CartFunctions.getCartTotalPrice({
            cart: this.cart,
            products: this.products,
            country: this.deliveryCountry
        })
    }
    /** Cart info - end */

    get AppIsLoading () {
        return this.$store.state.appLoading
    }
}
</script>
<style src="./style/load.scss" lang="scss"></style>