class LocalStorage {
    available: boolean

    constructor () {
        this.available = localStorage !== undefined
    }

    public saveItem (name: string, value: any): void {
        if (!this.available) console.warn('Local storage is not available on this browser.')
        try {
            let valueString = JSON.stringify(value)
            localStorage.setItem(name, valueString)
        } catch (e) {
            // do nothing
        }
    }

    public getItem (name: string): any {
        if (!this.available) {
            console.warn('Local storage is not available on this browser.')
            return null
        }

        const fromStorage = localStorage.getItem(name)
        if (fromStorage === null) return null
        try {
            return JSON.parse(fromStorage)
        } catch (e) {
            return null
        }
    }
}

export default new LocalStorage()
