<template>
    <div class="overlay-popup"
         :class="{ 'visible active': sizePicker.active }"
         @keypress.esc="close()">
        <div class="overflow">
            <div class="table-view">
                <div class="cell-view">
                    <div class="close-layer"></div>
                    <div class="popup-container">
                        <div class="close-popup" @click="close()"></div>
                        <template v-if="noStock">
                            <div class="newsletter-title">Artigo indisponível online</div>
                            <div class="newsletter-text">
                                <br />
                                Pedimos desculpa mas o artigo está indisponível para venda online.<br/>
                                Consulte os tamanhos e disponibilidade em loja na <router-link :to="{name: 'product', params: { id: productId }}">página do produco</router-link>.
                            </div>
                        </template>
                        <template v-else>
                            <div class="newsletter-title">Selecione o tamanho</div>
                            <div class="size-selector detail-info-entry">
                                <div class="detail-info-entry-title">
                                    <br/>
                                    Tamanhos disponíveis na loja online:
                                </div>
                                <div v-for="stock in orderedOnlineStock"
                                     :key="stock.size"
                                     class="entry"
                                     @click="addProduct(stock.size)">{{ stock.size }}</div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import MUTATIONS from '../../store/types-mutations'
    import ACTIONS from '../../store/types-actions'
    import { ApiResponse } from "../../utils/Interfaces/ApiResponse"
    import orderBy from 'lodash/orderBy'

    // TODO: change this to the store that will supply the online purchases
    const TARGET_STORE = 'online'

    @Component({})
    export default class ModalSizePicker extends Vue {
        productStock: null | any = null

        get sizePicker () {
            return this.$store.state.modals.sizePicker
        }

        get productId () {
            return this.sizePicker.productId
        }

        get onlineStock () {
            if (this.productStock === null) return {}
            if (!this.productStock[TARGET_STORE]) return {}
            return this.productStock[TARGET_STORE]
        }

        get orderedOnlineStock () {
            const stockArray = Object.keys(this.onlineStock).map(size => {
                return { size, quantity: this.onlineStock[size] }
            })
            return orderBy(stockArray, ['size'], ['asc'])
        }

        get noStock () {
            return Object.values(this.onlineStock).length === 0
        }

        @Watch('$route', { deep: true })
        onRouteChange () {
            this.close()
        }

        @Watch('productId')
        onProductIdChange (newProductId: string): void {
            if (newProductId === '') return
            this.loadProductStock()
        }

        async loadProductStock (): Promise<void> {
            if (!this.productId) return
            let response: ApiResponse = await this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_GET_PRODUCT_STOCK, { productId: this.productId })
            if (response.valid) {
                this.productStock = response.data
            }
        }

        addProduct (size: number): void {
            this.$store.dispatch(ACTIONS.PURCHASE_CART_ADD_PRODUCT, {
                productId: this.productId,
                size
            }).then(() => {
                this.$store.commit(MUTATIONS.APP_SIZE_PICKER_SUCCESS_SHOW)
            }).finally(() => {
                this.close()
            })
        }

        close () {
            this.productStock = null
            this.$store.commit(MUTATIONS.APP_SIZE_PICKER_HIDE)
        }
    }
</script>