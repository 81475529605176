<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'home' }">Jandaia</router-link>
            <router-link :to="{ name: 'stores' }">Lojas</router-link>
        </div>
        <div class="information-blocks">
            <div class="row">
                <content-store-card v-for="store in stores"
                                    :key="store.id"
                                    :store="store"/>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Store } from '../../utils/Interfaces/Stores'
import ContentStoreCard from '../content/storeCard.vue'

@Component({
    components: {
        ContentStoreCard
    }
})
export default class PageStores extends Vue {
    get stores () {
        let stores = this.$store.state.stores.storesObject
        // @ts-ignore
        return Object.values(stores).sort((sA: Store, sB: Store) => sA.ordering - sB.ordering )
    }
}
</script>
<style scoped lang="scss"></style>
