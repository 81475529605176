// Import the correspondent API object
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
import GETTERS from '../types-getters'
// Store specific imports
import purchaseAPI from '../../api/purchase'
import { DeliveryAddress, InvoiceAddress } from '../../utils/Interfaces/Address'
import {
    CartProduct,
    PurchaseContacts,
    PurchaseFull
} from '../../utils/Interfaces/Purchase'
import { PublishedProduct, ProducePrice } from '../../utils/Interfaces/Products'
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import LocalStorage from '../../utils/storate'

interface DefaultState {
    loading: boolean,
    generated: boolean,
    settings: {
        STORAGE_ITEM_NAME: string
        MAX_PRODUCT_QUANTITY: number
    }
    cart: { [index: string]: CartProduct }
    products: { [index: string]: PublishedProduct }
    contacts: PurchaseContacts
    addressDelivery: DeliveryAddress
    addressInvoice: InvoiceAddress
    notesClient: string
}

const defaultState: DefaultState = {
    loading: false,
    generated: false,
    settings: {
        STORAGE_ITEM_NAME: 'cart',
        MAX_PRODUCT_QUANTITY: 10
    },
    cart: {},
    products: {},
    contacts: {
        email: '',
        phone: ''
    },
    addressDelivery: {
        name: '',
        forInvoice: false,
        country: '',
        street: '',
        city: '',
        postalCode: ''
    },
    addressInvoice: {
        forInvoice: true,
        name: '',
        nif: '',
        country: '',
        street: '',
        city: '',
        postalCode: ''
    },
    notesClient: ''
}

let state = cloneDeep(defaultState)

const getters = {
    [GETTERS.PURCHASE_CART_COUNT]: (state: DefaultState) => {
        return Object.keys(state.cart).length
    }
}

// TODO: only trigger USER_CART_UPDATE_CART if the cart has changed in each action
/**
 * NOTES:
 *
 * - Actions that add new products must trigger PURCHASE_CART_LOAD_PRODUCTS which will commit PURCHASE_CART_SAVE_TO_STORAGE
 * - Actions that do not add new products (decrement size, remove size or remove product) do not need to load
 * PURCHASE_CART_LOAD_PRODUCTS but need to commit PURCHASE_CART_SAVE_TO_STORAGE
 */

const actions = {
    [ACTIONS.PURCHASE_CART_LOAD] ({ commit, dispatch }: { commit: any, dispatch: any }, { cartString }: { cartString: string | null}) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        let cart: { [index: string]: CartProduct }
        try {
            cart = JSON.parse(cartString || '{}')
        } catch (e) {
            cart = {}
        }
        commit(MUTATIONS.PURCHASE_CART_LOAD, { cart })
        dispatch(ACTIONS.PURCHASE_CART_LOAD_PRODUCTS)
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    async [ACTIONS.PURCHASE_CART_LOAD_PRODUCTS] ({ commit, state }: { commit: any, state: DefaultState }) {
        await purchaseAPI.getProducts(state.cart).then((response: any) => {
            const productsArray = response.data.data
            const productsObject: { [index: string]: PublishedProduct } = {}
            productsArray.forEach((product: PublishedProduct) => {
                productsObject[product.id] = product
            })
            // 1. Save the product
            // 2. Remove product from the cart that weren't retrieve by the api
            // 3. Save to storage
            commit(MUTATIONS.PURCHASE_CART_LOAD_PRODUCTS, { products: productsObject })
            commit(MUTATIONS.PURCHASE_CART_HEAL_PRODUCTS, { products: productsObject })
            commit(MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE)
        })
    },
    [ACTIONS.PURCHASE_CART_RECOVER_FROM_STORAGE] ({ commit, dispatch }: { commit: any, dispatch: any }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.PURCHASE_CART_RECOVER_FROM_STORAGE)
        dispatch(ACTIONS.PURCHASE_CART_LOAD_PRODUCTS)
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    [ACTIONS.PURCHASE_CART_ADD_PRODUCT] ({ commit, dispatch, state }: { commit: any, dispatch: any, state: DefaultState }, { productId, size }: { productId: string, size: number }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.PURCHASE_CART_INCREMENT_PRODUCT, { productId, size })


        // Only load products if productId is new
        const productAlreadyLoaded = state.products[productId]
        if (!productAlreadyLoaded) dispatch(ACTIONS.PURCHASE_CART_LOAD_PRODUCTS)

        dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: state.cart })
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    [ACTIONS.PURCHASE_CART_DECREMENT_PRODUCT] ({ commit, dispatch, state }: { commit: any, dispatch: any, state: DefaultState }, { productId, size }: { productId: string, size: number }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.PURCHASE_CART_DECREMENT_PRODUCT, { productId, size })
        commit(MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE)
        dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: state.cart })
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    [ACTIONS.PURCHASE_CART_REMOVE_PRODUCT] ({ commit, dispatch, state }: { commit: any, dispatch: any, state: DefaultState }, { productId }: { productId: string }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.PURCHASE_CART_REMOVE_PRODUCT, { productId })
        commit(MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE)
        dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: state.cart })
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    [ACTIONS.PURCHASE_CART_REMOVE_PRODUCT_SIZE] ({ commit, dispatch, state }: { commit: any, dispatch: any, state: DefaultState }, { productId, size }: { productId: string, size: number }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.PURCHASE_CART_REMOVE_PRODUCT_SIZE, { productId, size })
        commit(MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE)
        dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: state.cart })
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
    async [ACTIONS.PURCHASE_GENERATE] ({ commit, getters, state }: { commit: any, getters: any, state: DefaultState }, { paymentMethod }: { paymentMethod: 'CREDIT' | 'MULTIBANCO' }) {
        commit(MUTATIONS.BLANK_REQUEST_START)

        const purchaseData: PurchaseFull = {
            clientId: getters[GETTERS.USER_ID],
            cart: state.cart,
            contacts: state.contacts,
            addressDelivery: state.addressDelivery,
            addressInvoice: state.addressInvoice,
            notesClient: state.notesClient,
            paymentMethod
        }

        return await purchaseAPI.generate(purchaseData).then((response: any) => {
            return {
                paymentMethod: response.data.data.paymentMethod,
                paymentKey: response.data.data.paymentKey,
                id: response.data.data.id,
                idShort: response.data.data.idShort,
                paymentReference: response.data.data.paymentReference,
                paymentEntity: response.data.data.paymentEntity
            }
        }).finally(() => {
            commit(MUTATIONS.BLANK_REQUEST_FINISH)
        })
    },
    async [ACTIONS.PURCHASE_WAIT] ({ commit, getters, state }: { commit: any, getters: any, state: DefaultState }, { purchaseIdShort }: { purchaseIdShort: 'string' }) {
        commit(MUTATIONS.BLANK_REQUEST_START)

        return await purchaseAPI
            .wait(purchaseIdShort)
            .then(() => true)
            .finally(() => { commit(MUTATIONS.BLANK_REQUEST_FINISH) })
    },
    async [ACTIONS.PURCHASE_CONFIRM] ({ commit, state }: { commit: any, state: DefaultState }, { intentId }: { intentId: string }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        await purchaseAPI.confirm(intentId)
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    },
}

const mutations = {
    [MUTATIONS.PURCHASE_RESET] (state: any) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.PURCHASE_RESET_SOFT] (state: DefaultState) {
        // Reset the user data if he logs out
        state.contacts.email = ''
        state.contacts.phone = ''
        state.addressDelivery.street = ''
        state.addressDelivery.city = ''
        state.addressDelivery.postalCode = ''
        state.addressInvoice.name = ''
        state.addressInvoice.nif = ''
        state.addressInvoice.postalCode = ''
        state.addressInvoice.street = ''
        state.addressInvoice.city = ''
    },
    [MUTATIONS.PURCHASE_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.PURCHASE_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.PURCHASE_CHANGE_GENERATE_STATE] (state: DefaultState, newState: boolean ) {
        state.generated = newState
    },
    [MUTATIONS.PURCHASE_CART_LOAD] (state: DefaultState, { cart }: { cart: {[index: string]: CartProduct }}) {
        state.cart = cart
    },
    [MUTATIONS.PURCHASE_CART_LOAD_PRODUCTS] (state: DefaultState, { products }: { products: {[index: string]: PublishedProduct }}) {
        state.products = products
    },
    [MUTATIONS.PURCHASE_CART_HEAL_PRODUCTS] (state: DefaultState) {
        // If the api did not return a product that was in the cart, then we should remove it from the cart
        const cartProductIds = Object.keys(state.cart)
        cartProductIds.forEach((productId: string) => {
            if (!state.products[productId]) {
                Vue.delete(state.cart, productId)
            }
        })
    },
    [MUTATIONS.PURCHASE_CART_SAVE_TO_STORAGE] (state: DefaultState) {
        LocalStorage.saveItem(state.settings.STORAGE_ITEM_NAME, state.cart)
    },
    [MUTATIONS.PURCHASE_CART_RECOVER_FROM_STORAGE] () {
        const fromStorage = LocalStorage.getItem(state.settings.STORAGE_ITEM_NAME)
        if (fromStorage === null) return
        state.cart = fromStorage
    },
    [MUTATIONS.PURCHASE_CART_INCREMENT_PRODUCT] (state: DefaultState, { productId, size }: { productId: string, size: number }) {
        if (!state.cart[productId]) {
            let newProduct: CartProduct = { id: productId, quantities: {} }
            // @ts-ignore
            Vue.set(state.cart, productId, newProduct)
        }
        if (!state.cart[productId].quantities[size]) {
            // @ts-ignore
            Vue.set(state.cart[productId].quantities, size, 0)
        }
        // Product max out, return
        if (state.cart[productId].quantities[size] === state.settings.MAX_PRODUCT_QUANTITY) return
        // Increment product quantity
        state.cart[productId].quantities[size] = state.cart[productId].quantities[size] + 1
    },
    [MUTATIONS.PURCHASE_CART_DECREMENT_PRODUCT] (state: DefaultState, { productId, size }: { productId: string, size: number }) {
        if (!state.cart[productId]) return
        let quantity = state.cart[productId].quantities[size]
        if (!quantity) return
        if (quantity === 1) return

        quantity--
        state.cart[productId].quantities[size] = quantity
    },
    [MUTATIONS.PURCHASE_CART_UPDATE_PRODUCT_PRICE] (state: DefaultState, { productId, price }: { productId: string, price: ProducePrice }) {
        if (!state.products[productId]) return

        Vue.set(state.products[productId], 'price', price.price)
        Vue.set(state.products[productId], 'discount', price.discount)
    },
    [MUTATIONS.PURCHASE_CART_REMOVE_PRODUCT] (state: DefaultState, { productId }: { productId: string }) {
        Vue.delete(state.cart, productId)
        Vue.delete(state.products, productId)
    },
    [MUTATIONS.PURCHASE_CART_REMOVE_PRODUCT_SIZE] (state: DefaultState, { productId, size }: { productId: string, size: number }) {
        if (!state.cart[productId]) return
        const quantity = state.cart[productId].quantities[size]
        if (!quantity) return
        // @ts-ignore
        Vue.delete(state.cart[productId].quantities, size)

        const remainingSizes = Object.keys(state.cart[productId].quantities)
        if (remainingSizes.length === 0) {
            Vue.delete(state.cart, productId)
            Vue.delete(state.products, productId)
        }
    },
    // Generic data change
    [MUTATIONS.PURCHASE_CHANGE_CLIENTS_NOTES] (state: DefaultState, notes: string) {
        state.notesClient = notes
    },
    [MUTATIONS.PURCHASE_DATA_CHANGE] (state: DefaultState, { segment, key, value }: { segment: string, key: string, value: any }) {
        // @ts-ignore
        if (!state[segment]) return
        // @ts-ignore
        if (!state[segment][key] === undefined) return
        // @ts-ignore
        state[segment][key] = value
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
