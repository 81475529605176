<template>
    <div class="col-sm-9 col-sm-push-3">
        <div class="wishlist-header hidden-xs">
            <div class="title-1">Artigo</div>
            <div class="title-2">Adicionar ao carrinho</div>
        </div>
        <div class="wishlist-box">
            <template v-if="!wishlist || wishlist.length === 0">
                <div class="article-container style-1">
                    <h2>Não tem produtos adicionados aos favoritos</h2>
                    <p>Nas páginas e cartões de cada produto pode carregar em <i class="fa fa-heart"></i> para adicionar o produto à sua lista de favoritos.</p>
                    <p>Pode consultar os seus produtos favoritos nesta página e adiciona-lo diretamente ao carrinho.</p>
                </div>
                <div style="min-height: 100px"></div>
            </template>
            <template v-else>
                <product-wish v-for="product in wishlistProducts"
                              :key="product.id"
                              :product="product"
                              @removeProduct="removeFromList"/>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
import VueMain from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import { PublishedProduct } from '../../utils/Interfaces/Products'
// Components
import ProductWish from '../product/wish.vue'


@Component({
    components: {
        ProductWish
    }
})
export default class ContentWishlist extends Vue {
    wishlistProducts: PublishedProduct[] = []

    get wishlist () {
        return this.$store.state.user.user.wishlist
    }

    removeFromList (productId: string) {
        let index = this.wishlistProducts.findIndex(product => product.id === productId)
        if (index === -1) return
        VueMain.delete(this.wishlistProducts, index)
    }

    async mounted () {
        this.wishlistProducts = await this.$store.dispatch(ACTIONS.USER_WISHLIST_GET_PRODUCTS)
    }
}
</script>
<style scoped lang="scss"></style>
