enum MUTATIONS {
    // BLANK EXAMPLES:
    'BLANK_RESET' = 'BLANK_RESET',
    'BLANK_REQUEST_START' = 'BLANK_REQUEST_START',
    'BLANK_REQUEST_FINISH' = 'BLANK_REQUEST_FINISH',
    // ROOT STORE MUTATIONS
    'APP_LOADING_START' = 'APP_LOADING_START',
    'APP_LOADING_FINISH' = 'APP_LOADING_FINISH',
    'APP_PERSISTENT_DATA_LOADED' = 'APP_PERSISTENT_DATA_LOADED',
    'APP_SETTINGS_LOAD' = 'APP_SETTINGS_LOAD',
    'APP_ERROR_SHOW' = 'APP_ERROR_SHOW',
    'APP_ERROR_HIDE' = 'APP_ERROR_HIDE',
    'APP_MESSAGE_SHOW' = 'APP_MESSAGE_SHOW',
    'APP_MESSAGE_HIDE' = 'APP_MESSAGE_HIDE',
    'APP_SIZE_PICKER_SHOW' = 'APP_SIZE_PICKER_SHOW',
    'APP_SIZE_PICKER_HIDE' = 'APP_SIZE_PICKER_HIDE',
    'APP_SIZE_PICKER_SUCCESS_SHOW' = 'APP_SIZE_PICKER_SUCCESS_SHOW',
    'APP_SIZE_PICKER_SUCCESS_HIDE' = 'APP_SIZE_PICKER_SUCCESS_HIDE',
    'APP_PURCHASE_SUCCESS_SHOW' = 'APP_PURCHASE_SUCCESS_SHOW',
    'APP_PURCHASE_SUCCESS_HIDE' = 'APP_PURCHASE_SUCCESS_HIDE',
    'APP_CART_POPUP_SHOW' = 'APP_CART_POPUP_SHOW',
    'APP_CART_POPUP_HIDE' = 'APP_CART_POPUP_HIDE',
    // PUBLISHED PRODUCTS MUTATIONS
    'PUBLISHED_PRODUCTS_RESET' = 'PUBLISHED_PRODUCTS_RESET',
    'PUBLISHED_PRODUCTS_REQUEST_START' = 'PUBLISHED_PRODUCTS_REQUEST_START',
    'PUBLISHED_PRODUCTS_REQUEST_FINISH' = 'PUBLISHED_PRODUCTS_REQUEST_FINISH',
    'PUBLISHED_PRODUCTS_INITIALIZED' = 'PUBLISHED_PRODUCTS_INITIALIZED',
    'PUBLISHED_PRODUCTS_LOAD_ALL' = 'PUBLISHED_PRODUCTS_LOAD_ALL',
    'PUBLISHED_PRODUCTS_LOAD_ONE' = 'PUBLISHED_PRODUCTS_LOAD_ONE',
    'PUBLISHED_PRODUCTS_LOAD_FEATURED' = 'PUBLISHED_PRODUCTS_LOAD_FEATURED',
    'PUBLISHED_PRODUCTS_UPDATE_PRODUCT_PRICE' = 'PUBLISHED_PRODUCTS_UPDATE_PRODUCT_PRICE',
    'PUBLISHED_PRODUCTS_SEARCH_INIT' = 'PUBLISHED_PRODUCTS_SEARCH_INIT',
    'PUBLISHED_PRODUCTS_SEARCH_LOAD' = 'PUBLISHED_PRODUCTS_SEARCH_LOAD',
    // CATEGORIES
    'CATEGORIES_RESET' = 'CATEGORIES_RESET',
    'CATEGORIES_REQUEST_START' = 'CATEGORIES_REQUEST_START',
    'CATEGORIES_REQUEST_FINISH' = 'CATEGORIES_REQUEST_FINISH',
    'CATEGORIES_LOAD' = 'CATEGORIES_LOAD',
    // COLORS
    'COLORS_RESET' = 'COLORS_RESET',
    'COLORS_REQUEST_START' = 'COLORS_REQUEST_START',
    'COLORS_REQUEST_FINISH' = 'COLORS_REQUEST_FINISH',
    'COLORS_LOAD' = 'COLORS_LOAD',
    // BRANDS
    'BRANDS_RESET' = 'BRANDS_RESET',
    'BRANDS_REQUEST_START' = 'BRANDS_REQUEST_START',
    'BRANDS_REQUEST_FINISH' = 'BRANDS_REQUEST_FINISH',
    'BRANDS_LOAD' = 'BRANDS_LOAD',
    // BANNERS
    'BANNERS_LOAD' = 'BANNERS_LOAD',
    // TAGS
    'TAGS_RESET' = 'TAGS_RESET',
    'TAGS_REQUEST_START' = 'TAGS_REQUEST_START',
    'TAGS_REQUEST_FINISH' = 'TAGS_REQUEST_FINISH',
    'TAGS_LOAD' = 'TAGS_LOAD',
    // STORES
    'STORES_RESET' = 'STORES_RESET',
    'STORES_REQUEST_START' = 'STORES_REQUEST_START',
    'STORES_REQUEST_FINISH' = 'STORES_REQUEST_FINISH',
    'STORES_LOAD' = 'STORES_LOAD',
    // CONTACT EXAMPLES:
    'CONTACT_RESET' = 'CONTACT_RESET',
    'CONTACT_REQUEST_START' = 'CONTACT_REQUEST_START',
    'CONTACT_REQUEST_FINISH' = 'CONTACT_REQUEST_FINISH',
    // USER CLIENT
    'USER_RESET' = 'USER_RESET',
    'USER_REQUEST_START' = 'USER_REQUEST_START',
    'USER_REQUEST_FINISH' = 'USER_REQUEST_FINISH',
    'USER_LOG_USER' = 'USER_LOG_USER',
    'USER_SET_TOKEN' = 'USER_SET_TOKEN',
    'USER_DELETE_TOKEN' = 'USER_DELETE_TOKEN',
    'USER_EMAIL_CONFIRMED' = 'USER_EMAIL_CONFIRMED',
    'USER_WISHLIST_UPDATE' = 'USER_WISHLIST_UPDATE',
    'USER_ADDRESS_UPDATE_ADDRESS' = 'USER_ADDRESS_UPDATE_ADDRESS',
    // PURCHASE
    'PURCHASE_RESET' = 'PURCHASE_RESET',
    'PURCHASE_RESET_SOFT' = 'PURCHASE_RESET_SOFT',
    'PURCHASE_REQUEST_START' = 'PURCHASE_REQUEST_START',
    'PURCHASE_REQUEST_FINISH' = 'PURCHASE_REQUEST_FINISH',
    'PURCHASE_CHANGE_GENERATE_STATE' = 'PURCHASE_CHANGE_GENERATE_STATE',
    'PURCHASE_CART_LOAD' = 'PURCHASE_CART_LOAD',
    'PURCHASE_CART_LOAD_PRODUCTS' = 'PURCHASE_CART_LOAD_PRODUCTS',
    'PURCHASE_CART_HEAL_PRODUCTS' = 'PURCHASE_CART_HEAL_PRODUCTS',
    'PURCHASE_CART_SAVE_TO_STORAGE' = 'PURCHASE_CART_SAVE_TO_STORAGE',
    'PURCHASE_CART_RECOVER_FROM_STORAGE' = 'PURCHASE_CART_RECOVER_FROM_STORAGE',
    'PURCHASE_CART_INCREMENT_PRODUCT' = 'PURCHASE_CART_INCREMENT_PRODUCT',
    'PURCHASE_CART_DECREMENT_PRODUCT' = 'PURCHASE_CART_DECREMENT_PRODUCT',
    'PURCHASE_CART_UPDATE_PRODUCT_PRICE' = 'PURCHASE_CART_UPDATE_PRODUCT_PRICE',
    'PURCHASE_CART_REMOVE_PRODUCT' = 'PURCHASE_CART_REMOVE_PRODUCT',
    'PURCHASE_CART_REMOVE_PRODUCT_SIZE' = 'PURCHASE_CART_REMOVE_PRODUCT_SIZE',
    'PURCHASE_CHANGE_CLIENTS_NOTES' = 'PURCHASE_CHANGE_CLIENTS_NOTES',
    'PURCHASE_DATA_CHANGE' = 'PURCHASE_DATA_CHANGE',
    // COUNTRIES
    'COUNTRIES_RESET' = 'COUNTRIES_RESET',
    'COUNTRIES_REQUEST_START' = 'COUNTRIES_REQUEST_START',
    'COUNTRIES_REQUEST_FINISH' = 'COUNTRIES_REQUEST_FINISH',
    'COUNTRIES_LOAD_COUNTRIES' = 'COUNTRIES_LOAD_COUNTRIES',
    'COUNTRIES_LOAD_COUNTRY_GROUPS' = 'COUNTRIES_LOAD_COUNTRY_GROUPS',
}

export default MUTATIONS
