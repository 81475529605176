// Import the correspondent API object
// Store getters, actions and mutation types
import MUTATIONS from '../types-mutations'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { BannersObject} from '../../utils/Interfaces/Banners'

interface DefaultState {
    bannersObject: BannersObject
    loading: boolean
}

const defaultState: DefaultState = {
    bannersObject: {},
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {}

const actions = {}

const mutations = {
    [MUTATIONS.BANNERS_LOAD] (state: DefaultState, { bannersObject }: { bannersObject: BannersObject }) {
        state.bannersObject = bannersObject
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
