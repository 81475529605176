<template>
    <div class="information-entry col-md-6">
        <div class="image-text-widget" :style="`background-image: url(${imageUrl}); background-size: 48% auto;`">
            <h3 class="title">{{ store.name }}</h3>
            <div class="article-container style-1">
                <p>{{ store.address }}</p>
                <ul>
                    <li>
                        <a :href="`tel:${store.phone}`">
                            <i class="fa fa-phone"></i>
                            {{ store.phone }}
                        </a>
                        <p class="header-top-entry-legal">chamada para a rede fixa nacional</p>
                    </li>
                    <li>
                        <a :href="store.mapLink" target="_blank">
                            <i class="fa fa-map-marker"></i>
                            Google Maps
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Store } from '../../utils/Interfaces/Stores'
import getImageUrl from '../../utils/ImageURL'

@Component({})
export default class ContentStoreCard extends Vue {
    @Prop({}) store!: Store

    get imageUrl () {
        return getImageUrl({ filePath: this.store.thumbnail })
    }
}
</script>
<style scoped lang="scss"></style>
