enum ACTIONS {
    // BLANK EXAMPLES:
    'BLANK_ACTION' = 'BLANK_ACTION',
    // ROOT STORE ACTIONS
    'RESET_ALL_STORES_MODULES' = 'RESET_ALL_STORES_MODULES',
    'LOAD_PERSISTENT_STORES' = 'LOAD_PERSISTENT_STORES',
    // USER ACTIONS
    // UNPUBLISHED_PRODUCTS
    // PUBLISHED_PRODUCTS
    'PUBLISHED_PRODUCTS_LOAD' = 'PUBLISHED_PRODUCTS_LOAD',
    'PUBLISHED_PRODUCTS_LOAD_ONE' = 'PUBLISHED_PRODUCTS_LOAD_ONE',
    'PUBLISHED_PRODUCTS_GET_FEATURED' = 'PUBLISHED_PRODUCTS_GET_FEATURED',
    'PUBLISHED_PRODUCTS_GET_PRODUCT_STOCK' = 'PUBLISHED_PRODUCTS_GET_PRODUCT_STOCK',
    'PUBLISHED_PRODUCTS_GET_PRODUCT_PRICE' = 'PUBLISHED_PRODUCTS_GET_PRODUCT_PRICE',
    'PUBLISHED_PRODUCTS_SEARCH' = 'PUBLISHED_PRODUCTS_SEARCH',
    // CATEGORIES
    'CATEGORIES_LOAD' = 'CATEGORIES_LOAD',
    // COLORS
    'COLORS_LOAD' = 'COLORS_LOAD',
    // BRANDS
    'BRANDS_LOAD' = 'BRANDS_LOAD',
    // TAGS
    'TAGS_LOAD' = 'TAGS_LOAD',
    // STORES
    'STORES_LOAD' = 'STORES_LOAD',
    // CONTACT
    'CONTACT_REGISTER' = 'CONTACT_REGISTER',
    // USER
    'USER_LOGOUT' = 'USER_LOGOUT',
    'USER_REGISTER' = 'USER_REGISTER',
    'USER_GET_TOKEN' = 'USER_GET_TOKEN',
    'USER_SEND_RECOVER_PASSWORD' = 'USER_SEND_RECOVER_PASSWORD',
    'USER_CHECK_RECOVER_PASSWORD' = 'USER_CHECK_RECOVER_PASSWORD',
    'USER_PERFORM_RECOVER_PASSWORD' = 'USER_PERFORM_RECOVER_PASSWORD',
    'USER_REFLECT' = 'USER_REFLECT',
    'USER_DELETE_ACCOUNT' = 'USER_DELETE_ACCOUNT',
    'USER_CHANGE_PASSWORD' = 'USER_CHANGE_PASSWORD',
    'USER_CONFIRM_EMAIL' = 'USER_CONFIRM_EMAIL',
    'USER_RESEND_CONFIRM_EMAIL' = 'USER_RESEND_CONFIRM_EMAIL',
    'USER_CHANGE_DATA' = 'USER_CHANGE_DATA',
    'USER_WISHLIST_TOGGLE_PRODUCT' = 'USER_WISHLIST_TOGGLE_PRODUCT',
    'USER_WISHLIST_GET_PRODUCTS' = 'USER_WISHLIST_GET_PRODUCTS',
    'USER_ADDRESS_GET_BOTH' = 'USER_ADDRESS_GET_BOTH',
    'USER_ADDRESS_UPDATE_ADDRESS' = 'USER_ADDRESS_UPDATE_ADDRESS',
    'USER_CART_UPDATE_CART' = 'USER_CART_UPDATE_CART',
    'USER_GET_PURCHASES' = 'USER_GET_PURCHASES',
    'USER_GET_PURCHASE_PRODUCTS' = 'USER_GET_PURCHASE_PRODUCTS',
    // PURCHASE
    'PURCHASE_CART_LOAD' = 'PURCHASE_CART_LOAD',
    'PURCHASE_CART_LOAD_PRODUCTS' = 'PURCHASE_CART_LOAD_PRODUCTS',
    'PURCHASE_CART_RECOVER_FROM_STORAGE' = 'PURCHASE_CART_RECOVER_FROM_STORAGE',
    'PURCHASE_CART_ADD_PRODUCT' = 'PURCHASE_CART_ADD_PRODUCT',
    'PURCHASE_CART_DECREMENT_PRODUCT' = 'PURCHASE_CART_DECREMENT_PRODUCT',
    'PURCHASE_CART_REMOVE_PRODUCT' = 'PURCHASE_CART_REMOVE_PRODUCT',
    'PURCHASE_CART_REMOVE_PRODUCT_SIZE' = 'PURCHASE_CART_REMOVE_PRODUCT_SIZE',
    'PURCHASE_GENERATE' = 'PURCHASE_GENERATE',
    'PURCHASE_WAIT' = 'PURCHASE_WAIT',
    'PURCHASE_CONFIRM' = 'PURCHASE_CONFIRM',
}

export default ACTIONS
