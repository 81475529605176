<template>
    <div class="header-top">
        <div class="header-top-entry">
            <div class="title">
                <i class="fa fa-phone"></i>Alguma questão? Ligue-nos <a :href="'tel:' + contactNumber"><b>{{ contactNumber }}</b></a>
                <div class="header-top-entry-legal">chamada para a rede fixa nacional</div>
            </div>
        </div>

        <div class="menu-button responsive-menu-toggle-class" @click="$emit('toggleMenu')"><i class="fa fa-reorder"></i></div>
        <div class="clear"></div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class HeaderUserBar extends Vue {
    get contactNumber () {
        return this.$store.state.settings.contact_phone
    }
}
</script>
<style scoped lang="scss"></style>
