// Import the correspondent API object
import categoriesAPI from '../../api/categories'
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
import GETTERS from '../types-getters'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { Category, CategoryTree } from '../../utils/Interfaces/Categories'

interface DefaultState {
    loading: boolean
    categoryTree: CategoryTree
}

const defaultState: DefaultState = {
    categoryTree: {},
    loading: false
}

let state = cloneDeep(defaultState)

const filterTree = (state: any, flag1: string, flag2: string) => {
    let categoriesArray = Object.values(state.categoryTree) as Category[]
    return categoriesArray
        // @ts-ignore TODO: see whats happening here
        .filter(cat => cat[flag1] && cat[flag2])
        .reduce((tree: CategoryTree, category: Category) => {
            tree[category.id] = category
            return tree
        }, {})
}

const getters = {
    [GETTERS.CATEGORY_GET_WOMAN_SHOES]: (state: DefaultState) => {
        return filterTree(state, 'isWoman', 'forShoe')
    },
    [GETTERS.CATEGORY_GET_MAN_SHOES]: (state: DefaultState) => {
        return filterTree(state, 'isMan', 'forShoe')
    },
    [GETTERS.CATEGORY_GET_WOMAN_ACCESSORIES]: (state: DefaultState) => {
        return filterTree(state, 'isWoman', 'forAccessorie')
    },
    [GETTERS.CATEGORY_GET_MAN_ACCESSORIES]: (state: DefaultState) => {
        return filterTree(state, 'isMan', 'forAccessorie')
    }
}

const actions = {
    async [ACTIONS.CATEGORIES_LOAD] ({ commit }: { commit: any }): Promise<void> {
        commit(MUTATIONS.BLANK_REQUEST_START)
        await categoriesAPI.getTree().then((response: any) => {
            commit(MUTATIONS.CATEGORIES_LOAD, { categoryTree: response.data.data })
        })
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    }
}

const mutations = {
    [MUTATIONS.CATEGORIES_RESET] (state: DefaultState) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.CATEGORIES_REQUEST_START] (state: DefaultState) {
        state.loading = true
    },
    [MUTATIONS.CATEGORIES_REQUEST_FINISH] (state: DefaultState) {
        state.loading = false
    },
    [MUTATIONS.CATEGORIES_LOAD] (state: DefaultState, { categoryTree }: { categoryTree: CategoryTree }) {
        state.categoryTree = categoryTree
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
