<template>
    <div>
        <div class="accordeon-title"
             @click="isOpen = !isOpen">
            <div class="tags-box" style="display: inline-block;">
                <a href="#">{{ purchaseStateString }}</a>
            </div>
            Ref: <strong class="mono-font">{{ purchase.idShort }}</strong> - A sua compra no dia <span class="mono-font">{{ purchaseDate }}</span>
        </div>
        <div class="accordeon-entry" v-if="isOpen">
            <div class="article-container">
                <template v-if="purchase.state === PurchaseStatus.MULTIBANCO">
                    <p>
                        Esta encomenda aguarda o pagamento.
                        Os dados de pagamento são válidos durante 6 horas.
                    </p>
                    <h5 class="mono-font">
                        Entidade: {{ purchase.paymentEntity }}
                    </h5>
                    <h5 class="mono-font">
                        Referência: {{ purchase.paymentReference }}
                    </h5>
                    <h5 class="mono-font">
                        Valor: {{ purchase.priceTotal.toFixed(2)}} €
                    </h5>
                    <hr>
                </template>
                <h5 class="mono-font">
                    Preço base: {{ purchase.priceBase.toFixed(2)}} €
                </h5>
                <h5 class="mono-font">
                    Custo de entrega: {{ purchase.priceDelivery.toFixed(2)}} €
                </h5>
                <h5 class="mono-font">
                    IVA ({{ purchase.priceIVA}} %): {{ purchase.priceTax.toFixed(2)}} €
                </h5>
                <h4 class="mono-font">
                    Total: {{ purchase.priceTotal.toFixed(2)}} €
                </h4>
                <hr>
                <h3>Produtos:</h3>
                <br/>
                <other-loading v-if="!hasRequested" />
                <div class="information-blocks" v-else>
                    <div class="products-list">
                        <product-purchase-item v-for="productId in cartProductIds"
                                               :key="productId"
                                               :product="products[productId]"
                                               :quantities="cart[productId].quantities"/>
                    </div>
                </div>
                <hr>
                <h3>Morada de entrega:</h3>
                <div class="mono-font">
                    <h5 style="display: inline-block;">País:</h5> {{ purchase.addressDeliveryCountry }}
                </div>
                <div class="mono-font">
                    <h5 style="display: inline-block;">Morada:</h5> {{ purchase.addressDeliveryStreet }}
                </div>
                <div class="mono-font">
                    <h5 style="display: inline-block;">Código Postal:</h5> {{ purchase.addressDeliveryPostalCode }}
                </div>
                <div class="mono-font">
                    <h5 style="display: inline-block;">Localidade:</h5> {{ purchase.addressInvoiceCity }}
                </div>
                <template v-if="purchase.addressInvoiceNif">
                    <hr>
                    <h3>Morada de faturação:</h3>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">NIF:</h5> {{ purchase.addressInvoiceNif }}
                    </div>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">Nome:</h5> {{ purchase.addressInvoiceName }}
                    </div>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">País:</h5> {{ purchase.addressInvoiceCountry }}
                    </div>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">Morada:</h5> {{ purchase.addressInvoiceStreet }}
                    </div>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">Código Postal:</h5> {{ purchase.addressInvoicePostalCode }}
                    </div>
                    <div class="mono-font">
                        <h5 style="display: inline-block;">Localidade:</h5> {{ purchase.addressInvoiceCity }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { AuxData } from '../../utils/Interfaces/_misc'
    import { Purchase, PurchaseStatus } from '../../utils/Interfaces/Purchase'
    import { PublishedProduct, ConstructedProduct } from '../../utils/Interfaces/Products'
    import constructProduct from '../../utils/Constructers/Product'
    import moment from 'moment'
    import ACTIONS from '../../store/types-actions'
    // Components
    import OtherLoading from '../other/loading.vue'
    import ProductPurchaseItem from '../product/purchaseItem.vue'

    @Component({
        components: {
            OtherLoading,
            ProductPurchaseItem
        }
    })
    export default class ContentPurchasesItem extends Vue {
        @Prop({}) purchase!: Purchase
        products: { [index: string]: ConstructedProduct } = {}
        hasRequested: boolean = false
        isOpen: boolean = false
        PurchaseStatus = PurchaseStatus

        get purchaseDate () {
            return moment(this.purchase.date).format('YYYY-MM-DD')
        }
        get purchaseStateString () {
            switch (this.purchase.state) {
                case this.PurchaseStatus.MULTIBANCO:
                    return 'AGUARDA PAGAMENTO'
                case this.PurchaseStatus.PAYED:
                    return 'A PREPARAR'
                case this.PurchaseStatus.DISPATCHED_REQUESTED:
                    return 'P/RECOLHA'
                case this.PurchaseStatus.DISPATCHED:
                    return 'A TRANSITO'
                case this.PurchaseStatus.DELIVERED:
                    return 'ENTREGUE'
                case this.PurchaseStatus.CANCELED:
                    return 'CANCELADA'
            }
        }
        get cart () {
            return JSON.parse(this.purchase.cart)
        }
        get cartProductIds () {
            return Object.keys(this.cart)
        }
        get auxData (): AuxData {
            return {
                colors: this.$store.state.colors.colorsObject,
                brands: this.$store.state.brands.brandsObject,
                categories: this.$store.state.categories.categoryTree,
                tags: this.$store.state.tags.tagsObject
            }
        }

        @Watch('isOpen')
        onOpen () {
            this.loadProducts()
        }

        loadProducts () {
            if (this.hasRequested) return

            this.$store.dispatch(ACTIONS.USER_GET_PURCHASE_PRODUCTS, {
                purchaseId: this.purchase.id
            }).then((products: PublishedProduct[]) => {
                products.forEach((product: PublishedProduct) => {
                    this.products[product.id] = constructProduct(product, this.auxData)
                })
            }).finally(() => {
                this.hasRequested = true
            })
        }
    }
</script>
<style scoped lang="scss"></style>
