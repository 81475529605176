<template>
    <div>
        <div class="accordeon-title active">
            <span class="number">5</span>Pagamento
        </div>
        <div class="accordeon-entry">
            <div v-if="validPurchase.error" class="row">
                <div class="col-md-12 information-entry">
                    <div class="message-box message-danger">
                        <div class="message-icon"><i class="fa fa-times"></i></div>
                        <div class="message-text">
                            Campos em falta:
                            <p v-for="missingField in validPurchase.missingFields"
                               :key="missingField">&nbsp;&nbsp;&nbsp;&nbsp;{{ missingField}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <label>Observações</label>
                    <textarea rows="4"
                              class="simple-field"
                              style="resize: none;"
                              v-model="notesClient"
                              :disabled="purchaseGenerated"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <label>Método de pagamento</label>
                    <label class="checkbox-entry"
                           :class="{
                               'input--disabled': purchaseGenerated
                           }">
                        <input type="radio"
                               name="paymentMethod"
                               value='MULTIBANCO'
                               v-model="paymentMethod"
                               :disabled="purchaseGenerated">
                        <span class="check"></span> Referência multibanco
                    </label>
                    <label class="checkbox-entry"
                           :class="{
                               'input--disabled': purchaseGenerated
                           }">
                        <input type="radio"
                               name="paymentMethod"
                               value='CREDIT'
                               v-model="paymentMethod"
                               :disabled="purchaseGenerated">
                        <span class="check"></span> Cartão de crédito
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    
                    <other-loading v-show="processing.active"
                                   :message="processing.message"/>
                    <div class="article-container style-1" v-show="!processing.active" style="text-align: center;">
                        <button class="button style-10" @click="generatePayment()" v-if="!purchaseGenerated">
                            Gerar pagamento
                        </button>
                        <template v-else>
                            <template v-if="payment_method_final === 'CREDIT'">
                                <form @submit.prevent>
                                    <label>Nome Cartão</label>
                                    <input class="simple-field"
                                        v-model="cardName"
                                        type="text"
                                        @focus="cardNameError = false">
                                    <div id="stripe-card-element"></div>
                                    <button class="button style-10"
                                            style="margin-top: 20px;"
                                            id="stripe-card-button"
                                            @click="triggerPayment()">
                                        Efetuar pagamento
                                    </button>
                                </form>
                            </template>
                            <template v-if="payment_method_final === 'MULTIBANCO'">
                                <p>
                                    <br/>
                                    <br/>
                                    Referência multibanco gerada com sucesso<br/>
                                    A referência multibanco é válida durante as próximas 6 horas.<br/>
                                    Utilize os dados de pagamento para finalizar a sua compra:
                                </p>
                                <ul class="mono-font">
                                    <li><b>Entidade: </b>{{ payment_entity }}</li>
                                    <li><b>Referência: </b>{{ payment_reference }}</li>
                                    <li><b>Valor: </b>{{ cartTotalPrice.total.toFixed(2) }} €</li>
                                </ul>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import ACTIONS from '../../store/types-actions'
    import MUTATIONS from '../../store/types-mutations'
    import { PAYMENT_METHODS } from '../../utils/Interfaces/Purchase'
    import { CartTotalPrice } from '../../utils/Constructers/CartFunctions'
    // Components
    import OtherLoading from '../other/loading.vue'

    const DEFAULT_PAYMENT_METHOD = 'MULTIBANCO'

    const STRIPE_APP_REGISTRY_DEV = 'pk_test_yzgDQosUG5irSMpW98IaCjk700JTb33wyH'
    const STRIPE_APP_REGISTRY_PROD = 'pk_live_I5948S9yVqtyokpUjuIrWaEI00299XeXff'
    const STRIPE_PUBLIC_KEY = process.env.NODE_ENV === 'development' ? STRIPE_APP_REGISTRY_DEV : STRIPE_APP_REGISTRY_PROD

    interface PurchaseGenerateOutput {
        paymentMethod: PAYMENT_METHODS
        paymentKey: string
        id: string
        idShort: string
        paymentReference: string
        paymentEntity: string

    }

    @Component({
        components: {
            OtherLoading
        }
    })
    export default class CheckoutPayment extends Vue {
        @Prop({}) cartTotalPrice!: CartTotalPrice

        validPurchase = {
            error: false,
            missingFields: ['']
        }

        processing = { active: false, message: '' }

        paymentMethod: 'CREDIT' | 'MULTIBANCO' = DEFAULT_PAYMENT_METHOD

        purchaseId: string = ''
        purchaseIdShort: string = ''
        payment_key: string = ''
        payment_intent_id = ''
        payment_method_final: '' | PAYMENT_METHODS = ''
        payment_reference: string = ''
        payment_entity: string = ''

        cardName: string = 'Joaquim Fonseca'
        cardNameError: boolean = false

        // Stripe elements
        stripe: null | any = null
        cardElement: null | any = null

        get notesClient () {
            return this.$store.state.purchase.notesClient
        }
        set notesClient (value) {
            this.$store.commit(MUTATIONS.PURCHASE_CHANGE_CLIENTS_NOTES, value)
        }

        get purchase () {
            return this.$store.state.purchase
        }
        get purchaseGenerated () {
            return this.$store.state.purchase.generated
        }

        purchaseHasError (): boolean {
            this.validPurchase.error = false
            this.validPurchase.missingFields = []

            if (this.purchase.contacts.email === '') this.validPurchase.missingFields.push('Contactos - email de contacto')
            if (this.purchase.contacts.phone === '') this.validPurchase.missingFields.push('Contactos - contacto telefónico')
            if (this.purchase.addressDelivery.name === '') this.validPurchase.missingFields.push('Morada de Entrega - nome do distanatário')
            if (this.purchase.addressDelivery.country === '') this.validPurchase.missingFields.push('Morada de Entrega - país')
            if (this.purchase.addressDelivery.city === '') this.validPurchase.missingFields.push('Morada de Entrega - morada')
            if (this.purchase.addressDelivery.city === '') this.validPurchase.missingFields.push('Morada de Entrega - cidade / localidade')
            if (this.purchase.addressDelivery.postalCode === '') this.validPurchase.missingFields.push('Morada de Entrega - código postal')
            
            if (this.validPurchase.missingFields.length > 0) this.validPurchase.error = true
            return this.validPurchase.error
        }
        @Watch('purchase', { deep: true })
        onPurchaseChange () {
            this.validPurchase.error = false
            this.validPurchase.missingFields = []
        }

        async generatePayment (): Promise<void> {
            if (this.purchaseHasError()) return

            this.processing.active = true
            this.processing.message = 'A gerar os dados de pagamento...'

            // TODO: validacao dos formularios
            await this.$store
                .dispatch(ACTIONS.PURCHASE_GENERATE, { paymentMethod: this.paymentMethod })
                .then((data: PurchaseGenerateOutput) => {
                    this.payment_method_final = data.paymentMethod
                    this.payment_key = data.paymentKey
                    this.purchaseIdShort = data.idShort
                    this.payment_reference = data.paymentReference
                    this.payment_entity = data.paymentEntity

                    this.$store.commit(MUTATIONS.PURCHASE_CHANGE_GENERATE_STATE, true)

                    switch (this.payment_method_final) {
                        case 'MULTIBANCO':
                            this.$nextTick(() => { this.waitingPayment() })
                            break;
                        case 'CREDIT':
                            this.$nextTick(() => { this.mountStripe() })
                            break;
                    }
                })
                .catch((error) => {
                    const apiError = error.response.data.error
                    this.$store.commit(MUTATIONS.APP_MESSAGE_SHOW, {
                        title: 'Não foi possível concluir a sua compra.',
                        message: `Ocorreu um erro e não foi possível concluir a sua compra. [Código: (${apiError.code}) - ${apiError.message}]`
                    })
                })

            this.processing.active = false
            this.processing.message = ''
        }

        mountStripe () {
            if (!this.payment_key) return
            // @ts-ignore
            this.stripe = window.Stripe(STRIPE_PUBLIC_KEY)
            const elements = this.stripe.elements()

            this.cardElement = elements.create('card')
            this.cardElement.mount('#stripe-card-element')
        }

        async triggerPayment () {
            const cardButton = document.getElementById('stripe-card-button')

            if (!this.payment_key) return
            if (!cardButton) return

            this.processing.active = true
            this.processing.message = 'A processar o seu pagamento...'

            const { paymentIntent, error } = await this.stripe.handleCardPayment(
                this.payment_key,
                this.cardElement,
                {
                    payment_method_data: {
                        billing_details: {
                            name: this.cardName
                        }
                    }
                }
            )

            this.processing.active = false
            this.processing.message = ''

            if (error) {
                // Display error.message in your UI.
                // TODO: handle stripe payment error
                console.log('payment error: ', error)
            } else {
                // The payment has succeeded. Display a success message.
                this.payment_intent_id = paymentIntent.id
                // Confirm payment in the backend
                this.$store.dispatch(ACTIONS.PURCHASE_CONFIRM, { intentId: this.payment_intent_id })
                this.$store.commit(MUTATIONS.APP_PURCHASE_SUCCESS_SHOW, { shortId: this.purchaseIdShort })
            }
        }

        async waitingPayment () {
            this.processing.active = true
            this.$store
                .dispatch(ACTIONS.PURCHASE_WAIT, { purchaseIdShort: this.purchaseIdShort })
                .then(() => {
                    this.$store.commit(MUTATIONS.APP_PURCHASE_SUCCESS_SHOW, {
                        idShort: this.purchaseIdShort,
                        paymentMethod: this.payment_method_final,
                        paymentReference: this.payment_reference,
                        paymentEntity: this.payment_entity,
                        paymentValue: this.cartTotalPrice.total
                    })
                })
                .finally(() => { this.processing.active = false })
        }

        mounted () {
            this.$store.commit(MUTATIONS.PURCHASE_CHANGE_GENERATE_STATE, false)
        }
    }
</script>
<style scoped lang="scss"></style>
