import HTTP from '../utils/HTTP'
import API from '../utils/API'
import { Contact } from '../utils/Interfaces/Contacts'

// API access to both public and admin restricted routes. Can't set up a base API_ROUTE
export const API_ROUTE = ''

class ContactsAPI extends API {
    // Public access
    register (contact: Contact) {
        return HTTP.api().post(`/public/contact`, contact)
    }
}

export default new ContactsAPI(API_ROUTE)
