<template>
    <div class="content-push">
        <product-bread-crumb :product="constructedProduct"/>
        <product-info :product="constructedProduct"/>
        <content-featured-products />
        <div class="hidden-sm hidden-xs" style="height: 30px;"></div>
    </div>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import constructProduct from '../../utils/Constructers/Product'
import ProductBreadCrumb from '../product/breadCrumb.vue'
import ProductInfo from '../product/info.vue'
import ContentFeaturedProducts from '../content/featuredProducts.vue'
import { AuxData } from '../../utils/Interfaces/_misc'

@Component({
    components: {
        ProductBreadCrumb,
        ProductInfo,
        ContentFeaturedProducts
    }
})
export default class PageProduct extends Vue {
    get productId () {
        return this.$route.params.id
    }
    @Watch('productId')
    onPropertyChanged(value: string) {
        this.loadProduct(value)
    }

    loadProduct (productId: string): void {
        this.$store.dispatch(ACTIONS.PUBLISHED_PRODUCTS_LOAD_ONE, { productId }).catch(() => {
            this.$router.replace({ name: 'notFound' })
        })
    }

    get auxData (): AuxData {
        return {
            colors: this.$store.state.colors.colorsObject,
            brands: this.$store.state.brands.brandsObject,
            categories: this.$store.state.categories.categoryTree,
            tags: this.$store.state.tags.tagsObject
        }
    }

    get product () {
        return this.$store.state.publishedProducts.products[this.productId] || null
    }

    get constructedProduct () {
        if (!this.product) return null
        return constructProduct(this.product, this.auxData)
    }

    created () {
        this.loadProduct(this.productId)
    }
}
</script>
<style scoped lang="scss"></style>
