import axios from 'axios'

class HTTP {
    baseURL: string
    headers: any

    constructor () {
        this.baseURL = ''
        this.headers = {}
    }
    set ({ baseURL }: { baseURL: string }) {
        this.baseURL = baseURL || (this.baseURL || '')
    }
    setHeader ({ key, value }: { key: string, value: string }) {
        this.headers[key] = value
    }
    unsetHeader ({ key }: { key: string }): void {
        delete this.headers[key]
    }
    unset ({ baseURL }: { baseURL?: string }) {
        if (baseURL) { this.baseURL = '' }
    }
    reset () {
        this.constructor()
    }
    api () {
        let config = {
            baseURL: this.baseURL,
            headers: this.headers,
            withCredentials: true
        }
        return axios.create(config)
    }
}

export default (new HTTP())
