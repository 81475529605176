<template>
    <div class="col-xs-12">
        <div class="article-container style-1">
            <!-- Resend email confirmation boxes -->
            <div class="message-box message-warning" v-if="!emailConfirmed && !hasResentConfirmationEmail">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    <b>Atenção!</b>
                    Ainda não confirmou o seu email.<br/>
                    <a @click="resendEmailConfirmation()">
                        Clique aqui para reenviar o link de confirmação do seu email.
                    </a>
                </div>
            </div>
            <div v-if="showResentInfo" class="message-box message-success">
                <div class="message-icon"><i class="fa fa-check"></i></div>
                <div class="message-text">
                    Reenviamos o email de confirmação do seu email.<br />
                    Se não receber o email dentro de momentos, verifique a pasta de Spam do seu email.
                </div>
                <div class="message-close" @click="showResentInfo = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <!-- other warning boxes -->
            <div v-if="formError.active" class="message-box message-danger">
                <div class="message-icon"><i class="fa fa-times"></i></div>
                <div class="message-text">{{ formError.message }}</div>
                <div class="message-close" @click="formError.active = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div v-if="formSuccess.active" class="message-box message-success">
                <div class="message-icon"><i class="fa fa-check"></i></div>
                <div class="message-text">{{ formSuccess.message }}</div>
                <div class="message-close" @click="formSuccess.active = false">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="message-box message-warning" v-if="emailChanged">
                <div class="message-icon"><i class="fa fa-exclamation"></i></div>
                <div class="message-text">
                    <b>Atenção!</b>
                    A alteração de email implica a reconfirmação do mesmo.
                    Após a alteração deverá confirmar o novo email através do link de confirmação que lhe iremos enviar para o novo endereço.
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-3 col-sm-6">
                    <form @submit.prevent="updateUserData()">
                        <!-- email -->
                        <label>Email</label>
                        <input v-model="userEmail"
                               type="email"
                               placeholder="Email"
                               class="simple-field width"
                               maxlength="75"
                               @focus="hideMessages()"
                               required/>
                        <!-- name -->
                        <label>Nome</label>
                        <input v-model="userName"
                               type="text"
                               placeholder="Primeiro e último nome"
                               class="simple-field width"
                               maxlength="150"
                               @focus="hideMessages()"
                               required/>
                        <!-- phone -->
                        <label>Telefone</label>
                        <input v-model="userPhone"
                               type="text"
                               placeholder="Telefone"
                               class="simple-field width"
                               maxlength="15"
                               @focus="hideMessages()"
                               @input="healPhoneNumber()"
                               required/>
                        <div class="button style-10" v-if="dataHasChanges">
                            Alterar<input type="submit" value="">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { UserChangeData } from '../../utils/Interfaces/User'
import { healPhone, INDICATIVO } from '../../utils/formHealers'
import ACTIONS from '../../store/types-actions'

@Component({})
export default class FormUserSettings extends Vue {
    userEmail: string = ''
    userName: string = ''
    userPhone: string = ''
    formError = {
        active: false,
        message: ''
    }
    formSuccess = {
        active: false,
        message: ''
    }
    hasResentConfirmationEmail: boolean = false
    showResentInfo = false

    get emailConfirmed () {
        return this.$store.state.user.user.emailConfirmed
    }

    get emailChanged () {
        return this.userEmail !== this.$store.state.user.user.email
    }

    get dataHasChanges () {
        if (this.emailChanged) return true
        if (this.userName !== this.$store.state.user.user.name) return true
        const comparePhone = this.userPhone.substr(6, 9)
        if (comparePhone !== this.$store.state.user.user.phone) return true
        return false
    }

    healPhoneNumber () {
        this.userPhone = healPhone(this.userPhone)
    }

    validateForm (): boolean {
        if (this.userPhone.length > 6) {
            if (this.userPhone.length !== 15 || this.userPhone.substr(0, 6) !== INDICATIVO) {
                this.formError.active = true
                this.formError.message = 'Introduza um número de telefone válido'
                return false
            }
        }

        return true
    }

    resendEmailConfirmation () {
        this.$store.dispatch(ACTIONS.USER_RESEND_CONFIRM_EMAIL).then(() => {
            this.hasResentConfirmationEmail = true
            this.showResentInfo = true
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }

    updateUserData () {
        if (!this.validateForm()) return

        let changeData: UserChangeData = {
            email: this.userEmail,
            name: this.userName,
            phone: this.userPhone.substr(6).substr(0, 9)
        }

        this.$store.dispatch(ACTIONS.USER_CHANGE_DATA, changeData)
            .then(() => {
                this.formError.active = false
                this.formSuccess.active = true
                this.formSuccess.message = 'Dados alterados com sucesso.'
            })
            .catch((error) => {
                this.formError.active = true
                this.formError.message = error.message
            }).finally(() => {
                this.cloneUserData()
            })
    }
    cloneUserData () {
        this.userEmail = this.$store.state.user.user.email
        this.userName = this.$store.state.user.user.name
        this.userPhone = INDICATIVO + this.$store.state.user.user.phone
    }

    hideMessages () {
        this.formError.active = false
        this.formSuccess.active = false
    }

    mounted () {
        // Make user data local to the component
        this.cloneUserData()
    }
}
</script>