<template>
    <div class="col-md-8 col-md-offset-2 information-entry">
        <div v-if="messageSent" class="message-box message-success">
            <div class="message-icon"><i class="fa fa-check"></i></div>
            <div class="message-text">{{ messageSent }}</div>
            <div class="message-close" @click="messageSent = ''">
                <i class="fa fa-times"></i>
            </div>
        </div>
        <h3 class="block-title main-heading">Contacte-nos</h3>
        <form @submit.prevent="sendContact()">
            <div class="row">
                <div class="col-sm-6">
                    <label>Nome <span>*</span></label>
                    <input class="simple-field"
                           v-model="contactName"
                           type="text"
                           placeholder="O seu nome (obrigatório)"
                           required/>
                    <div class="clear"></div>
                </div>
                <div class="col-sm-6">
                    <label>Apelido</label>
                    <input class="simple-field"
                           v-model="contactSurName"
                           type="text"
                           placeholder="O seu apelido"/>
                    <div class="clear"></div>
                </div>
                <div class="col-sm-12">
                    <label>Email <span>*</span></label>
                    <input class="simple-field"
                           v-model="contactEmail"
                           type="email"
                           placeholder="Email (required)"
                           required/>
                    <label>A sua mensagem <span>*</span></label>
                    <textarea class="simple-field"
                              v-model="contactMessage"
                              placeholder="Mensagem (obrigatório)"
                              required></textarea>
                    <div class="button style-10">
                        Send Message
                        <input type="submit" value="" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import { Contact } from '../../utils/Interfaces/Contacts'

@Component({})
export default class FormsContact extends Vue {
    messageSent = ''
    contactName = ''
    contactSurName = ''
    contactEmail = ''
    contactMessage = ''

    async sendContact () {
        const newContact: Contact = {
            name: this.contactName,
            surname: this.contactSurName,
            email: this.contactEmail,
            message: this.contactMessage
        }
        let response = await this.$store.dispatch(ACTIONS.CONTACT_REGISTER, newContact)
        if (response.valid) {
            this.messageSent = response.message
            this.contactName = ''
            this.contactSurName = ''
            this.contactEmail = ''
            this.contactMessage = ''
        } else {
            this.messageSent = response.error.message
        }
    }
}
</script>
<style scoped lang="scss"></style>
