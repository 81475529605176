<template>
    <div class="inline-product-entry">
        <router-link class="image"
                     :to="{ name: 'product', params: { id: product.id } }">
            <img :src="firstImage" alt=""/>
        </router-link>
        <div class="content">
            <div class="cell-view">
                <router-link class="title mono-font"
                             :to="{ name: 'product', params: { id: product.id } }">
                    {{ product.ref }}
                </router-link>
                <div class="price"
                     v-for="size in sizes"
                     :key="size">
                    <div class="current">Tamanho {{size}} x {{ quantities[size] }} unidades</div>
                </div>
                <br/>
                <div class="price">
                    <div class="current">Preço unitário: {{ product.price.toFixed(2) }} €</div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { ConstructedProduct } from '../../utils/Interfaces/Products'
    import getImageUrl from '../../utils/ImageURL'

    @Component({})
    export default class ProductPurchaseItem extends Vue {
        @Prop({}) product!: ConstructedProduct
        @Prop({}) quantities!: { [index: number ]: number }

        getProductImageURL (file: string): string {
            if (!file) file = 'products/product-no-image.jpg'
            return getImageUrl({ filePath: file })
        }

        get sizes () {
            return Object.keys(this.quantities)
        }

        get firstImage () {
            // No images
            if (!this.product.merged.pictures[0]) {
                return this.getProductImageURL('')
            }
            // At least one image
            const thumbnailIndex = this.product.merged.fixedThumbnailIndex
            const productPictures = this.product.merged.pictures
            return this.getProductImageURL(productPictures[thumbnailIndex] || '')
        }
    }
</script>
<style scoped lang="scss"></style>
