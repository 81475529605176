<template>
    <div class="overlay-popup"
        :class="{ 'visible active': isActive }">
        <div class="overflow">
            <div class="table-view">
                <div class="cell-view">
                    <div class="close-layer"></div>
                    <div class="popup-container">
                        <div class="newsletter-title">Ups</div>
                        <div class="newsletter-text">{{ message }}</div>
                        <div class="close-popup" @click="close()"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class ModalError extends Vue {

        get isActive () {
            return this.$store.state.modals.error.active
        }

        get message () {
            return this.$store.state.modals.error.message
        }

        close () {
            this.$store.commit(MUTATIONS.APP_ERROR_HIDE)
        }

    }
</script>