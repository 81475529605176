<template>
    <div class="overlay-popup"
        :class="{ 'visible active': isActive }">
        <div class="overflow" v-if="purchaseData">
            <div class="table-view">
                <div class="cell-view">
                    <div class="close-layer"></div>
                    <div class="popup-container">
                        <div class="newsletter-title">
                            Registámos a sua compra!<br/>
                            Referência da encomenda: <span class="mono-font">{{ purchaseData.idShort }}</span>
                        </div>
                        <br/>
                        
                        <div class="newsletter-text" v-if="purchaseData.paymentMethod === 'MULTIBANCO'">
                            <p class="mono-font">
                                Esta referência multibanco é válida durante as próximas 6 horas.<br />
                                Efetue o pagamento por referência multibanco com os seguintes dados:<br />
                                <span class="text-bold">Entidade: </span> {{ purchaseData.paymentEntity }}<br />
                                <span class="text-bold">Referência: </span> {{ purchaseData.paymentReference }}<br />
                                <span class="text-bold">Valor:</span> {{ purchaseData.paymentValue.toFixed(2) }} €<br />
                            </p>
                        </div>

                        <div class="newsletter-text" v-if="userIsLogged">
                            Enviámos um email com os detalhes da sua compra.<br/>
                            Pode também acompanhar a sua compra na página 'Minha conta' no separador 'Minhas compras'.
                        </div>
                        <div class="newsletter-text" v-else>
                            Enviámos um email com os detalhes da sua compra.<br/>
                            Deverá receber outro email com a confirmação do envio da sua encomenda e um outro com a confirmação de entrega da mesma.
                            Se tiver alguma dúvida não hesite em contactar-nos indicando a referência da sua encomenda.
                        </div>
                        <div class="close-popup" @click="close()"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import MUTATIONS from '../../store/types-mutations'
    import ACTIONS from '../../store/types-actions'
    import GETTERS from '../../store/types-getters'

    @Component({})
    export default class ModalPurchaseSuccess extends Vue {

        get userIsLogged () {
            return this.$store.getters[GETTERS.USER_IS_LOGGED]
        }

        get isActive () {
            return this.$store.state.modals.purchaseSuccess.active
        }

        get purchaseData () {
            return this.$store.state.modals.purchaseSuccess.purchaseData
        }

        close () {
            this.$store.commit(MUTATIONS.APP_PURCHASE_SUCCESS_HIDE)
            this.$store.dispatch(ACTIONS.USER_CART_UPDATE_CART, { cart: {} }).then(() => {
                this.$store.commit(MUTATIONS.PURCHASE_RESET)
            })

            this.$router.replace({ name: 'home' })
        }
    }
</script>