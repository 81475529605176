<template>
    <div class="col-sm-6 col-sm-offset-3 information-entry">
        <div v-if="formError.active" class="message-box message-danger">
            <div class="message-icon"><i class="fa fa-times"></i></div>
            <div class="message-text">{{ formError.message }}</div>
            <div class="message-close" @click="formError.active = false">
                <i class="fa fa-times"></i>
            </div>
        </div>

        <div class="login-box">
            <div class="article-container style-1">
                <h3>Recuperação da sua conta</h3>
                <p>
                    Escolha uma nova password para a sua conta:
                </p>
            </div>
            <form @submit.prevent="recover()">
                <label>Escolha nova password</label>
                <input class="simple-field"
                       v-model="password"
                       type="password"
                       minlength="8"
                       maxlength="40"
                       @focus="formError.active = false"
                       required>
                <label>Confirme password</label>
                <input class="simple-field"
                       v-model="passwordConfirm"
                       type="password"
                       minlength="8"
                       maxlength="40"
                       @focus="formError.active = false"
                       required>
                <div class="button style-10">
                    Recuperar
                    <input type="submit" value="">
                </div>
            </form>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ACTIONS from '../../store/types-actions'
import MUTATIONS from '../../store/types-mutations'

@Component({})
export default class FormUserPasswordRecover extends Vue {
    password: string = ''
    passwordConfirm: string = ''
    formError = {
        active: false,
        message: ''
    }

    get recoveryToken () {
        return this.$route.params.token || ''
    }

    recover () {
        if (this.password !== this.passwordConfirm) {
            this.formError.message = 'As password não conincidem'
            this.formError.active = true
            return
        }
        if (this.password.length < 8) {
            this.formError.message = 'Introduza uma password com pelo menos 8 caracteres'
            this.formError.active = true
            return
        }

        this.formError.active = false

        this.$store.dispatch(ACTIONS.USER_PERFORM_RECOVER_PASSWORD, {
            recoveryToken: this.recoveryToken,
            password: this.password
        }).then(() => {
            this.$store.commit(MUTATIONS.APP_MESSAGE_SHOW, {
                title: 'Conta recuperada',
                message: 'A sua password foi estabelecida com successo. Pode agora fazer login com a nova password.',
                onClose: () => {
                    this.$router.replace({ name: 'sign' })
                }
            })
        }).catch((error) => {
            this.formError.active = true
            this.formError.message = error.message
        })
    }

    mounted () {
        this.$store.dispatch(ACTIONS.USER_CHECK_RECOVER_PASSWORD, this.recoveryToken).catch(() => {
            // TODO: redirect to message saying the token is invalid, expired or already used
            this.$router.replace({ name: 'notFound'})
        })
    }
}
</script>
<style scoped lang="scss"></style>
