// Import the correspondent API object
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
import GETTERS from '../types-getters'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'

interface DefaultState {
    blank: string
    loading: boolean
}

const defaultState: DefaultState = {
    blank: '',
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {
    [GETTERS.BLANK_GETTER]: (state: any) =>  {
        return state.blank
    }
}

const actions = {
    [ACTIONS.BLANK_ACTION] ({ commit }: { commit: any }) {
        commit(MUTATIONS.BLANK_REQUEST_START)
        commit(MUTATIONS.BLANK_REQUEST_FINISH)
    }
}

const mutations = {
    [MUTATIONS.BLANK_RESET] (state: any) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.BLANK_REQUEST_START] (state: any) {
        state.loading = true
    },
    [MUTATIONS.BLANK_REQUEST_FINISH] (state: any) {
        state.loading = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
