// Import the correspondent API object
// Store getters, actions and mutation types
import ACTIONS from '../types-actions'
import MUTATIONS from '../types-mutations'
// Store specific imports
// Other helper imports
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import ContactsAPI from '../../api/contact'
import { Contact } from "../../utils/Interfaces/Contacts"

interface DefaultState {
    loading: boolean
}

const defaultState: DefaultState = {
    loading: false
}

let state = cloneDeep(defaultState)

const getters = {}

const actions = {
    async [ACTIONS.CONTACT_REGISTER] ({ commit }: { commit: any }, contact: Contact) {
        commit(MUTATIONS.CONTACT_REQUEST_START)
        return ContactsAPI.register(contact).then((response) => {
            return response.data
        }).catch((error) => {
            return error.response.data
        }).finally(() => {
            commit(MUTATIONS.CONTACT_REQUEST_FINISH)
        })
    }
}

const mutations = {
    [MUTATIONS.CONTACT_RESET] (state: any) {
        let defaultCopy: any = cloneDeep(defaultState)
        Object.keys(defaultCopy).map(key => {
            Vue.set(state, key, defaultCopy[key])
        })
    },
    [MUTATIONS.CONTACT_REQUEST_START] (state: any) {
        state.loading = true
    },
    [MUTATIONS.CONTACT_REQUEST_FINISH] (state: any) {
        state.loading = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
