<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'about' }">Quem somos</router-link>
        </div>

        <div class="information-blocks">
            <div class="row">
                <div class="col-md-6 col-md-offset-3 information-entry">
                    <div class="blog-landing-box type-1 detail-post">
                        <div class="blog-entry">
                            <div class="content">
                                <h1 class="title">Quem somos</h1>
                                <div class="article-container style-1">
                                    <p>Jandaia Sapatarias, é uma empresa de comércio de calçado que opera no mercado em Lisboa, desde 1978.</p>
                                    <p>Há mais de 4 décadas que representamos as melhores marcas de calçado nacionais, de senhora e homem. Em parceria com unidades de produção dedicadas, desenvolvemos colecções exclusivas e mantemos um serviço personalizado ao Cliente.</p>
                                    <p>Representamos diferentes marcas com elevado prestígio de Itália, Espanha, Alemanha, entre outros países da União Europeia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PageAbout extends Vue {}
</script>
<style scoped lang="scss"></style>
