// Vendors
require('./utils/Vendors/jquery.mousewheel.js')
require('./utils/Vendors/jquery.jscrollpane.min.js')
import 'swiper/dist/css/swiper.css'
// Base imports vue related
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// @ts-ignore
import VueAwesomeSwiper from 'vue-awesome-swiper'

// Utilities imports
import { getCookie } from 'tiny-cookie'
// Store import
import ACTIONS from './store/types-actions'
import MUTATIONS from './store/types-mutations'

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)

// Recover session cookie
const authCookie = getCookie('authToken')
if (authCookie !== null) {
    store.commit(MUTATIONS.USER_SET_TOKEN, authCookie)
    store.dispatch(ACTIONS.USER_REFLECT)
} else {
    // Recover cart from Storage only if there was no user token
    store.dispatch(ACTIONS.PURCHASE_CART_RECOVER_FROM_STORAGE)
    store.commit(MUTATIONS.USER_DELETE_TOKEN)
}

// Redirect to home if user doesn't have a valid session
router.beforeEach(async (to, from, next) => {
    let success = await store.dispatch(ACTIONS.LOAD_PERSISTENT_STORES)
        .then(() => { return true })
        .catch(() => { return false})
    // @ts-ignore 
    if (!success || !store.state.settings.website_online) {
        if (to.name !== 'offline') {
            next({ name: 'offline' })
            return
        }
        next()
        return
    }
    // @ts-ignore 
    if (store.state.settings.website_online) {
        if (to.name === 'offline') {
            next({ name: 'home' })
            return
        }
        next()
        return
    }
    next()
})

router.afterEach((to, from) => {
    window.scrollTo(0, 0)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#content-block')
