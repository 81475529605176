<template>
    <div class="mozaic-banners-wrapper type-2">
        <div class="row">
            <div class="banner-column col-sm-6">
                <router-link class="mozaic-banner-entry type-3"
                             :to="{ name: 'products', query: { isWomen: 1, forShoe: 1 }}"
                             :style="getBannerUrl('bannerShoesWomen')">
                    <span class="mozaic-banner-content">
                        <span class="subtitle">Sapatos</span>
                        <span class="title">Mulher</span>
                        <span class="view">ver</span>
                    </span>
                </router-link>
            </div>
            <div class="banner-column col-sm-6">
                <router-link class="mozaic-banner-entry type-3"
                             :to="{ name: 'products', query: { isMen: 1, forShoe: 1 }}"
                             :style="getBannerUrl('bannerShoesMan')">
                    <span class="mozaic-banner-content">
                        <span class="subtitle">Sapatos</span>
                        <span class="title">Homem</span>
                        <span class="view">ver</span>
                    </span>
                </router-link>
            </div>
            <div class="clear"></div>
        </div>

        <div class="row">
            <div class="banner-column col-sm-6">
                <router-link class="mozaic-banner-entry type-3"
                             :to="{ name: 'products', query: { isWomen: 1, forAccessories: 1 }}"
                             :style="getBannerUrl('bannerAccessoriesWomen')">
                    <span class="mozaic-banner-content">
                        <span class="subtitle">Acessórios</span>
                        <span class="title">Mulher</span>
                        <span class="view">ver</span>
                    </span>
                </router-link>
            </div>
            <div class="banner-column col-sm-6">
                <router-link class="mozaic-banner-entry type-3"
                             :to="{ name: 'products', query: { isMen: 1, forAccessories: 1 }}"
                             :style="getBannerUrl('bannerAccessoriesMen')">
                    <span class="mozaic-banner-content">
                        <span class="subtitle">Acessórios</span>
                        <span class="title">Homem</span>
                        <span class="view">ver</span>
                    </span>
                </router-link>
            </div>
            <div class="clear"></div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <img class="img-responsive" :src="getBottomBannerUrl"/>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import getImageUrl from '../../utils/ImageURL'

@Component({})
export default class BannerMainCategories extends Vue {
    get categories () {
        return this.$store.state.categories.categoryTree
    }

    get settings () {
        return this.$store.state.settings
    }

    get getBottomBannerUrl () {
        if (!this.settings.bannerBottomHomepage) return ''
        return getImageUrl({ filePath: this.settings.bannerBottomHomepage })
    }

    getBannerUrl (property: string) {
        if (!this.settings[property]) return ''
        const bannerUrl = getImageUrl({ filePath: this.settings[property] })
        return `background-image: url(${bannerUrl}); background-size: cover; background-position: left bottom;`
    }
}
</script>
<style scoped lang="scss"></style>
