<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'account' }">Conta</router-link>
            <a href="#">As minhas compras</a>
        </div>
        <div class="information-blocks">
            <div class="row">
                <content-purchases />
                <other-account-navigation />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import ContentPurchases from '../../content/purchases.vue'
import OtherAccountNavigation from '../../other/accountNavigation.vue'

@Component({
    components: {
        ContentPurchases,
        OtherAccountNavigation
    }
})
export default class PageAccount extends Vue {
    @Watch('$store.state.user.logged')
    onUserLoggedChanged (newLogged: boolean) {
        if (newLogged) return
        this.$router.replace({ name: 'sign'})
    }
}
</script>
<style scoped lang="scss"></style>
