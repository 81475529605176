<template>
    <div class="content-push">
        <div class="breadcrumb-box">
            <router-link :to="{ name: 'account' }">Conta</router-link>
            <a href="#">Os meus dados</a>
        </div>
        <div class="information-blocks">
            <div class="row">
                <div class="col-sm-9 col-sm-push-3 information-entry">
                    <form-user-delete />
                </div>
                <other-account-navigation />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import OtherAccountNavigation from '../../other/accountNavigation.vue'
import FormUserDelete from '../../forms/userDelete.vue'

@Component({
    components: {
        FormUserDelete,
        OtherAccountNavigation
    }
})
export default class PageDelete extends Vue {
    @Watch('$store.state.user.logged')
    onUserLoggedChanged (newLogged: boolean) {
        if (newLogged) return
        this.$router.replace({ name: 'sign'})
    }
}
</script>
<style scoped lang="scss"></style>
