<template>
    <div class="col-sm-9 col-sm-push-3 information-entry">
        <other-loading v-if="!hasRequested"/>
        <template v-else-if="purchases.length === 0">
            <p>Ainda não efetuou nenhum compra.</p>
        </template>
        <div class="accordeon size-1" v-else>
            <content-purchases-item v-for="purchase in purchases"
                                    :key="purchase.id"
                                    :purchase="purchase"/>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { Purchase } from '../../utils/Interfaces/Purchase'
    import ACTIONS from '../../store/types-actions'
    // Components
    import OtherLoading from '../other/loading.vue'
    import ContentPurchasesItem from './purchasesItem.vue'

    @Component({
        components: {
            OtherLoading,
            ContentPurchasesItem
        }
    })
    export default class ContentPurchases extends Vue {
        purchases: Purchase[] = []
        hasRequested: boolean = false

        get clientId () {
            return this.$store.state.user.user.id
        }

        mounted () {
            this.$store.dispatch(ACTIONS.USER_GET_PURCHASES).then((purchases: Purchase[]) => {
                this.purchases = purchases
            }).finally(() => {
                this.hasRequested = true
            })
        }
    }
</script>
<style scoped lang="scss"></style>
