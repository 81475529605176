enum GETTERS {
    // BLANK EXAMPLES:
    'BLANK_GETTER' = 'BLANK_GETTER',
    // STORE ROOT GETTERS:
    'ROOT_GET_AUX_DATA' = 'ROOT_GET_AUX_DATA',
    // CATEGORY GETTERS:
    'CATEGORY_GET_WOMAN_SHOES' = 'CATEGORY_GET_WOMAN_SHOES',
    'CATEGORY_GET_MAN_SHOES' = 'CATEGORY_GET_MAN_SHOES',
    'CATEGORY_GET_WOMAN_ACCESSORIES' = 'CATEGORY_GET_WOMAN_ACCESSORIES',
    'CATEGORY_GET_MAN_ACCESSORIES' = 'CATEGORY_GET_MAN_ACCESSORIES',
    // PRODUCTS GETTERS:
    'PRODUCTS_GET_PRODUCT' = 'PRODUCTS_GET_PRODUCT',
    // USER CLIENT
    'USER_IS_LOGGED' = 'USER_IS_LOGGED',
    'USER_ID' = 'USER_ID',
    // PURCHASE
    'PURCHASE_CART_COUNT' = 'PURCHASE_CART_COUNT'
}

export default GETTERS
