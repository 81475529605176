<template>
    <div class="overlay-popup"
        :class="{ 'visible active': sizePickerSuccess.active }">
        <div class="overflow">
            <div class="table-view">
                <div class="cell-view">
                    <div class="close-layer"></div>
                    <div class="popup-container">
                        <div class="newsletter-title">Artigo adicionado!</div>
                        <div class="newsletter-text">Adicionamos o artigo ao seu carrinho.</div>
                        <div class="cart-buttons">
                            <div class="column" style="width: 33.333333%">
                                <router-link :to="{name: 'cart'}" class="button style-3">Ver carrinho</router-link>
                                <div class="clear"></div>
                            </div>
                            <div class="column" style="width: 33.333333%">
                                <router-link :to="{name: 'checkout'}" class="button style-4">Checkout</router-link>
                                <div class="clear"></div>
                            </div>
                            <div class="column" style="width: 33.333333%">
                                <a class="button style-3" @click="close()">Fechar</a>
                                <div class="clear"></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="close-popup" @click="close()"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class ModalSizePickerSuccess extends Vue {
        @Watch('$route', { deep: true })
        onRouteChange () {
            this.close()
        }

        get sizePickerSuccess () {
            return this.$store.state.modals.sizePickerSuccess
        }

        close () {
            this.$store.commit(MUTATIONS.APP_SIZE_PICKER_SUCCESS_HIDE)
        }
    }
</script>