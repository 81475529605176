import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { AuxData } from '../Interfaces/_misc'
import { Tag } from '../Interfaces/Tags'
import { Category } from '../Interfaces/Categories'
import { PublishedProduct, ConstructedProduct } from '../Interfaces/Products'

// Component constructing the product needs to pass
// auxData as an input in order for it to be reactive
// to the persistent data load on direct linking.

export default function constructProduct (product: PublishedProduct, auxData: AuxData): ConstructedProduct {
    let cProd = cloneDeep(product) as ConstructedProduct

    cProd.merged = {
        datePublished: getDatePublished(product),
        datePublishedFormatted: getDatePublishedFormatted(product),
        pictures: getPictures(product),
        fixedThumbnailIndex: 0,
        brand: getBrand(product, auxData),
        tags: getTags(product, auxData),
        mainCategory: getMainCategory(product, auxData),
        categoryObject: getCategoryObject(product, auxData),
        color: getColor(product, auxData),
        sizes: getSizes(product)
    }
    // Fix thumbnail
    if (cProd.merged.pictures[Number(product.thumbnailIndex)]) cProd.merged.fixedThumbnailIndex = product.thumbnailIndex
    return cProd
}

function getDatePublished(product: PublishedProduct): number {
    return new Date(product.dataPublished || '').getTime()
}

function getDatePublishedFormatted(product: PublishedProduct): string {
    let timeNumber = new Date(product.dataPublished || '').getTime()
    return moment(timeNumber).format('YYYY/MM/DD hh:mm:ss')
}

function getPictures(product: PublishedProduct): string[]{
    if (!product.pictures) return []
    let pictures: string[] = []
    try  {
        pictures = JSON.parse(product.pictures)
    } catch (e) {
        pictures = []
    }
    return pictures
}

function getBrand(product: PublishedProduct, auxData: AuxData): string {
    const missingBrandString = 'Sem Marca'
    if (!product.brandId) return missingBrandString
    if (!auxData.brands[product.brandId]) return missingBrandString
    return auxData.brands[product.brandId].name
}

function getTags(product: PublishedProduct, auxData: AuxData): Tag[]{
    const tagsObjects: Tag[] = []
    if (!product.tags) return tagsObjects
    try {
        const tagsIds = JSON.parse(product.tags)
        tagsIds.forEach((tagId: string) => {
            if (auxData.tags[tagId]) tagsObjects.push(auxData.tags[tagId])
        })
        return tagsObjects
    } catch (e) {
        return  tagsObjects
    }
}

function getCategoryObject(product: PublishedProduct, auxData: AuxData): Category | null {
    if (!product.categoryId) return null
    if (!auxData.categories[product.categoryId]) return null
    return auxData.categories[product.categoryId]
}

function getMainCategory(product: PublishedProduct, auxData: AuxData): string {
    if (!product.categoryId) return ''
    const productCategory = auxData.categories[product.categoryId]
    if (!productCategory) return ''
    return `${productCategory.forShoe ? 'sapatos' : 'acessórios'} ${productCategory.isWoman ? 'mulher' : 'homem'}`
}

function getColor(product: PublishedProduct, auxData: AuxData): string {
    // colorManual overwrites native color setttings
    if (product.colorManual) return product.colorManual

    if (!product.colorId) return ''
    if (!auxData.colors[product.colorId]) return ''
    return auxData.colors[product.colorId].name
}

function getSizes(product: PublishedProduct): number[] {
    let sizes: number[] = []
    if (!product.sizes) return sizes
    try {
        sizes = JSON.parse(product.sizes)
        return sizes.sort()
    } catch (e) {
        return sizes
    }
}
