<template>
    <div class="wishlist-entry">
        <div class="column-1">
            <div class="traditional-cart-entry">
                <router-link :to="productRoute" class="image">
                    <img alt="" :src="firstImage" />
                </router-link>
                <div class="content">
                    <div class="cell-view">
                        <router-link class="tag" :to="categoryRoute">
                            {{ productConstructed.merged.mainCategory }}
                            <template v-if="productConstructed.merged.categoryObject">
                                > {{ productConstructed.merged.categoryObject.name }}
                            </template>
                        </router-link>
                        <router-link class="title" :to="productRoute">Ref: {{ product.ref }}</router-link>
                        <div class="inline-description">{{ productConstructed.merged.brand }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column-2">
            <a class="button style-14" @click="addToCart()">Comprar</a>
            <a class="remove-button" @click="removeFromFavorites()">
                <i class="fa fa-times"></i>
            </a>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { PublishedProduct, ConstructedProduct } from '../../utils/Interfaces/Products'
    import { AuxData } from '../../utils/Interfaces/_misc'
    import constructProduct from '../../utils/Constructers/Product'
    import getImageUrl from '../../utils/ImageURL'
    import ACTIONS from '../../store/types-actions'
    import MUTATIONS from '../../store/types-mutations'

    @Component({})
    export default class ProductWish extends Vue {
        @Prop({}) product!: PublishedProduct

        get storeIsOnline () {
            return this.$store.state.settings.store_online
        }

        getProductImageURL (file: string): string {
            return getImageUrl({ filePath: file })
        }

        removeFromFavorites () {
            this.$store.dispatch(ACTIONS.USER_WISHLIST_TOGGLE_PRODUCT, { productId: this.product.id }).then(() => {
                this.$emit('removeProduct', this.product.id)
            })
        }

        addToCart () {
            if (!this.storeIsOnline) {
                this.$store.commit(MUTATIONS.APP_ERROR_SHOW, 'A nossa loja está temporáriamente indisponível. Pedimos desculpa pelo incómodo. Por favor tente mais tarde.')
                return
            }

            this.$store.commit(MUTATIONS.APP_SIZE_PICKER_SHOW, { productId: this.product.id })
        }

        get auxData (): AuxData {
            return {
                colors: this.$store.state.colors.colorsObject,
                brands: this.$store.state.brands.brandsObject,
                categories: this.$store.state.categories.categoryTree,
                tags: this.$store.state.tags.tagsObject
            }
        }

        get productConstructed (): ConstructedProduct {
            return constructProduct(this.product, this.auxData)
        }

        get productRoute () {
            return {
                name: 'product',
                params: {
                    id: this.product.id
                }
            }
        }

        get categoryRoute () {
            // If there is a sub category, use it
            let query: any = {}
            if (this.productConstructed.merged.categoryObject) {
                query.category = this.productConstructed.merged.categoryObject.id
            } else {
                this.productConstructed.isWomen ? query.isWomen = 1 : query.isMen = 1
                this.productConstructed.isShoe ? query.forShoe = 1 : query.forAccessories = 1
            }

            return { name: 'products', query }
        }

        get firstImage () {
            // No images
            if (!this.productConstructed.merged.pictures[0]) {
                return this.getProductImageURL('products/product-no-image.jpg')
            }
            // At least one image
            const thumbnailIndex = this.productConstructed.merged.fixedThumbnailIndex
            const productPictures = this.productConstructed.merged.pictures
            return this.getProductImageURL(productPictures[thumbnailIndex])
        }

        get secondImage () {
            // One or less pictures
            if (!this.productConstructed.merged.pictures[1]) {
                return this.getProductImageURL('products/product-no-image.jpg')
            }
            const thumbnailIndex = this.productConstructed.merged.fixedThumbnailIndex
            const productPictures = this.productConstructed.merged.pictures
            // If thumbnailIndex is 0, return 1, else return the first image
            if (thumbnailIndex === 0) {
                return this.getProductImageURL(productPictures[1])
            } else {
                return this.getProductImageURL(productPictures[0])
            }
        }
    }
</script>
<style scoped lang="scss"></style>
